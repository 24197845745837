import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const hideModalUseCase: UseCase = {
  type: 'HIDE_MODAL',

  run: ({ params }) => {
    return Cx.resolve(params);
  }
};

export function hideModal(params?: string[]) {
  app.runUseCase(hideModalUseCase, { params });
}

// How to use it
// hideModal() --> will set all ModalReducer params to initialState
// hideModal(['alertOpen', 'alertProps']) --> will close and set to initialState just the params declared
// Important: the params should match with the initialState in ModalReducer