import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface setLiveRatesUseCaseOptions {
  liveRates: boolean;
}

export const setLiveRatesUseCase: UseCase = {
  type: 'SET_LIVE_RATES',

  run: ( options: setLiveRatesUseCaseOptions ) => {
    return Cx.resolve({
      liveRates: options.liveRates
    });
  }
};

/**
 * @category Can
 */
export function cansetLiveRates( state ) {
  return setLiveRatesUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setLiveRates( options: setLiveRatesUseCaseOptions ) {
  app.runUseCase( setLiveRatesUseCase, options );
}
