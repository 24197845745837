import _ from 'lodash';
import { isDesignReady } from '../domain/Design/Conditions';
import { DecorationArea } from '../domain/Design/DecorationArea';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { toggleDecoratinAreaHidden$ } from './actions/toggleDecorationAreaHidden$';
import { UseCase } from './usecase/UseCase';

export interface ToggleDecorationAreaMultiDisabledOptions {
  decorationAreaList: DecorationArea[];
  visibleId: string;
}

export const toggleDecorationAreaMultiHiddenUseCase:UseCase = {
  type: 'TOGGLE_DECORATION_MULTI_AREA_HIDDEN',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: ToggleDecorationAreaMultiDisabledOptions) => {
    const hideAll = [] ;
    options.decorationAreaList.forEach((da)=>{
      const hidden = ( da.Id !== options.visibleId ) ;
      hideAll.push(toggleDecoratinAreaHidden$(da, hidden));
    })
    return Promise.all(hideAll)
    .then(()=>{
      return Cx.resolve(options);
    })
  }
};

export function canToggleDecorationAreaMultiHidden(state): boolean {
  return toggleDecorationAreaMultiHiddenUseCase.checkConditions( state ).isAllowed;
}

export function toggleDecorationAreaMultiHidden(options: ToggleDecorationAreaMultiDisabledOptions){
  app.runUseCase(toggleDecorationAreaMultiHiddenUseCase, options);
}
