import { app } from '../../initApp';
import { UseCase } from '../usecase/UseCase';
import SearchFactory from '../../../gui/services/search'
import { loadFontConfig$ } from '../../usecases/actions/font'

export interface initFontListWithConfigUseCaseOptions {
  published: boolean
}

export const initFontListWithConfigUseCase: UseCase = {
  type: 'INIT_FONT_LIST',

  run: ( options: initFontListWithConfigUseCaseOptions ) => {
    let fontSearchService = new SearchFactory("Font")
    return fontSearchService.search().then((fonts) => {
      return loadFontConfig$().then((data) => {
        let config = JSON.parse(data.Items[0].Data)
        if (options.published)
        {
          config = Object.fromEntries(Object.entries(config).filter(([k,v]) => (v as any).publishedFontId));
          const validFontIds = Object.keys(config);
          fonts  = fonts.filter( font => validFontIds.indexOf(font.Id) > -1  )
        }
        return Cx.resolve({ fonts, config })
      })
    });
  }
};

/**
 * @category Use Case
 */
export function initFontListWithConfig( options: initFontListWithConfigUseCaseOptions ) {
  app.runUseCase( initFontListWithConfigUseCase, options );
}
