export interface GrayGood {
  // Archived: boolean;
  // ArtRefs: any;
  // Disabled: boolean;
  DocId: string;
  // GrayGood: any;
  Id: string;
  // LogoPositionConfig: any;
  // ModelType: string;
  Name: any;
  Fabric: string;
  // PartnerId: string;
  // PartnersKey: string;
  Price: string;
  Cost: string;
  Raw: any;
  SportId: string;
  PriceSizeOffsets: any;
  ThumbnailScaling: string;
};

export function grayGoodFromActionResult (props: any): GrayGood {
  return {
    DocId: props.artRefs.publicDocId,
    Id: props.id,
    Name: props.name,
    Fabric: props.fabric,
    Price: props.price,
    Cost: props.cost ?? props.price,
    Raw: props,
    SportId: props.sport.id,
    PriceSizeOffsets: props.priceSizeOffsets,
    ThumbnailScaling: props.thumbnailScaling
  };
}