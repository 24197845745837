import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { enumDecorationAreas } from '../../usecases/actions/canvas';
import { isCADecorationArea, isNameDecorationArea, isNumberDecorationArea } from '../Design/DecorationArea';

export const ROSTER_FIELD_CA = 'CA';
export const ROSTER_FIELD_NAME = 'Name';
export const ROSTER_FIELD_NUMBER = 'Number';

// Use to determine what decoration areas are linked to a roster field
export interface RosterFieldMap {
  DecorationAreaIds: string[]; // e.g. ['Sleeve-Number-1', 'Sleeve-Number-2', 'Back-Number']
  DecorationAreaNames: string[]; // e.g. ['Sleeve-Number-1', 'Sleeve-Number-2', 'Back-Number']
  RosterField: string; // e.g. 'Number';
}

export function getRosterFieldMapFromCanvas (canvasId: string): RosterFieldMap[] {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const decorationAreas = enumDecorationAreas(canvas);
  const fields = {};
  const caKey = ROSTER_FIELD_CA;
  const nameKey = ROSTER_FIELD_NAME;
  const numberKey = ROSTER_FIELD_NUMBER;

  decorationAreas.forEach((item) => {
    if (isCADecorationArea(item)) {
      if (!fields[caKey]) { fields[caKey] = []; }
      fields[caKey].push(item.Id);
    }

    else if (isNameDecorationArea(item)) {
      if (!fields[nameKey]) { fields[nameKey] = []; }
      fields[nameKey].push(item.Id);
    }

    else if (isNumberDecorationArea(item)) {
      if (!fields[numberKey]) { fields[numberKey] = []; }
      fields[numberKey].push(item.Id);
    }
  });

  const items: RosterFieldMap[] = [];

  for (const key in fields) {
    items.push({
      DecorationAreaIds: fields[key],
      DecorationAreaNames: fields[key],
      RosterField: key
    });
  }

  return items;
}