import { app } from "../initApp";
import { UseCase } from "./usecase/UseCase";

export const showEditColorOptionsUseCase: UseCase = {
  type: 'SHOW_EDIT_COLOR_OPTIONS',

  run: () => {
    return Cx.resolve({});
  }
};

export default function showEditColorOptions() {
  app.runUseCase(showEditColorOptionsUseCase, {});
}
