import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { hasActiveProduct } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';
import { priceByOrder$ } from './actions/priceByOrder$';
import { saveCanvasDocument$ } from './actions/canvas';
import global from '../../gui/services/global';

export interface ShowReviewStepOptions {
  products: any;
  partnerKey: string;
  stateTax: string;
}

export const showReviewStepUseCase: UseCase = {
  type: 'SHOW_REVIEW_STEP',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady, hasActiveProduct]);
  },

  run: (options: ShowReviewStepOptions) => {
    const clonedProducts = Cx.cloneDeep(options.products);

    return priceByOrder$(clonedProducts, options.partnerKey, options.stateTax, 'coach')
      .then((priceModel) => {
        // @ts-ignore
        return saveCanvasDocument$(global.__MAIN_CANVAS_ID__) 
          .then((doc) => {
            return Cx.resolve(Object.assign(options, { SavedDocId: doc.id, priceModel: priceModel }));
          });

      })
  }
};

export function canShowReviewStep(state: RootState) {
  return showReviewStepUseCase.checkConditions(state).isAllowed;
}

export function showReviewStep(options: ShowReviewStepOptions) {
  app.runUseCase(showReviewStepUseCase, options);
}
