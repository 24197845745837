import React, { useEffect, useRef } from 'react';
import { hideModal, showLogin } from '../../../../../app/initApp';
import { showCustomMsgPopup } from '../../../../../app/usecases/customMsgPopupUseCase';
import Button from '../../../misc/Button';

import styles from './LogoSelector.module.css';

interface Props {
  canvasId: string;
  className?: string;
  imageLink?: string;
  disabled?: boolean;
  hidden?: boolean;
  loginRequiredBeforeUpload?: boolean;
  logoIsLoading?: boolean;
  onFileSelected: Function;
  onSelectClipartClick: Function;
  onRemove: Function;
  currentDpiNumber?: any;
}

const LogoSelector = (props: Props) => {
  const loginRequiredBeforeUpload = props.loginRequiredBeforeUpload;
  const logoIsLoading = props.logoIsLoading;
  let className = props.hidden ? styles.logoSelectorHidden : styles.logoSelector;
  const inputFile = useRef(null);

  if (props.className) {
    className += ' ' + props.className;
  }

  const validateLogin = () => {
    if (loginRequiredBeforeUpload) {
      showCustomMsgPopup({
        title: 'Please, login to continue.',
        onClick: showLogin,
        showCancel: true,
        showConfirm: true
      });
    } else {
      inputFile.current.click();
    }
  }

  const onUpload = (e: any) => {
    props.onFileSelected(e.target.files[0]);
    e.target.value = null;
  }

  useEffect(() => {
    if (props.currentDpiNumber) {
      const dpiNumber = Math.ceil(props.currentDpiNumber);
      // 300 DPI === 118 PPI. Some pics hasn't set the 300DPI values on their properties and they are taken as 118 PPI
      // Set 250 DPI as new minimum value
      if (!isNaN(dpiNumber) && (dpiNumber < 119 || (dpiNumber > 119 && dpiNumber < 250))) {
        showCustomMsgPopup({
          title: `Your logo is less than 250 DPI (${dpiNumber}) and may appear blurry/pixelated when printed.`,
          showConfirm: true,
          onClick: hideModal,
        });
      }
    }
  }, [props.currentDpiNumber]);

  return (
    <div className={className}>
      <div className={styles.preview} style={{ backgroundImage: 'url(' + props.imageLink + ')' }}>
        {
          logoIsLoading
            ?
            <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
            :
            null
        }
        {props.imageLink && props.imageLink.length > 0 && <div className="fas fa-trash-alt" style={{ position: "absolute", top: "5px", right: "5px", cursor: "pointer", fontSize: "1.5em" }} onClick={() => props.onRemove()} ></div>}
      </div>
      <div className={styles.uploadOrSelect}>
        <div className={styles.uploadButton}>
          <Button
            className={styles.uploadButtonButton}
            disabled={logoIsLoading}
            iconCls={logoIsLoading ? 'fas fa-circle-notch fa-spin' : undefined}
            label={logoIsLoading ? 'LOADING...' : 'UPLOAD LOGO'}
            onClick={() => {
              validateLogin()
            }} />
        </div>

        <div className={styles.supportedFileTypes + ' partner-color2'}>SUPPORTED FILE TYPES:<br />PDF, PNG</div>
        {/* <div className={styles.or}>
          <div className={styles.orLabel}>Or</div>
        </div>
        <Button
          className={styles.button}
          label="SELECT CLIPART"
          onClick={props.onSelectClipartClick} /> */}
      </div>
      <input
        hidden
        ref={inputFile}
        id={"test123"}
        accept=".jpg,.pdf,.png"
        onChange={onUpload}
        type="file"
        name="logo" />
    </div>

  );

}

export default LogoSelector;
