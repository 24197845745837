import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/Application';
import { succeedAccessStart } from '../../gui/services/storage'
import { verifyEmailHash } from '../../app/usecases/login/verifyEmail'
import { useEffect } from 'react';

// Styles
import styles from './Confirmation.module.css';

interface Props {
  hash: string;
}

const Confirmation = ({ hash }: Props) => {
  const hashedValues = hash.split('|');
  const coachesKey = useSelector((state: RootState) => state.Session.coachesKey);
  const loginFailed = useSelector((state: RootState) => state.Session.getSessionFailed);
  const partner = useSelector((state: RootState) => state.Session.partnersKey);

  useEffect(() => {
    if (!coachesKey && !loginFailed) {
      verifyEmailHash({ email: hashedValues[1], hash: hashedValues[0], partner });
    }
  }, [coachesKey, loginFailed, hashedValues, partner]);

  if (coachesKey) {
    return (
      <div className={styles.confirmation}>
        <div className={styles.message}>
          <div className={styles.loggedIn}>You are now logged in!</div>
          <div>You can return to the designer and continue!</div>
        </div>
      </div>
    );
  }

  if (loginFailed) {
    return (
      <div className={styles.confirmation}>
        <div className={styles.message}>
          Sorry, we are <span className={styles.emphasize}>not able</span> to log you in
        </div>
      </div>
    );
  }

  return (
    <div className={styles.confirmation}>
      <div className={styles.message}>Logging you in...</div>
    </div>
  );
};

export default Confirmation;
