import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SavedOrder } from '../../../app/domain/Order/SavedOrder';
import { loadSavedOrderStates } from '../../../app/usecases/loadSavedOrderStates';
import { getValidThumbUrl } from '../../../app/domain/Product/Product';
import { formatDate } from '../../util/formatDate';
import { uniqueId } from 'lodash';
import { backendClient } from '../../services/backend';
import Modal from '../misc/Modal';
import { RootState } from '../../../app/Application';
// Styles
import styles from './SavedOrdersSummary.module.css';

interface Props {
  className: string;
  onOrderClick: Function;
  orders: SavedOrder[];
  cadworxSessionId?: string;
}

const SavedOrdersSummary = (props: Props) => {
  const [invalidIds, setInvalidIds] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState<SavedOrder | null>(null);
  const img: any = useRef([]);
  const thumbRef: any = useRef([]);
  const partnersKey = useSelector((state: RootState) => state.Session.partnersKey);

  function hideDivById(id: string) {
    if (!invalidIds.includes(id)) setInvalidIds((invalidIds) => [...invalidIds, id]);
  }

  function checkInvalidId(id: string) {
    return invalidIds.indexOf(id) > -1 ? 'none' : '';
  }

  function handleOnLoad(e, id: string) {
    const thumbElementId = document.getElementById(id)?.id;
    const imgElement = e.target;
    imgElement.style.display = 'flex';
    const nodeList = imgElement.parentElement.childNodes;
    const childElements = nodeList && Array.prototype.slice.call(nodeList);

    if (thumbElementId && childElements?.length > 0) {
      const thumb = childElements.find((x) => x?.id && x.id === thumbElementId);
      thumb.style.display = 'none';
    }
  }

  function getDocThumb(id: string) {
    const thumbSize = 260;
    const requestModel = {
      Doc: { Id: id },
      Format: 'png',
      Height: thumbSize,
      Width: thumbSize,
    };

    return Cx.Core.Doc3D.thumbnailUrl(requestModel);
  }

  const handleDeleteOrder = (coachesKey: string, orderId: string, partnersKey: string) => {
    setIsModalOpen(false);
  
    return backendClient
      .post<any>({
        endPoint: 'orders',
        method: 'archiveSavedOrders',
        payload: { coachesKey, orderId },
      })
      .then(() => {
        console.log('Order successfully deleted');
        loadSavedOrderStates({ coachesKey, partnersKey });
      })
      .catch((err) => {
        console.error('Error deleting order:', err);
      });

  };

  const confirmDelete = (order: SavedOrder) => {
    setOrderToDelete(order);
    setIsModalOpen(true);
  };

  const cancelDelete = () => {
    setOrderToDelete(null);
    setIsModalOpen(false);
  };

  return (
    <div className={props.className || ''}>
      {props.orders.length > 0 ? (
        <>
          <div className={styles.orderItemHeader}>
            <div className={styles.thumbListPreview}></div>
            <div className={[styles.header, styles.column].join(' ')}>ITEMS</div>
            <div className={[styles.header, styles.column].join(' ')}>PRODUCTS</div>
            <div className={[styles.header, styles.column].join(' ')}>ROSTER</div>
            <div className={[styles.header, styles.column].join(' ')}>LAST UPDATED</div>
            <div className={[styles.header, styles.column].join(' ')}>ACTIONS</div>
          </div>
          {props.orders.map((savedOrder: SavedOrder, orderIndex: number) => {
            const date = formatDate(savedOrder.lastUpdatedOn);
            const columnData = getColumnData(savedOrder);
            const products = columnData?.products;
            const onClick = () => props.onOrderClick(savedOrder);

            return (
              <div
                className={styles.orderItem}
                key={savedOrder.id}
                onClick={onClick}
                style={{ display: checkInvalidId(savedOrder.id) }}
              >
                <div className={styles.thumbListPreview}>
                  {products &&
                    products.map((product: any, index: number) => (
                      <>
                        <img
                          id={`${savedOrder.orderState[index].Id}_${String(index)}`}
                          src={getDocThumb(savedOrder.orderState[index].GrayGood.DocId)}
                          className={[styles.imageColumn, styles.blur].join(' ')}
                        />
                        <img
                          ref={(el) => (img.current[index] = el)}
                          onError={() => hideDivById(savedOrder.id)}
                          key={uniqueId()}
                          onLoad={(e) => handleOnLoad(e, `${savedOrder.orderState[index].Id}_${String(index)}`)}
                          className={[styles.imageColumn, styles.fadeIn].join(' ')}
                          src={getValidThumbUrl(
                            {
                              Size: 100,
                              Camera: 'Front',
                              DocId: product.savedDocId,
                              cadworxSessionId: props.cadworxSessionId,
                              type: 'img',
                            },
                            savedOrder.orderState[index]
                          )}
                          alt="front"
                          style={{ display: 'none' }}
                        />
                      </>
                    ))}
                </div>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>ITEMS</span>
                  {columnData.productCount}
                </div>
                <div className={[styles.column, styles.flexColumn].join(' ')}>
                  <span className={styles.mobileHeader}>PRODUCTS</span>
                  {columnData.productList &&
                    columnData.productList.map((name: any) => <span key={uniqueId()}>{name}</span>)}
                </div>
                <div className={[styles.column, styles.flexColumn].join(' ')}>
                  <span className={styles.mobileHeader}>ROSTER</span>
                  {columnData.rosterList &&
                    columnData.rosterList.map((hasRoster: any) =>
                      hasRoster ? (
                        <i key={uniqueId()} className="icon fas fa-solid fa-check"></i>
                      ) : (
                        <i key={uniqueId()} className="icon fas fa-solid fa-times"></i>
                      )
                    )}
                </div>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>LAST UPDATED</span>
                  {date}
                </div>
                <div className={styles.column}>
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent parent click
                      confirmDelete(savedOrder);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.noItemsFound}>No items found...</div>
      )}
      {orderToDelete && (
        <Modal
          closeHandler={cancelDelete}
          title="Confirm Delete"
          side="center"
          show={isModalOpen}
        >
          <p>
            Are you sure you want to delete?
          </p>
          <div className={styles.modalActions}>
            <button
              className={styles.confirmButton}
              onClick={() =>
                handleDeleteOrder(orderToDelete.coachesKey, orderToDelete.id, partnersKey)
              }
            >
              Yes, Delete
            </button>
            <button className={styles.cancelButton} onClick={cancelDelete}>
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

function getColumnData(order: SavedOrder) {
  const orderSnippet: any = Cx.cloneDeep(order.orderStateSnippet) || [];
  let productList: any = [];
  let rosterList: any = [];
  let total = 0;

  orderSnippet?.products?.forEach((p, i) => {
    productList.push(p.grayGoodName);
    const hasRosters = order.orderState[i].RosterItems.length > 0;
    rosterList.push(hasRosters);
    total += p.totalPrice;
  });

  return {
    productList,
    rosterList,
    rosterTotal: order.orderState.map((s) => s.RosterItems.length),
    products: orderSnippet?.products,
    productCount: orderSnippet?.products?.length,
    total,
  };
}

export default SavedOrdersSummary;
