import { isEmpty } from 'lodash';
import {RootState} from '../Application';
import { app } from '../initApp';
import { getCoachSessionData$ } from './actions/getCoachSessionData$';
import { UseCase } from './usecase/UseCase';
import { report } from '../../gui/util/rollbar';

export interface CoachHasSessionOptions {
  sessionToken?: string;
  coachesKey?: string,
  partnerKey?: string,
  superSessionToken?: string
}

export const coachHasSessionUseCase: UseCase = {
  type: 'COACH_HAS_SESSION',

  run: async (options?: CoachHasSessionOptions) => {
    if (!isEmpty(options.sessionToken) || (!isEmpty(options.coachesKey) && !isEmpty(options.superSessionToken))) {
      return await getCoachSessionData$(options.sessionToken, options.partnerKey, options.coachesKey, options.superSessionToken);
    }

    const store = app.getReduxStore();
    const state: RootState = store.getState();
    const sessionToken = state.Session.sessionToken;

    if(!isEmpty(sessionToken)){
      return await getCoachSessionData$(sessionToken, options.partnerKey );
    }

    if ( Cx.Storage.isAvailable() ) {
      // TODO: Bring in CanvasLibTS so we can quickly edit types.  We can copy that lib around short term until Grafika TS is ready.
      // @ts-ignore
      Cx.Storage.remove( 'ubdesigner2.sessionToken' );
    }

    return Promise.reject('Coach does not have session');
  }
};

/**
 * @category Use Case
 */
export async function coachHasSession(options?: CoachHasSessionOptions) {
  try {
    await app.runUseCase( coachHasSessionUseCase, options );
  }
  catch (err) {
    report(err);
  }
}
