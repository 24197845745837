import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';
import { SavedOrder } from '../../../app/domain/Order/SavedOrder';
import { Product } from '../../../app/domain/Product/Product';
import { hideModal, openSavedOrder } from '../../../app/initApp';
import { showCustomMsgPopup } from '../../../app/usecases/customMsgPopupUseCase';
import { isLoadingOrder } from '../../../app/usecases/loadingOrder';
import global from '../../services/global';
import Button from '../misc/Button';
import ReviewItem from '../steps/ReviewItem';

// Styles
import styles from './SavedOrdersDetail.module.css';

interface Props {
  className: string;
  onBackClick: Function;
  savedOrder: SavedOrder;
  cadworxSessionId?: string;
  activeStep?: string;
  products?: any;
}

const SavedOrdersDetail = (props: Props) => {
  const savedOrder = props.savedOrder;
  const orderState = savedOrder ? savedOrder.orderState : [];
  const activeStep = props.activeStep;
  const [warningMsg, setWarningMsg] = useState<string>("");
  const [showWarningPopup, setShowWarningPopup] = useState<boolean>(false);
  const [option, setOption] = useState<string>("");
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  const savedProducts = props.products?.filter((p: any) => p.SavedDocId !== undefined);

  useEffect(() => {
    if (showWarningPopup) {
      showCustomMsgPopup({
        title: warningMsg,
        onClick: () => {
          redirectTo(option, currentProduct);
        },
        showCancel: true,
        showConfirm: true,
        showConfirmMsg: "CONTINUE",
        showCancelMsg: "CANCEL",
      });
    }
  }, [showWarningPopup]);

  const handleClick = (option: string, product: any) => {
    const isEditing = activeStep === GuiSteps.CUSTOMIZE || activeStep === GuiSteps.ROSTER;
    setOption(option);
    setCurrentProduct(product);

    if (isEditing && savedProducts.length === 0) {
      setWarningMsg("Loading a saved order will remove your current customization");
      setShowWarningPopup(true);
    } else if (isEditing && (orderState.length > 0 && savedProducts.length > 0)) {
      setWarningMsg(
        "Any products in your current order will be combined with this saved order. Any unsaved customization will be lost"
      );
      setShowWarningPopup(true);
    } else if (activeStep === GuiSteps.REVIEW && (savedProducts.length > 0 || orderState.length > 0)) {
      setWarningMsg("Any products in your current order will be combined with this saved order");
      setShowWarningPopup(true);
    } else {
      redirectTo(option, product);
    }
  };

  function redirectTo(option: string, product: any) {
    switch (option) {
      case "editDesign":
        editDesign(product);
        break;
      case "editRoster":
        editRoster(product);
        break;
      case "goToReviewStep":
        goToReviewStep(product);
        break;
    }
  }

  const editDesign = (product: any) => {
    openSavedOrder({
      canvasId: global.__MAIN_CANVAS_ID__,
      editDesign: true,
      product,
      savedOrder: props.savedOrder,
    });
    hideModal();
    isLoadingOrder();
  };

  const editRoster = (product: any) => {
    openSavedOrder({
      canvasId: global.__MAIN_CANVAS_ID__,
      editRoster: true,
      product,
      savedOrder: props.savedOrder,
    });
    hideModal();
    isLoadingOrder();
  };

  const goToReviewStep = (product: any) => {
    openSavedOrder({
      canvasId: global.__MAIN_CANVAS_ID__,
      editRoster: false,
      editDesign: false,
      product,
      savedOrder: props.savedOrder,
    });
    hideModal();
    isLoadingOrder();
  };

  return (
    <div className={props.className || ''}>
      <div className={`${styles.floatingHeader}`}>
        <span className={styles.title}>Saved Orders</span>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.backButton + ' partner-background-color2 partner-border-color2'}
            iconCls="fa-arrow-left"
            label="Back"
            onClick={props.onBackClick}
          />
          <Button
            className={styles.backButton + ' partner-background-color2 partner-border-color2'}
            label="LOAD ORDER"
            onClick={() => {
              if (orderState.length > 0) {
                handleClick('goToReviewStep', orderState[0]);
              }
            }}
          />
        </div>
      </div>
      {orderState.map((product: Product) => (
        <div key={uniqueId()} className={styles.reviewItemContainer}>
          <ReviewItem
            key={product.Id}
            onEditDesignClick={() => {
              handleClick('editDesign', product);
            }}
            onEditRosterClick={() => {
              handleClick('editRoster', product);
            }}
            product={product}
            cadworxSessionId={props.cadworxSessionId}
          />
        </div>
      ))}
    </div>
  );
};

export default SavedOrdersDetail;
