import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { AuthMethod } from '../../../../app/reducers/LoginReducer';
// import { requestPhoneCode, RequestPhoneCodeOptions } from '../../../../app/usecases/requestPhoneCode'
import { LoginCreateUser } from './LoginCreateUser';
import { LoginMessage } from './LoginMessage';
import { LoginSignIn } from './LoginSignIn';
import PhoneVerification from './PhoneVerification';
import { SelectVerification } from './SelectVerification';
import { isEqual } from 'lodash';

import styles from './Login.module.css';

interface Props {
  // states
  partner: any
  createUser?: boolean
  loginSuccess?: boolean
  promptForSelectVerification?: boolean
  // requestingAccessByEmail?: boolean
  requestingAccessBy?: AuthMethod.Email | AuthMethod.Phone | AuthMethod.Google
  // requestingAccessByPhone?: boolean
  // loggedIn?: boolean

  // data
  token: string
  email?: string
  phone?: string
  inSystem?: boolean

  // transitions
  requestFailed?: boolean
  requestInProgress?: boolean
  errorMessage?: string
  // needToVerifyPhone?: boolean,
  partnerInfo?: any;
}

const Login = (props: Props) => {
  // select verification type for new user
  if (props.promptForSelectVerification)
    return <SelectVerification
      email={props.email}
      phone={props.phone}
      partnerInfo={props.partnerInfo}
      token={props.token} />

  // email verification
  if (isEqual(props.requestingAccessBy, AuthMethod.Email) && props.inSystem)
    return <LoginMessage goBack={false}>
      <React.Fragment>
        <div className={styles.planeIcon + ' fas fa-paper-plane'}></div>
        <div className={styles.loginMainTitle + ' partner-color2'}>Check your email!</div>
        <div className={styles.linkSentMessage}>To login password-free, tap the button in the email we just sent to <b>{props.email}</b>.</div>
      </React.Fragment>
    </LoginMessage>

  // phone verification
  else if (isEqual(props.requestingAccessBy, AuthMethod.Phone) && props.inSystem)
    return <PhoneVerification />

  // create user
  else if (props.createUser)
    return <LoginCreateUser
      email={props.email}
      phone={props.phone}
      token={props.token}
      partner={props.partner}
      errorMessage={props.errorMessage} />;

  else // sign in
    return <LoginSignIn
      requestFailed={props.requestFailed}
      requestInProgress={props.requestInProgress}
      // requestingForAccess={ props.requestingAccessByEmail || props.requestingAccessByPhone}
      token={props.token}
      email={props.email}
      errorMessage={props.errorMessage}
      partnerInfo={props.partnerInfo} />;

};

const mapStateToProps = (state: RootState) => {
  const partnerName = state.GUI.partnerData && state.GUI.partnerData.name;
  const supportEmail = state.GUI.partnerData && (state.GUI.partnerData.supportEmail || state.GUI.partnerData.email);
  const supportPhone = state.GUI.partnerData && (state.GUI.partnerData.supportPhone || state.GUI.partnerData.phone);

  return {
    // states
    createUser: !state.Login.inSystem && state.Login.lastRequestForAccess > 0 && !state.Login.requestFailed,
    promptForSelectVerification: state.Login.promptForSelectVerification,
    requestingAccessBy: state.Login.requestingAccessBy,
    // requestingAccessByEmail: state.Login.requestingAccessByEmail,
    // requestingAccessByPhone:state.Login.requestingAccessByPhone,
    // loggedIn: state.Login.loggedIn,
    inSystem: state.Login.inSystem,
    // needToVerifyPhone: ! state.PhoneVerification.sendingCode && ! ( state.PhoneVerification.sendFailed || state.PhoneVerification.sendingSuccess ) ,

    // data
    email: state.Login.email,
    phone: state.Login.phone,

    // transitions
    // requestFailed: state.Login.requestFailed,
    requestInProgress: state.Login.requestInProgress,
    errorMessage: state.Login.errorMessage,
    partnerInfo: {
      partnerName: partnerName,
      partnerLogo: state.GUI.partnerLogoUrl,
      supportPhone: supportPhone,
      supportEmail: supportEmail
    },
    partner: { partnersKey: state.Session.partnersKey }
  }
};

export default connect(mapStateToProps, null)(Login);
