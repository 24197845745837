import { app } from '../initApp';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { UseCase } from './usecase/UseCase';

interface syncCanvasSizeUseCaseOptions {
  canvasId:string
}

export const syncCanvasSizeUseCase:UseCase = {
  type: 'SYNC_CANVAS_SIZE',

  run: (options: syncCanvasSizeUseCaseOptions) => {
    let canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    //CanvasHelper.clear3DView(options.canvasId);
    canvas.selectedFigures = [];
    canvas.updateSize();
    return canvas.commit$().then( () => {
      return canvas.render$();
    });
  }
};

export function syncCanvasSize(options: syncCanvasSizeUseCaseOptions){
  app.runUseCase(syncCanvasSizeUseCase, options);
}
