import React from 'react';
import { Component } from 'react';
import Main from './gui/components/Main';
import { connect } from 'react-redux';
import Confirmation from './gui/components/Confirmation';
import { queryString } from './gui/util/queryString';
import { showCustomMsgPopup } from './app/usecases/customMsgPopupUseCase';

// Styles
import styles from './App.module.css';

interface Props {
  showPopup?: string;
  partnersKey?: string;
}

interface State {
  showPopup: boolean;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPopup: false
    };
  }

  componentDidMount() {
    const urlContainsEdge = window.location.href.includes('edge');
    this.setState({ showPopup: urlContainsEdge });
  }

  render() {
    const { showPopup } = this.state;
    let loginHash = queryString("hash"); //This is used so the confirmation page will ping the backend with the emailed hash and verified it's valid
    let batch = Boolean(queryString("batch"));
    const nonce = !!batch; // bobert: can be removed anytime - test line
    const orderId = queryString("app.orderId")
    const initialOrderOpenAsCopy = true; //queryString("app.openAsCopy") != undefined;

    if (showPopup && this.props.partnersKey) {
      const productionUrl = 'https://www.uniformcreator.com/?pk=' + this.props.partnersKey;
      showCustomMsgPopup({
        title: 'You are trying to access a testing environment. If this is in error, please use the production link instead:',
        url: productionUrl
      });
    }

    return (
      <div className={styles.App}>
        {loginHash ?
          <Confirmation hash={loginHash} /> :
          <Main isBatchProcessing={batch}
            orderId={orderId}
            initialOrderOpenAsCopy={initialOrderOpenAsCopy}
          />
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    partnersKey: state.Session.partnersKey
  };
}

export default connect(mapStateToProps, null)(App)
