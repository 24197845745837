import Rollbar from "rollbar"

const rollbar = new Rollbar({
  accessToken: '762b2f566f8a4be293d12de45914f221',
  captureUncaught: true,
  captureUnhandledRejections: true
})

export function report(error) {
  console.error('Logging error to console:', error); 

  rollbar.error(error, rollbarError => {
    if (rollbarError) {
      console.error('Rollbar error reporting failed:');
      console.error(rollbarError);
      return;
    }
    console.log('Reported error to Rollbar');
  });
}


export function reportMessage(endpoint, msg) {
  const e = new Error(JSON.stringify({ endpoint, msg }));
  report(e);
}