import { Component } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import {
  showCustomizeStep,
  showReviewStep,
  canAddRosterItem,
  canShowReviewStep,
  showAddRosterStep,
  canSubmitOrder,
  hideModal,
  previewRosterItem,
  showLogin
}
  from '../../../../app/initApp';
import global from '../../../services/global';
import Canvas from '../../design/Canvas';
import Button from '../../misc/Button';
import RosterGrid from '../roster/RosterGrid';
import CustomizeHud from './CustomizeHud';
import CustomizeTabs from './CustomizeTabs';
import CustomizeToolsHud from './CustomizeToolsHud';
import { activeProductFromApplicationState } from '../../../../app/domain/Product/Product';
import { RosterItem } from '../../../../app/domain/Roster/RosterItem';
import { validateRosterItems } from '../../../../app/usecases/roster/validateRosterItems';
import { showCustomMsgPopup } from '../../../../app/usecases/customMsgPopupUseCase';
import { findCADecorationArea } from '../../../../app/domain/Design/DecorationArea';

// Styles
import './Customize.css';

interface Props {
  canAddRosterItem: boolean;
  canShowReviewStep: boolean;
  hidden: boolean;
  itemNumber: string;
  showAddRoster: boolean;
  canShowPatternsCombo: boolean;
  canvasIsReady?: boolean;
  jerseyThumbColorMap?: any
  activeStep: string;
  rosterItems?: RosterItem[];
  rosterSizeOptions: any[];
  products?: any[],
  partnerKey?: string,
  stateTax?: string,
  gettingPriceByOrder?: boolean,
  caDecorationArea?: any,
  activeSportName?: string,
  decorationAreas: [],
  coachKey: string;
}

class Customize extends Component<Props> {
  public state = {
    canvasLoaded: false
  };

  private setRenderedState = (state) => {
    this.setState({
      canvasLoaded: state
    });
  }

  private onAddRosterClick(itemNumber) {
    showAddRosterStep({ itemNumber });
  }

  private ensureValidRosterPreview() {
    const { rosterItems, decorationAreas } = this.props;

    const validRosterItemWithBoth = rosterItems.find(item => item.Name && item.Number);
    if (validRosterItemWithBoth) {
      previewRosterItem({
        canvasId: global.__MAIN_CANVAS_ID__,
        decorationAreas: decorationAreas,
        rosterItem: validRosterItemWithBoth
      });
    } else {
      const validRosterItems = rosterItems.filter(item => item.Number);
      if (validRosterItems.length > 0) {
        const lastValidItem = validRosterItems[validRosterItems.length - 1];
        previewRosterItem({
          canvasId: global.__MAIN_CANVAS_ID__,
          decorationAreas: decorationAreas,
          rosterItem: lastValidItem
        });
      }
    }
  }

  public render() {
    const { hidden, activeStep, itemNumber, showAddRoster, rosterItems, rosterSizeOptions, gettingPriceByOrder, products, partnerKey, stateTax } = this.props;
    let className = `customize flex-one ${activeStep === "customize" ? "no-footer" : ""}`;
    const reviewButtonDisabled = gettingPriceByOrder;
    const reviewButtonIconCls = gettingPriceByOrder ? 'fas fa-circle-notch fa-spin' : "fa-caret-right";
    const reviewButtonLabel = gettingPriceByOrder ? '' : 'REVIEW';
    const caMsg = 'You have enabled C/A on the customize tab but no roster entries have a C/A selected. Your order will be produced without jerseys containing a C or A unless a roster entry has a C or A selected. Would you like to continue?';
    const nameNumberMsg = 'You have a roster entry with no name and/or number. If a name and/or number is not included in the roster entry, that product will be produced without a name and/or number. Would you like to continue?';

    if (hidden) {
      className += ' customize-hidden';
    }

    const goToReviewStep = () => {
      this.ensureValidRosterPreview();

      const { caDecorationArea, activeSportName, canShowReviewStep, rosterItems } = this.props;
      const hasIncorrectSizeOptions = rosterItems.filter(items => (items.Size !== "") && !rosterSizeOptions.includes(items.Size));
      const hasEmptySizeOrQuantity = hasIncorrectSizeOptions?.length === 0 ? checkForEmptySizeOrQuantity() : false;
      const hastEmptyNameOrNumber = rosterItems.filter(items => (items.Name === "" || items.Number === ""));
      const isCAHidden = caDecorationArea && caDecorationArea.DesignElement.hidden;
      const hasEmptyCA = !isCAHidden ? (activeSportName?.toLowerCase() === 'hockey' && rosterItems.filter(item => item.CA === "")) : [];

      function checkForEmptySizeOrQuantity() {
        const roster = rosterItems.filter(function (item) {
          let clone = Object.assign({}, { ...item });
          // need to clone roster row object and remove keys that are not being completed manually
          delete clone.Id;
          delete clone.SizePriceOffset;
          delete clone.errorMessage;

          // the roster can be empty as long as the only active row in the grid does not have any cell with data
          if (!Object.values(clone).every(x => x === null || x === '')) {
            return (clone.Size === "" || clone.Quantity === "")
          } else {
            return false
          };
        });

        return roster.length > 0;
      }

      if (!canShowReviewStep || hasIncorrectSizeOptions.length > 0 || hasEmptySizeOrQuantity) {
        showCustomMsgPopup({
          title: 'Please select a size and quantity.',
          onClick: checkRosterItems,
          showCancel: false,
          showConfirm: true
        });
      }
      else if (!canShowReviewStep || hastEmptyNameOrNumber.length > 1 || hasEmptyCA.length > 1) {
        if (hastEmptyNameOrNumber.length > 1) {
          nameAndNumberPopup(nameNumberMsg, hasEmptyCA.length > 1 ? caPopup : redirect);
        } else if (hasEmptyCA) {
          caPopup();
        }
      } else {
        if (this.props.coachKey)
          showReviewStep({ products, partnerKey, stateTax });
        else
          showCustomMsgPopup({
            title: 'Please, login to continue.',
            onClick: showLogin,
            showCancel: true,
            showConfirm: true
          });
      }
    }

    function nameAndNumberPopup(title, fn) {
      showCustomMsgPopup({
        title,
        onClick: fn,
        showCancel: true,
        showConfirm: true,
        showConfirmMsg: "Yes",
        showCancelMsg: "No, I’m not done."
      });
    }

    function caPopup() {
      showCustomMsgPopup({
        title: caMsg,
        onClick: redirect,
        showCancel: true,
        showConfirm: true,
        showConfirmMsg: "Yes",
        showCancelMsg: "No, I’m not done."
      });
    }

    function checkRosterItems() {
      validateRosterItems({ itemNumber: itemNumber, rosterItem: rosterItems });
      showAddRosterStep({ itemNumber });
      hideModal();
    }

    const redirect = () => {
      hideModal();
      if (this.props.coachKey)
        showReviewStep({ products, partnerKey, stateTax });
      else
        showCustomMsgPopup({
          title: 'Please, login to continue.',
          onClick: showLogin,
          showCancel: true,
          showConfirm: true
        });
    }

    return (
      <div className={className}>
        <div className="customize-body flex-one">
          <div className="canvas-wrapper flex-row">
            <Canvas
              elementId={global.__MAIN_CANVAS_ID__}
              className="main-canvas-el column-one flex-one"
              backgroundColor="000000"
              defaultCameraState={global.__DEFAULT_CAMERA_STATE__}
              isReady={this.props.canvasIsReady}
              parentCallback={this.setRenderedState}
            />
            <div className="canvas-header">Click And Drag To Rotate</div>
            <CustomizeHud />
            <CustomizeToolsHud />
            <Button
              className="add-roster-button ui-primary partner-background-color1"
              disabled={!this.props.canAddRosterItem}
              iconAlign="right"
              iconCls="fa-caret-right"
              label="Add Roster"
              onClick={() => this.onAddRosterClick(itemNumber)} />
          </div>
          <RosterGrid
            canvasRotated={false}
            hidden={!showAddRoster} />
          <CustomizeTabs
            hidden={showAddRoster} />
        </div>
        <div className={'customize-footer flex-row' + (showAddRoster ? '' : ' hidden')}>
          <Button
            className="ui-gray btn-primary"
            iconCls="fa-caret-left"
            label="CUSTOMIZE"
            onClick={(e) => {
              showCustomizeStep();
            }
            } />
          <div className="flex-one"></div>
          <Button
            className="ui-primary btn-primary partner-background-color1"
            iconAlign="right"
            disabled={reviewButtonDisabled}
            iconCls={reviewButtonIconCls}
            label={reviewButtonLabel}
            onClick={() => goToReviewStep()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const product = activeProductFromApplicationState(state);
  const rosterItems = product ? product.RosterItems : [];
  const rosterSizeOptions = product ? product.RosterSizeOptions : [];
  const caDecorationArea = state.CanvasFullState.decorationAreas?.length > 0 && findCADecorationArea(state.CanvasFullState.decorationAreas);
  const activeSportName = state.GUI.activeSport?.Name;
  const coachKey = state.Session.coachesKey;

  return {
    canSubmitOrder: canSubmitOrder(state),
    canAddRosterItem: canAddRosterItem(state),
    canShowPatternsCombo: state.GUI.selectedPatternDocumentId !== undefined,
    canShowReviewStep: canShowReviewStep(state),
    itemNumber: state.Order.activeItemNumber,
    canvasIsReady: state.CanvasFullState.isReady,
    jerseyThumbColorMap: state.Thumb3D.jerseyThumbColorMap,
    activeStep: state.GUI.activeStep,
    rosterItems: rosterItems,
    rosterSizeOptions,
    products: state.Order.products,
    partnerKey: state.Session.partnersKey,
    gettingPriceByOrder: state.App.gettingPriceByOrder,
    caDecorationArea,
    activeSportName,
    decorationAreas: state.CanvasFullState.decorationAreas,
    coachKey: coachKey
  };
};

export default connect(mapStateToProps)(Customize);
