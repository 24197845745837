import axios, { AxiosRequestConfig } from 'axios'
import { getBackendApiUrl } from '../../backendApi/backendApi';

export interface IHttpClientRequestParameters<T> {
  endPoint: string
  headers?: any
  method: string
  payload?: T
}

export interface IHttpClient {
  get<T>(parameters: IHttpClientRequestParameters<T>): Promise<T>
  post<T>(parameters: IHttpClientRequestParameters<T>): Promise<T>
}

export function getToken(): string {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('apiToken') ?? localStorage.getItem('ubdesigner2.apiToken') ?? '';

  if (token) {
    localStorage.setItem('ubdesigner2.apiToken', token);
  }

  return token;
}

export class HttpClient implements IHttpClient {
  public _url: string;
  public _endPoint: string;

  constructor(url: string) {
    this._url = url;
  }

  public encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join('=')).join('&');

  public get<T>(parameters: IHttpClientRequestParameters<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { endPoint, method, payload, headers } = parameters;

      const apiToken = getToken();

      if (!apiToken) {
        return reject('Token not found in the URL');
      }

      const options: AxiosRequestConfig = {
        headers: {
          ...headers,
          'Authorization': `Bearer ${apiToken}`
        }
      };

      const url = buildUrl(this._url, endPoint, method) + '?' + this.encodeGetParams(payload);

      axios
        .get(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  public post<T>(parameters: IHttpClientRequestParameters<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { endPoint, method, payload, headers } = parameters;

      const apiToken = getToken();

      if (!apiToken) {
        return reject('Token not found in the URL');
      }

      const options: AxiosRequestConfig = {
        headers: {
          ...headers,
          'Authorization': `Bearer ${apiToken}`
        }
      };

      const url = buildUrl(this._url, endPoint, method);

      axios
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
}

function buildUrl(base: string, endPoint: string, method: string): string {
  const url = [base];

  if (endPoint) {
    url.push(endPoint);
  }

  url.push(method);

  return url.join('/');
}

export const backendClient = new HttpClient(getBackendApiUrl());
