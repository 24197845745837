import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { ColorZoneBrush } from '../domain/Design/ColorZone';
import { ColorZoneFillTarget, fillTargetIsColorZone } from '../domain/Design/ColorZoneFillTarget'
import { DecorationArea } from '../domain/Design/DecorationArea';
import { enumDecorationAreas, getNamedColorZones, hasDesignFigures, hasRosterItemFigures } from '../usecases/actions/canvas';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { emitCanvasEventUseCase } from '../usecases/emitCanvasEvent';
import { initCanvasUseCase } from '../usecases/initCanvas';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { setFillUseCase, SetFillUseCaseOptions } from '../usecases/setFill';
import { setOrbitModeUseCase } from '../usecases/setOrbitMode';
import { setPanModeUseCase } from '../usecases/setPanMode';
import { toggleDecorationAreaHiddenUseCase } from '../usecases/toggleDecorationAreaHidden';
import { toggleDecorationAreaMultiHiddenUseCase, ToggleDecorationAreaMultiDisabledOptions } from '../usecases/toggleDecorationAreaMultiHidden';
import { scaleDesignElementUseCase } from '../usecases/scaleDesignElement';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { DecorationAreaReducer } from './DecorationAreaReducer';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { clearDecorationAreaUseCase } from '../usecases/clearDecorationArea'
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { setDecorationAreaActiveUseCase } from '../usecases/setDecorationAreaActive';
import { loadCanvasDocId, loadCanvasDocIdUseCase } from '../usecases/loadCanvasDocId';

import { match } from 'minimatch';
import { reportMessage } from '../../gui/util/rollbar';
export interface CanvasFullStateReducerState {
  canRedo: boolean;
  canUndo: boolean;
  canvasId: string;
  colorZoneBrushMap: any;
  colorZoneGroups: any;
  decorationAreas: DecorationArea[];
  docId: string;
  hasDesignFigures: boolean;
  hasRosterItemFigures: boolean;
  inited: boolean;
  isOrbitMode: boolean;
  isPanMode: boolean;
  isReady: boolean;
};

const initState = {
  canRedo: false,
  canUndo: false,
  canvasId: undefined,
  colorZoneBrushMap: [],
  decorationAreas: [],
  colorZoneGroups: [],
  docId: undefined,
  hasDesignFigures: false,
  hasRosterItemFigures: false,
  inited: false,
  isOrbitMode: false,
  isPanMode: false,
  isReady: false,
};

export function CanvasFullStateReducer (state: CanvasFullStateReducerState = initState, actionResult: UseCaseResult): CanvasFullStateReducerState {

  switch (actionResult.type) {

    case initCanvasUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        return Object.assign({}, state, {
          inited: actionResult.data.inited
        });
      }

      return state;

    case addProductUseCase.type:
    case loadSubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
    case loadCanvasDocIdUseCase.type:
      if (actionResult.start() || actionResult.failure()) {
        if (actionResult.failure())
          reportMessage(actionResult.type, actionResult.rejectionReason);;
        return Object.assign({}, initState, {
          inited: state.inited,
          isReady: false
        });
      }

      if (actionResult.success()) {
        const canvasId = actionResult.data.canvasId;
        const canvas = CanvasAreaFactory.getCanvasById(canvasId);
        const decorationAreas = enumDecorationAreas(canvas);

        return Object.assign({}, state, {
          canvasId,
          colorZoneBrushMap: getNamedColorZones(canvasId),
          decorationAreas: decorationAreas.map((area) => {
            return DecorationAreaReducer(area, actionResult);
          }),
          docId: actionResult.data.docId,
          isReady: true
        });
      }

      return state;

    case editProductUseCase.type:
      if (actionResult.start() || actionResult.failure()) {
        if (actionResult.failure())
          reportMessage(actionResult.type, actionResult.rejectionReason);;
        return Object.assign({}, initState, {
          inited: state.inited,
          isReady: false
        });
      }

      if (actionResult.success()) {
        const canvasId = actionResult.data.canvasId;
        const canvas = CanvasAreaFactory.getCanvasById(canvasId);
        const decorationAreas = enumDecorationAreas(canvas);

        return Object.assign({}, state, {
          canvasId,
          decorationAreas: decorationAreas.map((area) => {
            return DecorationAreaReducer(area, actionResult);
          }),
          isReady: true,
          colorZoneBrushMap: getNamedColorZones(canvasId)
        });
      }

      return state;


    case emitCanvasEventUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const canvasId = actionResult.data.canvasId || state.canvasId;
        const canvas = CanvasAreaFactory.getCanvasById(canvasId);
        const decorationAreas = state.decorationAreas.map(d => DecorationAreaReducer(d, actionResult));
        return Object.assign({}, state, {
          canRedo: canvas.canRedo(),
          canUndo: canvas.canUndo(),
          decorationAreas,
          hasDesignFigures: hasDesignFigures(canvas),
          hasRosterItemFigures: hasRosterItemFigures(canvas),
          isOrbitMode: canvas.isOrbitMode,
          isPanMode: canvas.isPanMode
        });
      }

      return state;

    case setOrbitModeUseCase.type:
    case setPanModeUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const canvasId = actionResult.data.canvasId || state.canvasId;
        const canvas = CanvasAreaFactory.getCanvasById(canvasId);

        return Object.assign({}, state, {
          isOrbitMode: canvas.isOrbitMode,
          isPanMode: canvas.isPanMode
        });
      }

      return state;

    case toggleDecorationAreaHiddenUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const das = state.decorationAreas.map(d => DecorationAreaReducer(d, actionResult))
        return Object.assign({}, state, {
          decorationAreas: das
        });
      }

      return state;

    case toggleDecorationAreaMultiHiddenUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      const result: ToggleDecorationAreaMultiDisabledOptions = actionResult.data ;
      if (actionResult.success()) {
        return Object.assign({}, state, {
          decorationAreas: state.decorationAreas.map(d => DecorationAreaReducer(d, actionResult))
        });
      }

      return state;

    case setDecorationAreaActiveUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        return Object.assign({}, state, {
          decorationAreas: setActiveDecorationAreas(state.decorationAreas, actionResult.data.decorationArea)
        });
      }

      return state;

    case loadCanvasDocIdUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const canvasId = actionResult.data.canvasId;

        return Object.assign({}, state, {
          canvasId,
          docId: actionResult.data.docId,
          isReady: true
        });
      }

      return state;

    case clearDecorationAreaUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const newState = resetDesignElementFromDecorationArea(state, actionResult.data.designElement.decorationAreaId, actionResult.data.designElement.canvasId );
        return newState;
      }

      return state;

    case scaleDesignElementUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        let decorationAreas = state.decorationAreas.map(d => DecorationAreaReducer(d, actionResult));
        let targetDecorationArea = decorationAreas.filter( (d) => { return d.Id == actionResult.data.designElement.decorationAreaId })[0];
        targetDecorationArea.DesignElement.scale *= actionResult.data.factor;
        return Object.assign({}, state, {
          decorationAreas: decorationAreas
        });
      }

      return state;
    case setFillUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success() && fillTargetIsColorZone(actionResult.data.fillTarget)) {
        const data = actionResult.data as SetFillUseCaseOptions;
        const colorZoneFillTarget = data.fillTarget as ColorZoneFillTarget;
        const zoneBrush: ColorZoneBrush = { Name: colorZoneFillTarget.colorZone.Name, Value: data.brush };

        return Object.assign({}, state, {
          colorZoneBrushMap: replaceUpdatedColorZoneBrush( state.colorZoneBrushMap, zoneBrush )
        });
      }
  }

  return state;
}

function setActiveDecorationAreas(das: DecorationArea[], da:DecorationArea)
{
  das.forEach( (m)=>{
    if (m.Type == da.Type)
      m.Active = (m.Id == da.Id); 
  });
  return das;
}

function replaceUpdatedColorZoneBrush( state: ColorZoneBrush[], newColorZoneBrush: ColorZoneBrush ) {
  const i = state.findIndex( (c) => { return c.Name == newColorZoneBrush.Name });
  if ( i >= 0 )
    state[i] = newColorZoneBrush ;
  return state ;
}

function resetDesignElementFromDecorationArea( state: CanvasFullStateReducerState, daId: string, canvasId: string,)
{
  const daIx = state.decorationAreas.findIndex((da)=> { return da.Id == daId});
  state.decorationAreas[daIx].DesignElement = {
    canvasId: canvasId,
    decorationAreaId: daId
  }; ;
  return state; 
}