import React, { createRef } from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from '../../../../gui/util/isMobile';
import resizeMonitor from '../../../util/resizeMonitor';
import uniqueKey from '../../../util/uniqueKey';

import styles from './SelectInputMenu.module.css';

interface Props {
  label: string
  options: any
  position: string
  selectHandler: Function
  showByTarget: any
  value: string
  thumbInput: boolean
}

let _instance;

function Items(props) {
  return <div className={styles.itemsList}>
    {props.items.map(function (item) {
      const cls = props.value === item.value ? styles.selectedItem : styles.item;
      const key = uniqueKey();
      const onClick = () => props.scope.select(item.value);

      if (props.thumbInput)
        return (<div
          className={cls}
          key={key}
          style={{
            backgroundImage: `url(${item.data.thumb})`,
            height: item.data.thumbHeight + 'px',
            minWidth: item.data.thumbWidth + 'px'
          }}
          onClick={onClick}></div>)
      else
        return (<div
          className={[cls, styles.displayColumn].join(' ')}
          key={key}
          onClick={onClick}
        >
          {item.text}
          {item.description &&
            <span className={styles.textDescription}>
              {item.description}
            </span>
          }
        </div>)
    })}
  </div>;
}

export class SelectInputMenu extends Component<Props> {

  public static show(props) {
    const instance = _instance;

    if (instance) {
      instance.expand();
    }

    ReactDOM.render(<SelectInputMenu
      label={props.label}
      options={props.options}
      position={props.position || 'bottom'}
      selectHandler={props.selectHandler}
      showByTarget={props.showByTarget}
      thumbInput={props.thumbInput}
      value={props.value} />, document.getElementById('menuRoot'));
  }

  public anchorRef = createRef<HTMLDivElement>();
  public menuRef = createRef<HTMLDivElement>();

  public state = {
    anchorPos: 'top',
    collapsed: false
    // left: undefined,
    // top: undefined
  }

  public render() {
    if (this.state.collapsed) {
      return null;
    }

    const props = this.props;
    const label = props.label;
    const thumbInput = props.thumbInput;
    const options = props.options;
    const position = props.showByTarget.getBoundingClientRect();
    const value = props.value;
    const showMobile = isMobile();
    const style = showMobile ? undefined : {
      left: position.left + 'px',
      top: (position.top + 10) + 'px'
    };
    const className = showMobile ? styles.mobileMenu : (styles.menu + ' partner-border-color2');
    let arrowClassName = showMobile ? styles.mobileArrow : (styles.arrow + ' partner-border-bottom-color2');
    const headerClassName = showMobile ? (styles.mobileHeader + ' partner-color2') : (styles.header + ' partner-background-color2');
    const headerIconClassName = styles.headerIcon + ' ' + 'fa fa-times';

    arrowClassName += ' arrow-' + this.state.anchorPos;

    return (
      <div className={styles.modalWrap}>
        <div
          className={styles.modalOverlay}
          onClick={this.onModalOverlayClick.bind(this)}></div>
        <div className={className} ref={this.menuRef} style={style}>
          <div className={headerClassName}>
            <span className={styles.headerText}>{label}</span>
            <span
              className={headerIconClassName}
              onClick={this.collapse.bind(this)}></span>
          </div>
          <Items scope={this} items={options} value={value} thumbInput={thumbInput} />
          <div className={arrowClassName} ref={this.anchorRef}></div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    _instance = this;
    resizeMonitor(this.syncPosition.bind(this), 150);
    this.syncPosition();
  }

  public componentDidUpdate() {
    this.syncPosition();
  }

  private syncPosition() {
    const doSync = !this.state.collapsed && !isMobile();

    if (doSync) {
      const anchor = this.anchorRef.current;
      const menu = this.menuRef.current;
      const position = this.getPosition();

      if (position.anchorPos === 'bottom') {
        anchor.style.bottom = '-14px';
        anchor.style.top = 'auto';
        anchor.style.transform = 'rotate(180deg)';
      }

      else if (position.anchorPos === 'top') {
        anchor.style.bottom = 'auto';
        anchor.style.top = '-14px';
        anchor.style.transform = 'rotate(0deg)';
      }

      menu.style.left = position.left + 'px';
      menu.style.top = (position.top + 10) + 'px';
    }
  }

  private onModalOverlayClick() {
    this.collapse();
  }

  private select(value) {
    const handler = this.props.selectHandler;

    if (handler) {
      handler(value);
    }

    this.collapse();
  }

  public expand() {
    this.setState({
      collapsed: false
    });
  }

  public collapse() {
    this.setState({
      collapsed: true
    });
  }

  private getPosition() {
    const anchor = this.anchorRef.current;
    const menu = this.menuRef.current;
    const target = this.props.showByTarget;
    const position = this.props.position;
    const anchorBox = anchor.getBoundingClientRect();
    const menuBox = menu.getBoundingClientRect();
    const targetBox = target.getBoundingClientRect();
    const bodyBox = document.body.getBoundingClientRect();
    const anchorPos = _getAnchorPosition(bodyBox, targetBox, menuBox, position);
    let anchorLeft;
    let anchorTop;
    let left;
    let top;
    let menuCenter;
    let targetCenter;

    if (anchorPos === 'bottom' || anchorPos === 'top') {
      menuCenter = menuBox.width / 2;
      targetCenter = targetBox.left + (targetBox.width / 2);

      anchorLeft = targetCenter - (anchorBox.width / 2);
      left = targetCenter - menuCenter

      if (anchorPos === 'bottom') {
        anchorTop = targetBox.top - anchorBox.height;
        top = targetBox.top - menuBox.height - (anchorBox.height * 2);
      } else {
        anchorTop = targetBox.bottom;
        top = targetBox.bottom;
      }
    }

    else {
      menuCenter = menuBox.height / 2;
      targetCenter = targetBox.top + (targetBox.height / 2);

      anchorTop = targetCenter - (anchorBox.height / 2);
      top = targetCenter - menuCenter;

      if (anchorPos === 'right') {
        anchorLeft = targetBox.left - anchorBox.width;
        left = targetBox.left - menuBox.width - (anchorBox.height / 2);
      } else {
        anchorLeft = targetBox.right;
        left = targetBox.right + (anchorBox.width / 2);
      }
    }

    // Constrain
    if (left < 0) {
      left = 5;
    } else if (left + menuBox.width > bodyBox.width) {
      left = bodyBox.width - menuBox.width - 5;
    }

    // Constrain
    if (top < 0) {
      top = 0;
    } else if (top + menuBox.height > bodyBox.height) {
      top = bodyBox.height - menuBox.height - 5;
    }

    return ({ anchorPos, left, top });
  }

}


function _getAnchorPosition(bodyBox, targetBox, menuBox, position) {
  if (position === 'bottom' || position === 'top') {
    // Bottom of the menu below the bottom of the viewport?
    if (targetBox.bottom + menuBox.height > bodyBox.height) {
      return 'bottom';
    }

    return 'top';
  }

  else {
    // Right of the menu past the width of the viewport?
    if (targetBox.right + menuBox.right > bodyBox.width) {
      return 'right';
    }

    return 'left';
  }
}