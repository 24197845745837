import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import SearchFactory from '../../gui/services/search'
import { loadPatternConfig$ } from './actions/pattern'
import { patternBrushFromServerResponse  } from '../domain/Pattern/Pattern';

export interface loadPatternsUseCaseOptions {
  published: boolean
}

export const loadPatternsUseCase: UseCase = {
  type: 'LOAD_PATTERNS',

  run: ( options: loadPatternsUseCaseOptions ) => {
    let patternsSearchService = new SearchFactory('Patterns');
    return loadPatternConfig$().then((c)=>{
      return patternsSearchService.search().then((patterns)=>{
        let configs = JSON.parse(c.Data) ;
        //if (options.published)
          //configs = Object.fromEntries(Object.entries(configs).filter(([k,v]) => (v as any).publishedDocId));
        let parsedPatterns = [];
        // patterns.forEach((p)=>{
        //   if (configs[p.Id]){
        //     const config  = ( options.published && configs[p.Id].public ) ? configs[p.Id].public : configs[p.Id];
        //     const pattern = patternBrushFromServerResponse(p, config);
        //     parsedPatterns.push(pattern);
        //   }
        // });
        const orderedConfigIds = Object.keys(configs).map ( k => ( { key: k, order: configs[k].metadata?.DisplayOrder?.length > 0 ? parseInt(configs[k].metadata?.DisplayOrder) : Infinity } ) ).sort((a, b) => (a.order > b.order) ? 1 : -1)
        orderedConfigIds.forEach((c) => {
          //const config  = ( options.published && configs[c.key].public ) ? configs[c.key].public : configs[c.key];
          const config = configs[c.key];
          const p = patterns.find( (pattern) => pattern.Id == c.key );
          if(p)
          {
            const pattern = patternBrushFromServerResponse(p, config);
            parsedPatterns.push(pattern);
          }
        })
        return Cx.resolve({ patterns: parsedPatterns });
      })
    });
  }
};

/**
 * @category Use Case
 */
export function loadPatterns( options: loadPatternsUseCaseOptions ) {
  app.runUseCase( loadPatternsUseCase, options );
}
