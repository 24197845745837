import React, { createRef, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { addProduct } from '../../../app/initApp';
import { setActiveProductItemNumber } from '../../../app/usecases/setActiveProductItemNumber'
import global from '../../services/global';
import { hash } from '../../util/hash'
import swapJerseyColorList from '../../../app/usecases/swapJerseyColorList'
import Button from '../misc/Button';
import { cloneDeep, uniqueId } from 'lodash';
import ColorSelector from '../misc/colorselector/ColorSelector';
import { get3DRenderThumbnailUrl } from '../../../backendApi/backendApi';
import { setJerseySchemmeColorSelected } from '../../../app/usecases/setJerseySchemmeColorSelected';

// Styles
import './DesignStyles.css';

declare let Cx: any;

interface Props {
  hidden: boolean;
  designStyles: any;
  grayGood: any;
  jerseyDefaultColors: any;
  jerseyOriginalColors: any;
  jerseyAppliedDefaultColors: any;
  colorList: Cx.Color[];
  init: boolean;
  activeGrayGood: any;
}

const DesignStyles = (props: Props) => {
  let className = 'gray-goods flex-one design-step';
  const jerseyDefaultColors = props.jerseyDefaultColors;
  const hidden = props.hidden;
  const colorList = props.colorList;
  const brushList = getBrushListFromJerseyColors(jerseyDefaultColors);
  const brushItems = cloneDeep(brushList);
  const edition = useRef(null);
  const init = props.designStyles?.length ? true : false;
  const activeGrayGood = props.activeGrayGood;

  if (hidden) {
    className += ' hidden';
  }

  if (init) {
    edition.current.classList.remove('not-clickable');
  }

  const items = props.designStyles.map((designStyle) => {
    const originalColors = JSON.stringify(props.jerseyAppliedDefaultColors) == JSON.stringify(props.jerseyOriginalColors);
    const url = get3DRenderThumbnailUrl(designStyle.DocId, props.jerseyAppliedDefaultColors, originalColors, props.activeGrayGood.ThumbnailScaling);

    return {
      ...designStyle,
      url: url
    }
  });

  function displayLoader(ref) {
    const el = ref.current;
    const edit = edition.current;

    Array.from(rootRef.current.children).forEach((item) => {
      // @ts-ignore
      item.style.pointerEvents = 'none';
    });

    edit.className += ' not-clickable';
    el.children[1].children[0].style.display = 'none';
    el.children[1].children[1].style.display = 'block';
    el.className += ' selected-thumb';
  }

  function onItemClick(item, ref) {
    displayLoader(ref);
    // Need to set this first so UpdatedCdl is assigned to the proper design
    const itemNumber = hash(7).toUpperCase();
    setActiveProductItemNumber({ activeItemNumber: itemNumber });

    addProduct({
      canvasId: global.__MAIN_CANVAS_ID__,
      designStyle: item,
      grayGood: props.grayGood,
      itemNumber: itemNumber,
      colorScheme: props.jerseyAppliedDefaultColors,
    });
  }

  // Section: render thumbnail once scrolled into view
  const rootRef = createRef<HTMLDivElement>();
  const itemRefs = items.reduce((acc: any, value: any) => {
    // @ts-ignore
    acc[value.Name] = {
      id: value.Name,
      ref: React.createRef(),
      urlRendered: false
    };

    return acc;
  }, {});

  const brushes = brushItems
    .reduce((acc: any, value: any, index: number) => {
      // @ts-ignore
      acc[index] = {
        color: value.color,
      };

      return acc;
    }, [{}]);

  const callback = (entries: any) => {
    // @ts-ignore
    entries.forEach((entry: any) => {
      const dom = entry.target;
      const name = dom.id;
      const thumb = itemRefs[name];

      if (entry.intersectionRatio > 0.5 && !thumb.rendered) {
        const foundDesign = items.find((f) => f.Name === name);

        if (foundDesign) {
          dom.childNodes[0].children[1].style.filter = "blur(3px)";
          dom.childNodes[0].children[1].classList.remove("fade-in");
          dom.children[0].children[1].style.width = '260px';
          dom.children[0].children[1].style.height = '260px';
          dom.children[0].children[1].src = foundDesign.url;
          thumb.rendered = true;
        }
      }
    });
  };

  function getBrushListFromJerseyColors(jerseyColorScheme) {
    let brushes = [];
    jerseyColorScheme.forEach((c) => {
      let brush = new Cx.Brush({
        color: new Cx.Color({
          alpha: 1,
          name: c.SpotcolorName,
          rgb: c.RGB,
          spotColorName: c.SpotColorName
        })
      });
      brushes.push(brush);
    });
    return brushes;
  }

  function getColorList() {
    let colors = [];
    colorList.forEach((c) => {
      let color = {
        value: `#${c.argb.replace(/(..)(......)/, '$2$1')}`,
        label: c.name,
        color: c
      }
      colors.push(color);
    });
    return colors;
  }

  const observer = new IntersectionObserver(callback, {
    root: rootRef.current,
    threshold: new Array(101).fill(0).map((v, i) => i * 0.01)
  });

  useEffect(() => {
    Object.values(itemRefs).forEach((value: any) => {
      if (value.ref.current) {
        observer.observe(value.ref.current);
      }
    });
  }, [items, itemRefs]);

  function applySelectedColors() {
    brushes.map((item, i) => {
      setJerseySchemmeColorSelected({
        index: i,
        color: item.color
      });
    });

    swapJerseyColorList();
  }

  function handleOnLoad(e, ref) {
    const imgElement = e.target;
    ref.current.childNodes[0].children[1].style.filter = "blur(0px)";
    ref.current.childNodes[0].children[1].classList.add("fade-in");
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  const setBrush = (item, index) => {
    brushes[index].color = item.color;
  }

  function getDocThumb(id) {
    const thumbSize = 260;
    const requestModel = {
      Doc: { Id: id },
      Format: 'png',
      Height: thumbSize,
      Width: thumbSize
    };

    return Cx.Core.Doc3D.thumbnailUrl(requestModel);
  }

  return <div className={className}>
    <div className="design-container">
      <div className="jersey-column">
        <div className="edit-options" ref={edition}>
          <div className="header2Text partner-color2">
            Select Team Colors
          </div>

          {brushList.map((item, i) => {
            return <div
              key={uniqueId()}
              className="colors-selector">
              <ColorSelector
                options={getColorList()}
                value={`#${item.color.argb.replace(/(..)(......)/, '$2$1')}`}
                name="colors"
                selectedIndex={i}
                selectedColor={setBrush}
              />
            </div>
          })}

          <div className="divider"></div>

          <Button
            className="apply-btn btn-primary ui-primary partner-background-color1"
            iconCls="fa-plus"
            label="APPLY CHANGES"
            onClick={() => {
              applySelectedColors();
            }} />
        </div>
      </div>

      <div className="thumb-column">
        <div className="list" ref={rootRef}>
          {items.map(function (item: any) {
            const name = item.Name;
            return <div
              className="item"
              id={name}
              key={uniqueId()}
              onClick={() => { onItemClick(item, itemRefs[name].ref) }}
              // @ts-ignore
              ref={itemRefs[name].ref}
              title={name}>
              <div className="thumb">
                <div></div>
                <img onLoad={(e) => handleOnLoad(e, itemRefs[name].ref)} src={getDocThumb(activeGrayGood?.DocId)} className="blur" />
              </div>
              <div className="name">
                <div className="name-txt">
                  {name}
                </div>
                <div className="loader-text"><span className='icon fas fa-circle-notch fa-spin'></span> Loading design</div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
};

const mapStateToProps = (state) => {
  return {
    designStyles: state.GUI.activeGrayGoodDesignStyles ? state.GUI.activeGrayGoodDesignStyles : [],
    grayGood: state.GUI.activeGrayGood,
    jerseyOriginalColors: state.Config.jerseyOriginalColors,
    jerseyDefaultColors: state.Config.jerseyDefaultColors,
    jerseyAppliedDefaultColors: state.Config.jerseyAppliedDefaultColors,
    colorList: state.Config.colorList,
    init: false,
    activeGrayGood: state.GUI.activeGrayGood
  }
}

export default connect(mapStateToProps, null)(DesignStyles);
