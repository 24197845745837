import { types } from 'util';
import { DesignElement } from './DesignElement';

export enum DECORATION_AREA_TYPES {
  BACK_NUMBER = 'Back-Number',
  CA = 'C-A',
  MAIN_LOGO = 'Main-Logo',
  NAME = 'Name',
  NUMBER = 'Number',
  SINGLE_GRAPHIC = 'Single-Graphic',
  MULTI_GRAPHIC = 'Multi-Graphic',
  SLEEVE_NUMBER = 'Sleeve-Number',
  PARTNER_LOGO = 'Partner-Logo',
  RIB_NUMBER = 'Rib-Number'
};

export interface DecorationArea {
  DesignElement?: DesignElement;
  Figure: Cx.Figure;
  GroupId?: string;     // Not unique and used to update all of GroupId if present
  GroupLabel?: string;
  Id: string;         // Unique and used to link a design element to a decoration area
  Label: string;      // The display label to show
  Type: string;       // Not unique and used to determine what editor to show
  Active: boolean;
}

//const VALID_LOGO_LOCATIONS = ['LOCATION 1', 'LOCATION 2'];

// Is helpers

export function isRosterDecorationAreaType(type: string): boolean {
  const types = DECORATION_AREA_TYPES;
  return (type === types.NAME || type === types.BACK_NUMBER || type === types.SLEEVE_NUMBER) ? true : false;
}

export function isNameDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.NAME;
}

export function isNumberDecorationArea(props: DecorationArea): boolean {
  return props.Type.indexOf(DECORATION_AREA_TYPES.NUMBER) !== -1;
}

export function isBackNumberDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.BACK_NUMBER;
}

export function isRibNumberDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.RIB_NUMBER;
}

export function isSleeveNumberDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.SLEEVE_NUMBER;
}

export function isCADecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.CA ? true : false;
}

export function isSingleGraphicDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.SINGLE_GRAPHIC ? true : false;
}

export function isMultiGraphicDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.MULTI_GRAPHIC ? true : false;
}

export function isMainLogoDecorationArea(props: DecorationArea): boolean {
  return props.Type === DECORATION_AREA_TYPES.MAIN_LOGO ? true : false;
}

export function isPartnerLogoDecorationArea(props: DecorationArea, locations: any): boolean {
  return (props.Type === DECORATION_AREA_TYPES.PARTNER_LOGO && locations.indexOf(props.Label.toUpperCase()) > -1) ? true : false;
}

// Find helpers

export function findPartnerLogoDecorationAreas(items: DecorationArea[], locations?: any[]): DecorationArea[] { //there could be many logos
  return items.filter((area) => {
    return isPartnerLogoDecorationArea(area, locations);
  });
}

export function findNameDecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isNameDecorationArea(area);
  });
}

export function findBackNumberDecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isBackNumberDecorationArea(area);
  });
}

export function findSleeveNumberDecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isSleeveNumberDecorationArea(area);
  });
}

export function findRibNumberDecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isRibNumberDecorationArea(area) && area.Active;
  });
}

export function findCADecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isCADecorationArea(area);
  });
}

export function findMainLogoDecorationArea(items: DecorationArea[]): DecorationArea {
  return items.find((area) => {
    return isMainLogoDecorationArea(area);
  });
}

export function hasRosterTypeDecorationAreas(items: DecorationArea[]): boolean {
  return items.find((area) => {
    return isNameDecorationArea(area) ||
      isBackNumberDecorationArea(area) ||
      isSleeveNumberDecorationArea(area);
  }) ? true : false;
}

// Array helpers
export function uniqueDecorationAreasByType(decorationAreas: DecorationArea[]): DecorationArea[] {
  const tmp = {};

  decorationAreas.forEach((item) => {
    if (item.Active)
      tmp[item.Type] = item;
  });

  return Object.values(tmp);
}

// Sort the order based on how we want editor shown to user
const DECORATION_AREA_TYPE_ORDER = {
  [DECORATION_AREA_TYPES.NAME]: 1,
  [DECORATION_AREA_TYPES.BACK_NUMBER]: 2,
  [DECORATION_AREA_TYPES.SLEEVE_NUMBER]: 3,
  [DECORATION_AREA_TYPES.MAIN_LOGO]: 4,
  [DECORATION_AREA_TYPES.RIB_NUMBER]: 5,
  [DECORATION_AREA_TYPES.SINGLE_GRAPHIC]: 6
};

export function sortDecorationAreas(decorationAreas: DecorationArea[]): DecorationArea[] {
  const sortedItems = decorationAreas.sort((a: DecorationArea, b: DecorationArea) => {
    const aType = a.Type;
    const bType = b.Type;

    const aOrder = DECORATION_AREA_TYPE_ORDER[aType] || Number.MAX_SAFE_INTEGER;
    const bOrder = DECORATION_AREA_TYPE_ORDER[bType] || Number.MAX_SAFE_INTEGER;

    return aOrder - bOrder;
  });

  return sortedItems;
}

// export function getSingleGraphicLocationOptions (allDecorationAreas: DecorationArea[]): string[] {
//   const areas = allDecorationAreas.filter((item) => {
//     return isSingleGraphicDecorationArea(item);
//   });

//   const locations = areas.map((item) => {
//     return item.Label;
//   }).filter((location) => {
//     return location ? true : false;
//   });

//   return _unique(locations);
// }

// function _unique (arr) {
//   return arr.filter(function (value, index, self) {
//     return self.indexOf(value) === index;
//   });
// }