import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface SetColorTypeMainColorSelectorOptions {
  mainColorTabSelectedOption:number
}

export const setColorTypeMainColorSelectorUseCase:UseCase = {
  type: 'SET_COLOR_TYPE_MAIN_COLOR_SELECTOR',

  run: (options: SetColorTypeMainColorSelectorOptions) => {
    return Cx.resolve({ mainColorTabSelectedOption: options.mainColorTabSelectedOption })
  }
};

export function setColorTypeMainColorSelector(options: SetColorTypeMainColorSelectorOptions){
  app.runUseCase(setColorTypeMainColorSelectorUseCase, options);
}
