import { OrderState } from './OrderState';
import { OrderStateSnippet } from './OrderStateSnippet';

// This is the SavedOrderState from api/createOrderState

export interface SavedOrder {
  archived: boolean;
  coachId: string;
  coachesKey: string;
  id: string;
  lastUpdatedOn: string;
  orderState: OrderState;
  orderStateSnippet: OrderStateSnippet;
}

function filterUnsavedProducts(order) {
  order.forEach((o, i) => {
    if (o.orderStateSnippet.products)
      order[i].orderStateSnippet.products = o.orderStateSnippet.products.filter(product => product.savedDocId != undefined)
  })
  return order;
}

export function savedOrdersFromActionResult(orders): SavedOrder[] {
  const items = orders.map((item): SavedOrder => {
    return {
      archived: item.archived,
      coachId: item.coachId,
      coachesKey: item.coachesKey,
      id: item.id,
      lastUpdatedOn: item.lastUpdatedOn,
      orderState: item.orderState,
      orderStateSnippet: item.orderStateSnippet
    };
  });

  // Sort in descending order by lastUpdatedOn
  return filterUnsavedProducts(items)
    .filter(order => order.orderStateSnippet.products?.length > 0)
    .sort((a, b) => Date.parse(b.lastUpdatedOn) - Date.parse(a.lastUpdatedOn));
}