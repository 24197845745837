import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { Product } from '../../../app/domain/Product/Product';
import { canSaveOrder, canSubmitOrder, canUpdateOrder, saveOrder, SaveOrderUseCaseOptions, showLogin, updateOrder, UpdateOrderUseCaseOptions } from '../../../app/initApp';
import Button from '../misc/Button';
import uniqueKey from '../../util/uniqueKey';
import { coachContactInfo } from '../../../app/usecases/createCoachContactInfo';
import { evaluateSubmittingOrder } from '../../../app/usecases/evaluateSubmittingOrder';
import { selectShippingMethod } from '../../../app/usecases/selectShippingMethod';
import { selectStoreLocation } from '../../../app/usecases/selectStoreLocation';
import SelectInput from '../misc/field/SelectInput';
import { showNewAddressModal } from '../../../app/usecases/showNewAddressModal';
import { Address } from '../misc/NewAddressModal';
import { deliveryLocation } from '../../../app/usecases/setDeliveryLocation';
import { setFreeShipping } from '../../../app/usecases/setFreeShipping';

// Styles
import styles from './ReviewFooter.module.css';

interface Props {
  canSaveOrder?: boolean;
  canSubmitOrder?: boolean;
  canUpdateOrder?: boolean;
  coachKey?: string;
  partnersKey?: string;
  products: Product[];
  savedOrderId?: string;
  savingOrderState?: boolean;
  submittingOrder?: boolean;
  submittedOrder?: boolean;
  initialOrder?: string;
  initialOrderOpenAsCopy?: string;
  originallyCopiedFrom?: string;
  coachContactInfo?: coachContactInfo;
  partnerData?: any;
  defaultStoreLocation?: any;
  selectedStoreLocation?: any;
  orderShippingOptions?: any;
  shippingMethod?: any;
  selectedShippingOption?: any;
  priceSizeOffsetTotal: number;
  minOrderFee: number;
  total: number;
  totalCount: number;
  cadworxSessionId: string;
  totalSalesTax: any;
  activeItemNumber: string;
  productsWithRoster: any;
  newShippingAddress: Address;
  deliveryMethod: string;
}

const ReviewFooter = (props: Props) => {
  const products = props.products;
  const savingOrderState = props.savingOrderState;
  const submittingOrder = props.submittingOrder;
  const total = props.total;
  const productsSubTotal = products.reduce(function (prev, cur) {
    return prev + cur.TotalPrice;
  }, 0);
  const totalCount = props.totalCount;
  const salesTax = props.totalSalesTax;
  const saveButtonDisabled = savingOrderState || products.length === 0;
  const saveButtonIconCls = savingOrderState ? 'fas fa-circle-notch fa-spin' : undefined;
  const saveButtonLabel = savingOrderState ? 'Saving...' : 'Save For Later';
  const submittedOrder = props.submittedOrder;
  const submitButtonDisabled =
    submittedOrder || submittingOrder || (props.initialOrder && !props.initialOrderOpenAsCopy) || !props.selectedShippingOption || products.length === 0 || props.productsWithRoster.length > 0 ||
    (props.partnerData.shipToCustomer && !props.deliveryMethod) || (props.deliveryMethod === "shipToAddress" && !props.newShippingAddress);
  const submitButtonIconCls = submittingOrder ? 'fas fa-circle-notch fa-spin' : undefined;
  const submitButtonLabel = submittingOrder ? 'Submitting...' : 'Submit Order For Review';
  const { orderShippingOptions } = props;
  const shippingOptionsLoaded = orderShippingOptions?.length > 0;
  const [remainingForFreeShipping, setRemainingForFreeShipping] = useState<any>(null);
  const [thresholdType, setThresholdType] = useState<string>('');
  const [isFreeShipping, setIsFreeShipping] = useState<boolean>(false);
  const [shippingMethodDescription, setShippingMethodDescription] = useState<string>("CHOOSE YOUR SHIPPING METHOD");

  const checkIfIsFreeOption = (shippingOption) => {
    const { value, type } = shippingOption?.Threshold || {};

    if (type === 'quantity') {
      return totalCount >= value;
    }

    return value ? (productsSubTotal >= value) : false;
  };

  const getDescription = (shippingOption) => {
    const { value, type } = shippingOption?.Threshold || {};

    if (type === 'quantity') {
      return value ? `FREE SHIPPING buying ${value}+ ITEMS` : null;
    }

    return value ? `FREE SHIPPING on orders over $${value}` : null;
  };

  const shippingOptions = [
    {
      text: shippingOptionsLoaded ? 'Select a shipping method' : 'Loading shippers...',
      value: undefined
    },
    ...(orderShippingOptions || []).map((o) => {
      const { Name, Cost, Code } = o;
      const isFreeOption = checkIfIsFreeOption(o);
      const cost = parseFloat(Cost);
      const description = getDescription(o);

      return {
        text: `${Name}${isFreeOption ? ' (FREE)' : ` ($${cost})`}`,
        value: Code,
        description
      };
    })
  ];

  useEffect(() => {
    if (props.selectedShippingOption) {
      const hasThreshold = props.selectedShippingOption;
      calculateRemainingForFreeShipping(hasThreshold);
    }
  }, [props.selectedShippingOption, totalCount]);

  useEffect(() => {
    if (shippingOptionsLoaded) {
      if (orderShippingOptions?.length > 1)
        setShippingMethodDescription("CHOOSE YOUR SHIPPING METHOD");
      else {
        setShippingMethodDescription("SHIPPING METHOD");
        selectShippingMethod({ shippingMethod: props.orderShippingOptions[0] });
      }
    }
  }, [orderShippingOptions, shippingOptionsLoaded]);

  const storeLocations = [
    { text: props.defaultStoreLocation.name || props.defaultStoreLocation.address1, value: JSON.stringify(props.defaultStoreLocation) },
    ...props.partnerData.addressOptions?.map((o) => {
      return { text: o.name || o.address1, value: JSON.stringify(o) }
    })
  ];

  const uniqueStoreLocations = storeLocations.filter((obj, index, self) => {
    return index === self.findIndex((item) => (
      item.text === obj.text
    ));
  });

  const [isOpen, setOpen] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState<string>(props.deliveryMethod || "Select a delivery location");

  const collapseListener = () => {
    setOpen((prvState) => !prvState);
  };

  let iconChevron = isOpen ? "icon fas fa-chevron-up" : "icon fas fa-chevron-down";

  function onSaveClick() {
    if (props.canUpdateOrder) {
      updateOrder({
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        id: props.savedOrderId,
        products,
        shippingMethod: props.selectedShippingOption,
        deliveryMethod: props.deliveryMethod,
        cadworxSessionId: props.cadworxSessionId,
        minOrderFee: props.minOrderFee,
        shippingAddress: props.newShippingAddress
      } as UpdateOrderUseCaseOptions);
    } else if (props.canSaveOrder) {
      saveOrder({
        activeItemNumber: props.activeItemNumber,
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        products,
        shippingMethod: props.selectedShippingOption,
        deliveryMethod: props.deliveryMethod,
        cadworxSessionId: props.cadworxSessionId,
        minOrderFee: props.minOrderFee,
        shippingAddress: props.newShippingAddress
      } as SaveOrderUseCaseOptions);
    } else {
      showLogin();
    }
  }

  function onSubmitClick() {
    evaluateSubmittingOrder();
  }

  const calculateRemainingForFreeShipping = (selectedOption) => {
    if (selectedOption) {
      const quantitySum = products.reduce((sum, product) => sum + product.TotalQuantity, 0);
      const priceSum = products.reduce((sum, product) => sum + product.TotalPrice, 0);

      const { value, type } = selectedOption?.Threshold || {};
      setThresholdType(type);
      const remaining = type === 'quantity' ? value - quantitySum : value ? (value - priceSum) : null;
      setRemainingForFreeShipping(Math.max(remaining, 0));

      const isFreeShipping = remaining !== null ? remaining <= 0 : false;
      setIsFreeShipping(isFreeShipping);
      setFreeShipping({ isFreeShipping });
    } else {
      setRemainingForFreeShipping(0);
      setIsFreeShipping(false);
      setFreeShipping({ isFreeShipping: false });
    }
  };

  return (
    <>
      <div className={styles.reviewFooter}>
        <div className={styles.header}>
          <div className={styles.title + ' partner-color1'}>Order Summary</div>
        </div>
        <div className={styles.summaryRow}>
          <table className={styles.productSummary}>
            <tbody>
              <div className={styles.shippingWrap}>
                <div className={styles.shippingHeader}>
                  <div className={styles.subtitle + ' partner-color1'}>Shipping</div>
                  <i onClick={collapseListener} className={iconChevron}></i>
                </div>
                {isOpen &&
                  <div className={styles.showShippingDetails}>
                    <div className={styles.disclaimer + ' partner-color3'}>
                      <i className="icon fas fa-exclamation-circle"></i>
                      <div className={styles.importantText + ' partner-color3'}>
                        All products are custom made to order.
                      </div>
                      <div className={styles.importantText + ' partner-color3'}>
                        <span>Please allow 2 weeks to produce your order, not including shipping time.</span>
                        <span>We ship most orders via UPS however occasionally a different carrier is chosen to optimize delivery.</span>
                      </div>
                    </div>
                    {remainingForFreeShipping > 0 &&
                      <tr className={styles.freeShippingDescription + ' partner-color2'}>
                        <td>
                          Add <b>
                            {thresholdType === 'price' ? "$" : ""}
                            {parseFloat(remainingForFreeShipping)}
                            {thresholdType === 'price' ? " MORE" : ""}
                            {thresholdType === 'quantity' ? " MORE ITEMS" : ""}
                          </b> to get free shipping
                        </td>
                      </tr>
                    }
                    <tr className={styles.trRow + ' partner-color2'}>
                      <td className={styles.leftTd}>{shippingMethodDescription}</td>
                      <td className={styles.rigthTd}>
                        <div className={styles.ml2}>
                          {(orderShippingOptions?.length > 1)
                            ?
                            <SelectInput
                              className={styles.field}
                              handler={(value: string) => {
                                if (value) {
                                  const toSelectShippingOption = props.orderShippingOptions.find(s => s.Code == value);
                                  selectShippingMethod({ shippingMethod: toSelectShippingOption });
                                }
                              }}
                              isLoading={!shippingOptionsLoaded}
                              options={shippingOptions}
                              value={props.selectedShippingOption?.Code}
                            />
                            :
                            props.selectedShippingOption &&
                            `${props.selectedShippingOption.Name} ${isFreeShipping ? "- (FREE)" : `- $${props.selectedShippingOption.Cost?.toFixed(2)}`}`
                          }
                        </div>
                      </td>
                    </tr>

                    {/* DELIVERY METHOD (shipToCustomer flag) */}
                    {props.partnerData.shipToCustomer &&
                      <tr>
                        <div>
                          <tr className={styles.trRow + ' partner-color2'}>
                            <td className={styles.leftTd}>SELECT DELIVERY LOCATION</td>
                            <td className={styles.rigthTd}>
                              <div className={styles.ml2}>
                                <SelectInput
                                  className={styles.field}
                                  handler={(value: string) => {
                                    if (value) {
                                      deliveryLocation({ deliveryMethod: value });
                                      setDeliveryMethod(value);
                                    }
                                  }}
                                  options={[{ text: 'Select a delivery location', value: undefined }, { text: "SHIP TO ADDRESS", value: "shipToAddress" }, { text: "STORE PICKUP", value: "storePickup" }]}
                                  value={deliveryMethod}
                                /></div>
                            </td>
                          </tr>
                        </div>
                      </tr>
                    }

                    {
                      (props.partnerData.shipToCustomer && deliveryMethod === "shipToAddress") &&
                      <>
                        <tr className={styles.trRow}>
                          {!props.newShippingAddress
                            ?
                            <><td className={styles.leftTd}></td>
                              <td className={styles.rigthTd}>
                                <span
                                  className={styles.deliveryMethodTxt + ' partner-color1'}
                                  onClick={() => showNewAddressModal({ address: props.newShippingAddress })}>
                                  + ADD SHIPPING ADDRESS
                                </span>
                              </td>
                            </>
                            :
                            <><td className={styles.leftTd}>SHIP TO:</td>
                              <td className={styles.rigthTd}>
                                <span
                                  className={styles.deliveryMethodTxt + ' partner-color1'}
                                  onClick={() => showNewAddressModal({ address: props.newShippingAddress })}>
                                  <i className='icon fas fa-pen'></i> EDIT
                                </span>
                              </td>
                            </>
                          }
                        </tr>
                        {props.newShippingAddress &&
                          <>
                            <tr className={styles.trRow + ' partner-color2'}>
                              <td className={styles.rigthTd}></td>
                              <td className={styles.rigthTd}>{props.newShippingAddress?.name}</td>
                            </tr>
                            <tr className={styles.trRow + ' partner-color2'}>
                              <td className={styles.rigthTd}></td>
                              <td className={styles.rigthTd}>{props.newShippingAddress?.address1}</td>
                            </tr>
                            <tr className={styles.trRow + ' partner-color2'}>
                              <td className={styles.rigthTd}></td>
                              <td className={styles.rigthTd}>
                                {props.newShippingAddress?.address2 ? props.newShippingAddress?.address2 + ", " : ""} {props.newShippingAddress?.state}, {props.newShippingAddress?.city}, {props.newShippingAddress?.postalCode}</td>
                            </tr>
                          </>
                        }
                      </>
                    }

                    {
                      ((props.partnerData.shipToCustomer && deliveryMethod === "storePickup") || !props.partnerData.shipToCustomer) &&
                      <>
                        <tr className={styles.trRow + ' partner-color2'}>
                          <td className={styles.leftTd}>SELECT STORE PICK-UP LOCATION</td>
                          <td className={styles.rigthTd}>
                            <div className={styles.ml2}>
                              <SelectInput
                                className={styles.field}
                                handler={(value: string) => {
                                  if (value) {
                                    selectStoreLocation({ storeLocation: JSON.parse(value) });
                                  }
                                }}
                                options={uniqueStoreLocations}
                                value={JSON.stringify(props.selectedStoreLocation)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className={styles.trRow + ' partner-color2'}>
                          <td className={styles.leftTd}>SELECTED STORE LOCATION</td>
                          <td className={styles.rigthTd}>{props.selectedStoreLocation?.name}</td>
                        </tr>
                        <tr className={styles.trRow + ' partner-color2'}>
                          <td className={styles.rigthTd}></td>
                          <td className={styles.rigthTd}>{props.selectedStoreLocation?.address1}</td>
                        </tr>
                        <tr className={styles.trRow + ' partner-color2'}>
                          <td className={styles.rigthTd}></td>
                          <td className={styles.rigthTd}>
                            {props.selectedStoreLocation?.address2 ? props.selectedStoreLocation?.address2 + ", " : ""} {props.selectedStoreLocation?.state}, {props.selectedStoreLocation?.city}, {props.selectedStoreLocation?.postalCode}</td>
                        </tr>
                      </>
                    }
                  </div>}
              </div>
              <div className={styles.summaryPrices}>
                <div className={styles.shippingHeader}>
                  <div className={styles.subtitle + ' partner-color1'}>Subtotal</div>
                </div>
                {products.map((product) => {
                  const grayGood = product.GrayGood;
                  const grayGoodName = grayGood ? grayGood.Name : '';

                  return <>
                    <tr className={styles.trRow + ' partner-color2'} key={product.ItemNumber ?? uniqueKey()}>
                      <td className={styles.leftTd}>{grayGoodName}</td>
                      <div><td className={styles.rigthTd + styles.quantity}>Qty: {product.TotalQuantity}</td>
                        <td className={styles.rigthTd}>${product.Price?.toFixed(2)}</td>
                        <td className={styles.rigthTd}>${product.TotalPrice?.toFixed(2)}</td>
                      </div>
                    </tr></>
                })}
                <tr className={styles.trRow + ' partner-color2'}>
                  <td className={[styles.leftTdm, styles.pt05].join(' ')}>PRODUCT SUBTOTAL</td>
                  <td className={[styles.rigthTd, styles.productSubtotal].join(' ')}><b>${productsSubTotal?.toFixed(2)}</b></td>
                </tr>
                <tr className={styles.trRow}>
                  <td style={{ margin: "0 0 1em" }}></td>
                </tr>
                {!!(props.priceSizeOffsetTotal && props.priceSizeOffsetTotal > 0) &&
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.leftTd}>OVERSIZED JERSEY FEE</td>
                    <td className={styles.rigthTd}>${props.priceSizeOffsetTotal}</td>
                  </tr>
                }
                {!!(props.minOrderFee && props.minOrderFee > 0) &&
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.leftTd}>UNDER 8 PCS PROCESSING FEE</td>
                    <td className={styles.rigthTd}>${props.minOrderFee}</td>
                  </tr>
                }
                <tr className={styles.trRow + ' partner-color2'}>
                  <td className={styles.leftTd}>ESTIMATED TAX</td>
                  <td className={styles.rigthTd}>{salesTax === 0 ? "*To be determined" : "$" + salesTax?.toFixed(2)}</td>
                </tr>
                <tr className={styles.trRow + ' partner-color2'}>
                  <td className={[styles.leftTd, isFreeShipping ? styles.lineThrough : ''].join(' ')}>SHIPPING</td>
                  <td className={[styles.rigthTd, isFreeShipping ? styles.lineThrough : ''].join(' ')}>{props.selectedShippingOption?.Code ? "$" + parseFloat(props.selectedShippingOption.Cost) : 'N/A'}</td>
                </tr>
                {
                  isFreeShipping && <tr className={[styles.trRow, styles.freeShipping].join(' ')}>
                    <td className={styles.leftTd}>FREE SHIPPING</td>
                    <td className={styles.rigthTd}>$0.00</td>
                  </tr>
                }
              </div>
            </tbody>
          </table>
        </div>
        <div className={styles.totalRow}>
          <span className={styles.totalCount}>Total number of items: {totalCount}</span>
          <span className={styles.totalLabel}>{salesTax === 0 ? "Subtotal:" : "Total"}</span>
          <span className={styles.totalValue}>${total?.toFixed(2)}</span>
        </div>
        <div className={styles.smallText + ' partner-color3'}>
          {salesTax === 0 && "*Subtotal does not include tax. Tax will be calculated at the time of final sale."}
          <br /> Once your order is placed, a representative
          <br /> will contact you shortly for order confirmation and payment details
        </div>
      </div>
      <div className={styles.saveButtonsRow}>
        <Button
          className={styles.saveButton + ' btn-primary partner-color1 partner-border-color1'}
          disabled={saveButtonDisabled}
          iconCls={saveButtonIconCls}
          label={saveButtonLabel}
          onClick={onSaveClick} />
        <Button
          className={styles.submitButton + ' btn-primary partner-background-color1 partner-border-color1'}
          disabled={submitButtonDisabled}
          iconCls={submitButtonIconCls}
          label={submitButtonLabel}
          onClick={onSubmitClick} />
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  const openedSavedOrder = state.App.openedSavedOrder;
  const partnerData = state.GUI.partnerData;
  const productsWithRoster = state.Order.products.filter((p) => { return p.RosterItems.length === 0 });

  const defaultStoreLocation = {
    "name": partnerData.defaultAddressName || partnerData.address1
    , "address1": partnerData.address1
    , "address2": partnerData.address2
    , "postalCode": partnerData.postalCode
    , "city": partnerData.city
    , "state": partnerData.state
    , "salesTaxType": partnerData.salesTaxType
  }

  return {
    activeItemNumber: state.Order.activeItemNumber,
    canSaveOrder: canSaveOrder(state),
    canSubmitOrder: canSubmitOrder(state),
    canUpdateOrder: canUpdateOrder(state),
    coachKey: state.Session.coachesKey,
    partnersKey: state.Session.partnersKey,
    partnerData: partnerData,
    defaultStoreLocation,
    selectedStoreLocation: state.Order.storeLocation,
    orderShippingOptions: state.Order.allShippingOptions,
    selectedShippingOption: state.Order.shippingMethod,
    savedOrderId: openedSavedOrder ? openedSavedOrder.id : undefined,
    savingOrderState: state.App.savingOrderState,
    submittingOrder: state.App.submittingOrder,
    submittedOrder: state.App.submittedOrder,
    initialOrder: state.GUI.initialOrder,
    initialOrderOpenAsCopy: state.GUI.initialOrderOpenAsCopy,
    originallyCopiedFrom: state.Order.originallyCopiedFrom,
    cadworxSessionId: state.Session.cadworxSessionId,
    coachContactInfo: state.GUI.coachContactInfoSelected,
    productsWithRoster,
    newShippingAddress: state.Order.shippingAddress,
    deliveryMethod: state.Order.deliveryMethod
  };
};

export default connect(mapStateToProps, null)(ReviewFooter);
