import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DecorationArea } from '../../domain/Design/DecorationArea';
import { getInDecorationAreaName, getDecorationAreaByType } from './canvas';

export function toggleDecoratinAreaHidden$ (decorationArea: DecorationArea, hidden?: boolean) {
  const designElement = decorationArea.DesignElement;
  const canvasId = designElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const decorationAreasFromType = getDecorationAreaByType(canvas.figures, decorationArea.Type)?.map( area => area.metadata.get("daId"))

  const figures = canvas.figures.filter((f) => {
    if (decorationArea.GroupId)
    {
      const decorationAreaName = getInDecorationAreaName(f);
      return ( decorationAreaName && decorationAreasFromType.indexOf(decorationAreaName) > -1 );
    }
    else
      return getInDecorationAreaName(f) === decorationArea.Id;
  });

  if (figures.length > 0) {
    // We can have duplicate decoration areas (Ex: Sleeve-Number)
    // so we need to loop thru any figures of areaName
    figures.forEach((f) => {
      if (hidden)
        f.hidden = hidden ;
      else
        f.hidden = !f.hidden;
    });

    return canvas.commit$();
  }

  return Cx.resolve();
}