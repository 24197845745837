import { app } from "../initApp";
import { UseCase } from "./usecase/UseCase";


export const editTextRadioCheckUseCase: UseCase = {
  type: 'SET_EDIT_TEXT_RADIO_CHECK',

  run: async ( ) => {
    return Cx.resolve();
  }
};

export default function setEditTextRadio() {
  app.runUseCase( editTextRadioCheckUseCase, {});
}
