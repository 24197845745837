import { Product } from '../domain/Product/Product';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { removeProductUseCase } from '../usecases/removeProduct';
import { editRosterUseCase } from '../usecases/editRoster';
import { emitCanvasEventUseCase } from '../usecases/emitCanvasEvent';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { addRosterItemUseCase } from '../usecases/roster/addRosterItem';
import { addMultipleRosterItemsUseCase } from '../usecases/roster/addMultipleRosterItems';
import { setActiveProductItemNumberUseCase } from '../usecases/setActiveProductItemNumber';
import { previewRosterItemUseCase } from '../usecases/roster/previewRosterItem';
import { removeRosterItemUseCase } from '../usecases/roster/removeRosterItem';
import { updateRosterItemUseCase } from '../usecases/roster/updateRosterItem';
import { showAddRosterStepUseCase } from '../usecases/showAddRosterStep';
import { showReviewStepUseCase } from '../usecases/showReviewStep';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { ProductReducer } from './ProductReducer';
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { selectShippingMethodUseCase } from '../usecases/selectShippingMethod';
import { selectStoreLocationUseCase } from '../usecases/selectStoreLocation';
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { validateRosterItemsUseCase } from '../usecases/roster/validateRosterItems';
import { setGrayGoodUseCase } from '../usecases/setGrayGood';
import { getSalesTaxByZipUseCase } from '../usecases/getSalesTaxByZip';
import { getPriceByOrderUseCase } from '../usecases/getPriceByOrder';
import { reportMessage } from '../../gui/util/rollbar';
import { prepareOrderForSavingUseCase } from '../usecases/prepareOrderForSaving';
import { updateAfterRemovingProductUseCase } from '../usecases/updateAfterRemovingProduct';
import { getUpsRatesUseCase } from '../usecases/getUpsRates';
import { setDeliveryLocationUseCase } from '../usecases/setDeliveryLocation';
import { confirmNewShippingAddressUseCase } from '../usecases/confirmNewShippingAddress';
import { setFreeShippingUseCase } from '../usecases/setFreeShipping';

export interface OrderReducerState {
  activeItemNumber: string;
  originallyCopiedFrom: string;
  products: Product[];
  shippingMethod: any;
  storeLocation: any;
  allShippingOptions: any;
  totalItems: number;
  totalPrice: number;
  totalCost: number;
  totalSalesTax: number;
  totalSizePriceOffset: number;
  minOrderFee: number;
  isLoadedOrder: boolean;
  stateSalesTax: number;
  priceModel: any;
  orderStateSnippet: any;
  shippingAndProductPrice: number;
  totalProductsPrice: number;
  deliveryMethod: string;
  shippingAddress: any;
  isFreeShipping: boolean;
  allPartnerGrayGoods: any;
}

const initState: OrderReducerState = {
  activeItemNumber: undefined,
  originallyCopiedFrom: undefined,
  shippingMethod: undefined,
  storeLocation: undefined,
  allShippingOptions: undefined,
  products: [],
  totalItems: 0,
  totalPrice: 0,
  totalCost: 0,
  totalSalesTax: 0,
  totalSizePriceOffset: 0,
  minOrderFee: 0,
  isLoadedOrder: false,
  stateSalesTax: 0,
  priceModel: undefined,
  orderStateSnippet: undefined,
  shippingAndProductPrice: 0,
  totalProductsPrice: 0,
  deliveryMethod: undefined,
  shippingAddress: undefined,
  isFreeShipping: false,
  allPartnerGrayGoods: []
};

export function OrderReducer(state: OrderReducerState = initState, actionResult: UseCaseResult): OrderReducerState {

  switch (actionResult.type) {

    case initPartnerWithColorsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const defaultStoreLocation = {
          "name": actionResult.data.partnerData.Partner[0].defaultAddressName || actionResult.data.partnerData.Partner[0].address1
          , "address1": actionResult.data.partnerData.Partner[0].address1
          , "address2": actionResult.data.partnerData.Partner[0].address2
          , "postalCode": actionResult.data.partnerData.Partner[0].postalCode
          , "city": actionResult.data.partnerData.Partner[0].city
          , "state": actionResult.data.partnerData.Partner[0].state
          , "salesTaxType": actionResult.data.partnerData.Partner[0].salesTaxType
        }
        return Object.assign({}, state, {
          storeLocation: defaultStoreLocation,
          orderStateSnippet: { ...state.orderStateSnippet, storeLocation: defaultStoreLocation },
          allPartnerGrayGoods: actionResult.data.partnerData.PartnerGrayGood
        })
      }

      return state;

    case prepareOrderForSavingUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const orderStateSnippet = actionResult.data.orderStateSnippet;
        const products = actionResult.data.orderState;
        let newState = Object.assign(state, {
          orderStateSnippet,
          products
        });

        return newState;
      }
      return state;

    case loadSubmittedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const savedOrder = actionResult.data.savedOrder;
        const products = savedOrder.orderState;
        const updatedProducts = validateProducts(products, state);

        let newState = Object.assign({}, initState, {
          totalItems: getTotalProducts(updatedProducts),
          activeItemNumber: actionResult.data.product.ItemNumber,
          originallyCopiedFrom: actionResult.data.originallyCopiedFrom,
          allShippingOptions: state.allShippingOptions,
          shippingMethod: actionResult.data.savedOrder.orderSnippetData?.shippingMethod,
          shippingAddress: actionResult.data.savedOrder.orderSnippetData?.shippingAddress,
          storeLocation: state.storeLocation,
          isLoadedOrder: true,
          products: updatedProducts,
          allPartnerGrayGoods: state.allPartnerGrayGoods
        });

        updateShippingMethod(newState);

        return newState;
      }

      return state;

    case openSavedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const savedOrder = actionResult.data.savedOrder;
        const currentProducts = state.products;
        const savedProducts = savedOrder.orderState;
        const allProducts = [...currentProducts, ...savedProducts];

        const updatedProducts = validateProducts(allProducts, state);

        let newState = Object.assign({}, initState, {
          totalItems: getTotalProducts(updatedProducts),
          activeItemNumber: actionResult.data.product.ItemNumber,
          originallyCopiedFrom: actionResult.data.originallyCopiedFrom,
          allShippingOptions: state.allShippingOptions,
          shippingMethod: actionResult.data.savedOrder.orderStateSnippet.shippingMethod,
          deliveryMethod: actionResult.data.savedOrder.orderStateSnippet.deliveryMethod,
          shippingAddress: actionResult.data.savedOrder.orderStateSnippet.shippingAddress,
          storeLocation: state.storeLocation,
          isLoadedOrder: true,
          products: updatedProducts,
          allPartnerGrayGoods: state.allPartnerGrayGoods
        });

        updateShippingMethod(newState);

        return newState;
      }

      return state;

    case selectShippingMethodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        let newState = Object.assign({}, state, {
          shippingMethod: actionResult.data
        });
        updateShippingMethod(newState);
        return newState;
      }
      return state;

    case selectStoreLocationUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        let newState = Object.assign({}, state, {
          storeLocation: actionResult.data,
          orderStateSnippet: { ...state.orderStateSnippet, storeLocation: actionResult.data }
        });
        return newState;
      }
      return state;

    case setActiveProductItemNumberUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeItemNumber: actionResult.data
        });
      }

      return state;

    case addProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const product = actionResult.data.product;
        const newProduct = ProductReducer(product, actionResult);
        newProduct.ItemNumber = state.activeItemNumber;

        //copy roster from any product of the same id
        // const existingProduct = state.products.filter((p) => { return p.Id = product.Id });
        // if (existingProduct && existingProduct.length > 0 )
        //   newProduct.RosterItems = existingProduct[0].RosterItems ;

        const products = [
          ...state.products,
          newProduct
        ]

        const newState = Object.assign({}, state, {
          activeItemNumber: state.activeItemNumber,
          products,
          totalItems: getTotalProducts(products),
        });

        return newState;
      }

      return state;


    case removeProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        let newActiveProductItemNumber = undefined;

        const newProducts = state.products.filter(function (p) {
          return p.ItemNumber !== actionResult.data.ItemNumber
        });

        if (state.activeItemNumber == actionResult.data.ItemNumber) {
          if (newProducts.length > 0)
            newActiveProductItemNumber = newProducts[0].ItemNumber;
        } else {
          if (newProducts.length > 0)
            newActiveProductItemNumber = state.activeItemNumber;
        }

        const newState = Object.assign({}, state, {
          activeItemNumber: newActiveProductItemNumber,
          products: newProducts,
          totalItems: getTotalProducts(newProducts),
        });

        return newState;
      }

      return state;

    case editProductUseCase.type:
    case openSavedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        let newState = Object.assign({}, state, {
          products: products,
          totalItems: getTotalProducts(products),
        });

        return newState;
      }

      return state;

    case getUpsRatesUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        let newState = Object.assign({}, state, {
          allShippingOptions: actionResult.data
        });

        updateShippingMethod(newState);

        return newState;
      }

      return state;

    case editRosterUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          activeProductItemNumber: actionResult.data.product.ItemNumber,
          products: products,
          totalItems: getTotalProducts(products),
        });
      }

      return state;

    case addMultipleRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products,
          totalItems: getTotalProducts(products),
        });

        return newState;
      }

      return state;

    case addRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products,
          totalItems: getTotalProducts(products),
        });

        return newState;
      }

      return state;

    case updateRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products,
          totalItems: getTotalProducts(products),
        });

        return newState;
      }

      return state;

    case removeRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        return newState;
      }

      return state;

    case emitCanvasEventUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          products: state.products.map((p) => {
            if (p.ItemNumber === state.activeItemNumber) {
              return ProductReducer(p, actionResult);
            }

            return p;
          })
        });
      }

      return state;

    case previewRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      if (actionResult.start() || actionResult.failure() || actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          products: products
        });
      }

      return state;

    case showAddRosterStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          products: products
        });
      }

      return state;

    case showReviewStepUseCase.type:
    case updateAfterRemovingProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult)); //.filter((p) => { return p.RosterItems.length > 0 }); //we now allow empty rosters on product review

        const ix = products.findIndex(p => p.ItemNumber == state.activeItemNumber);

        if (actionResult.data.SavedDocId)
          products[ix].SavedDocId = actionResult.data.SavedDocId;

        const updatedProducts = validateProducts(products, state);

        const newState = Object.assign({}, state, {
          products: updatedProducts,
          priceModel: actionResult.data.priceModel.priceModel,
        });

        addTotalsToState(newState, actionResult.data.priceModel.priceModel);
        return newState;
      }

      return state;

    case validateRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        return newState;
      }

      return state;

    case setGrayGoodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const newState = Object.assign({}, state, {
          isLoadedOrder: false
        });

        return newState;
      }

      return state;

    case getSalesTaxByZipUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const salesTax = actionResult.data.priceModel.priceModel.tax;
        const newState = Object.assign({}, state, {
          stateSalesTax: salesTax ?? 0,
          priceModel: actionResult.data.priceModel.priceModel
        });

        addTotalsToState(newState, newState.priceModel);
        return newState;
      }
      return state;

    case getPriceByOrderUseCase.type:

      if (actionResult.start()) {
        resetStateTotals(state);
      }

      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        //resetStateTotals(state);
        const currentState = state;
        addTotalsToState(currentState, actionResult.data.priceModel.priceModel);
        return currentState;
      }

      return state;

    case setDeliveryLocationUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const newState = Object.assign({}, state, {
          deliveryMethod: actionResult.data.deliveryMethod
        });

        return newState;
      }

      return state;

    case confirmNewShippingAddressUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          shippingAddress: actionResult.data.address
        });
      }
      return state;

    case setFreeShippingUseCase.type:
      if (actionResult.success()) {
        let newState = Object.assign({}, state, {
          isFreeShipping: actionResult.data.isFreeShipping
        });
        updateShippingMethod(newState);

        return newState;
      }

      return state;
  }

  return state;
}

function resetStateTotals(state: any) {
  //this is now comming from the pricing API
  state.totalSizePriceOffset = 0;
  state.minOrderFee = 0;
  state.totalSalesTax = 0;
  state.totalPrice = 0;
  state.products.forEach(p => {
    p.Price = 0;
    p.TotalPrice = 0;
  })
}

function addTotalsToState(state: any, totals) {
  //this is now comming from the pricing API
  state.totalCount = totals.count;
  state.totalSizePriceOffset = totals.offset;
  state.minOrderFee = totals.minFee;
  state.totalSalesTax = totals.tax;
  state.totalPrice = totals.price;

  if (totals.validProductPrices) {
    checkForValidProductPrices(state, totals);
  }

  //updateOrderShippingOptions(state); //changing totals or price, update shipping options
  updateShippingMethod(state);
}

function checkForValidProductPrices(state, totals) {
  let productsTotal = 0;
  state.products.forEach(p => {
    const validProductPrice = totals.validProductPrices.find(v => v.id === p.GrayGood.Id);
    if (validProductPrice) {
      p.Price = validProductPrice.price * 1;
      p.GrayGood.Price = validProductPrice.price * 1;
      p.TotalPrice = validProductPrice.price * p.TotalQuantity * 1;
      productsTotal += p.TotalPrice;
    }
  });

  state.totalProductsPrice = productsTotal;
}

function getTotalProducts(products) {
  let totalProducts = 0;
  products.forEach(p => {
    totalProducts += p.TotalQuantity;
  });
  return totalProducts;
}

function updateShippingMethod(state: any) {
  const selectedShippingPrice = state.shippingMethod ? (!state.isFreeShipping ? state.shippingMethod.Cost : 0) : 0;
  state.totalPrice = selectedShippingPrice !== 0 ? 0 : state.totalPrice;
  const totalWithShipping = (state.totalSizePriceOffset + state.minOrderFee + state.totalSalesTax + selectedShippingPrice + state.totalProductsPrice);
  state.totalPrice = totalWithShipping;
}

function fixOffsetSize(size) {
  if (size.indexOf('-') > -1)
    return size.split('-')[0];
  else
    return size;
}

function validateProducts(allProducts, state) {
  const allGrayGoods = state.allPartnerGrayGoods;

  const updatedProducts = allProducts?.map((product: Product) => {
    let matchingGrayGood: any = null;

    for (const grayGood of allGrayGoods) {
      //@ts-ignore
      if (grayGood.grayGood.id === product.GrayGood.Raw.grayGood.id) {
        matchingGrayGood = grayGood;
        break;
      }
    }

    if (matchingGrayGood) {
      let offset = 0;

      // Update GrayGood
      product.GrayGood.PriceSizeOffsets = matchingGrayGood.priceSizeOffsets;

      // Update GrayGood fabric
      product.GrayGood.Fabric = matchingGrayGood?.fabric;

      // Update GrayGood Raw
      product.GrayGood.Raw.priceSizeOffsets = matchingGrayGood.priceSizeOffsets;

      // Update RosterItems SizePriceOffset and TotalSizePriceOffset
      product.RosterItems.forEach((roster: any) => {
        const validPriceOffset = matchingGrayGood.priceSizeOffsets?.find(offset => fixOffsetSize(offset.size) == roster.Size);
        const productOffset = parseFloat(roster.SizePriceOffset);

        if (validPriceOffset) {
          const validOffset = parseFloat(validPriceOffset.priceOffset);
          offset += parseFloat(roster.Quantity) * validOffset;

          if (validOffset !== productOffset)
            roster.SizePriceOffset = validOffset;
        }
      });

      product.TotalSizePriceOffset = offset;
    }

    return product;
  });

  return updatedProducts;
}