import { ColorZoneFillTarget, fillTargetIsColorZone } from '../domain/Design/ColorZoneFillTarget';
import { hasActiveFillTarget, isDesignReady } from '../domain/Design/Conditions';
import { FillTarget } from '../domain/Design/FillTarget';
import { fillTargetIsOutline, OutlineFillTarget } from '../domain/Design/OutlineFillTarget';
import { fillTargetIsText, TextFillTarget } from '../domain/Design/TextFillTarget';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { replaceZoneBrushByZoneId$ } from './actions/canvas';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { BrushFactoryOptions, createBrush$ } from '../../gui/util/brushFactory'
import { UseCase } from './usecase/UseCase';

export interface SetFillUseCaseOptions {
  fillTarget: FillTarget;
  brushOptions: BrushFactoryOptions;
  zones?: any;
  docId?: string;
  brush?: Cx.Brush;
}

export const setFillUseCase: UseCase = {
  type: 'SET_FILL',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, hasActiveFillTarget(state.GUI.activeCustomizeTab)]);
  },

  run: async ( options: SetFillUseCaseOptions ) => {
    
    const fillTarget = options.fillTarget;
    const targetBrush = await createBrush$(options.brushOptions);

    Object.assign(options, targetBrush );

    if (fillTargetIsColorZone(fillTarget)) {
      const colorZoneFillTarget = fillTarget as ColorZoneFillTarget;
      const canvasId = colorZoneFillTarget.canvasId;
      const colorZoneId = colorZoneFillTarget.colorZone.Name;

      return replaceZoneBrushByZoneId$(canvasId, colorZoneId, options.brush)
      .then(function (r) {
        return Cx.resolve(options);
      });   
    }

    else if (fillTargetIsOutline(fillTarget)) {
      const outlineFillTarget = fillTarget as OutlineFillTarget;
      const outlineIndex = outlineFillTarget.outlineIndex;
      
      let textElement = outlineFillTarget.textDesignElement ;
      textElement.outlines[outlineIndex].Brush = options.brush;
      return updateTextDesignElement$(outlineFillTarget.textDesignElement)
      .then(()=>{
        return Cx.resolve(options);
      });  
    }

    else if (fillTargetIsText(fillTarget)) {
      const textFillTarget = fillTarget as TextFillTarget;

      let textElement = textFillTarget.textDesignElement ;
      textElement.brush = options.brush ;
      return updateTextDesignElement$(textFillTarget.textDesignElement)
      .then(()=>{
        return Cx.resolve(options);
      });   
    }

    return Cx.resolve(options);
  }
};

/**
 * @category Can
 */
export function canSetFill( state ) {
  return setFillUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setFill( options: SetFillUseCaseOptions ) {
  app.runUseCase( setFillUseCase, options );
}
