import { app } from "../initApp";
import { UseCase } from "./usecase/UseCase";


export const editLogoRadioCheckUseCase: UseCase = {
  type: 'SET_EDIT_LOGO_RADIO_CHECK',

  run: async ( ) => {
    return Cx.resolve();
  }
};

export default function setEditLogoRadio() {
  app.runUseCase( editLogoRadioCheckUseCase, {});
}
