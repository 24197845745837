import {GetSpotColorDefaultIndexesZoneMap, GetSpotColorBasesWithMap} from "./colorZoneHelper"

export interface Pattern {
  docId: string;
  colorZones: any;
  activeColors: any;
  activeColorSetIndex: number;
  colorIndexZoneMap: object;
}

export function emptyPatternBrush () {
  return {
    // ActiveColors: [],
    // ActiveColorSetIndex: -1,
    ColorIndexZoneMap: [],
    ColorZones: [],
    Config: {},
    DocId: '',
    Id: '',
    Name: ''
  };
}

export function patternBrushFromServerResponse (docModel, Config?) {
  Config = Config || {};

  return Object.assign(emptyPatternBrush(), {
    Config,
    Id: docModel.Id,
    Name: docModel.Name
  });
}

export interface PatternConfig {
  seletedPatternId,
  metadata: [], 
  colors: [],
  defaultColorZones: [],
  colorMap: any
}

export function emptyPatternConfig() : PatternConfig { return { seletedPatternId: undefined, metadata: [], colors: [], defaultColorZones: [], colorMap: undefined }  }

export function activePatternFromBrush(brush, patterns)
{
  const colors = brush.colorMap.getColors();
  const map = GetSpotColorDefaultIndexesZoneMap(colors);
  const config = patterns?.find((p)=>{ return p.Config.metadata.patternLink === brush._link });

  return {
    docId: config?.Id ? config.Id : brush._link,
    colorZones: Cx.uniqueColorZones(brush),
    activeColors: GetSpotColorBasesWithMap(brush.colorMap.getColors(), map),
    activeColorSetIndex: -1,
    colorIndexZoneMap: map
  }
}

export function emptyPattern() : Pattern { return { docId: '', colorZones: [], activeColors: [], activeColorSetIndex: -1, colorIndexZoneMap: [] } }

export function isPatternBrush(brush:Cx.Brush)
{
  return isRasterPatternBrush(brush) || isVectorPatternBrush(brush);
}

export function isRasterPatternBrush(brush:Cx.Brush)
{
  return Object(brush).hasOwnProperty('_imageLink');
}

export function isVectorPatternBrush(brush:Cx.Brush)
{
  return Object(brush).hasOwnProperty('_link');
}


