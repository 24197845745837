import { getOrdersByCoachesKeyUrl } from '../../../backendApi/backendApi';
import { getToken } from '../../../gui/services/backend';

export async function getOrdersByCoachesKey$ (key: string, partnersKey: string) {
  const url = getOrdersByCoachesKeyUrl(key);
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const resp = await fetch(url, {
    mode: "cors",
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Content-Type': 'application/json'
    }
  });

  if(resp.ok) {
    const jsonResp = await resp.json();
    return Promise.resolve(jsonResp);
  }

  return Promise.reject('Problem with getOrdersByCoachesKey');
}
