import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface SetDeliveryLocationUseCaseOptions {
  deliveryMethod: string;
}

export const setDeliveryLocationUseCase: UseCase = {
  type: 'SET_DELIVERY_LOCATION',

  run: ( options: SetDeliveryLocationUseCaseOptions ) => {
    return Cx.resolve({
      deliveryMethod: options.deliveryMethod,
    });
  }
};

/**
 * @category Use Case
 */
export function deliveryLocation( options: SetDeliveryLocationUseCaseOptions ) {
  app.runUseCase( setDeliveryLocationUseCase, options );
}