import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { TextDesignElement } from '../../../../app/domain/Design/TextDesignElement';
import { textFillTarget } from '../../../../app/domain/Design/TextFillTarget';
import { setFillTarget, toggleDecorationAreaHidden } from '../../../../app/initApp';
import { setActiveDAPanelId } from '../../../../app/usecases/setIndexDecorationAreaPanel';

// Styles
import styles from './DecorationAreaPanel.module.css';

/**
 * Usage:
 *
 * <DecorationAreaPanel 
 *  decorationArea={decorationArea} 
 *  title={title} 
 *  activeDAIndex={number|string} 
 *  activeId={number|string}
 *  activeFillTarget={activeFillTarget}>
 *   ...
 * </DecorationAreaPanel>
 */

interface Props {
  children: any;
  activeDAIndex?: any;
  decorationArea: DecorationArea;
  activeId?: any;
  activeFillTarget?: any;
}

const DecorationAreaPanel = (props: Props) => {
  const [collapsed, setCollapsed] = useState(null);
  const decorationArea = props.decorationArea;
  const hidden = decorationArea.DesignElement.hidden;
  const title = decorationArea.GroupLabel || decorationArea.Label;

  const handleClick = () => {
    setActiveDAPanelId({ activeId: props.activeId });

    const textDesignElement = decorationArea.DesignElement as TextDesignElement;
    setFillTarget({
      fillTarget: textFillTarget(textDesignElement)
    });

    if (props.activeDAIndex === props.activeId) {
      setCollapsed(!collapsed ? props.activeId : null);
    }
  }

  useEffect(() => {
    if (props.activeDAIndex === props.activeId) {
      setCollapsed(null);
    } else {
      setCollapsed(props.activeId);
    }
  }, [props.activeDAIndex]);

  useEffect(() => {
    if (props.activeFillTarget && props.activeFillTarget?.textDesignElement) {
      const decorationAreaId = props.activeFillTarget.textDesignElement.decorationAreaId;
      setActiveDAPanelId({ activeId: decorationAreaId });
    }
  }, [props.activeFillTarget]);

  return (
    <div className="decoration-area-panel">
      <div className={styles.title + ' partner-color2'}>
        <div
          className={styles.titleCheckmark + (hidden ? ' far fa-square' : ' fas fa-check-square')}
          onClick={() => onToggleDisabled(decorationArea)}></div>
        <div
          className={styles.titleText}
          onClick={handleClick}>{title}</div>
        <div
          className={styles.titleCollapse + (collapsed === props.activeId ? ' fas fa-caret-left' : ' fas fa-caret-down')}
          onClick={handleClick}></div>
      </div>
      <div className={'body ' + (collapsed === props.activeId ? styles.bodyCollapsed : '')}>
        {props.children}
      </div>
    </div>
  );

}

function onToggleDisabled(decorationArea: DecorationArea) {
  toggleDecorationAreaHidden({
    decorationArea
  });
}

const mapStateToProps = (state: RootState) => {
  return {
    activeDAIndex: state.GUI.activeDecorationAreaPanelId
  };
};

export default connect(mapStateToProps)(DecorationAreaPanel);
