import { getCoachSessionData$ } from "../../app/usecases/actions/getCoachSessionData$";

export const poll = (finishedCb, timeout, interval, partnersKey) => {
  var startTime = (new Date()).getTime();
  interval = interval || 1000;
  let canPoll = true;
  (async function p() {
      canPoll = ((new Date).getTime() - startTime ) <= timeout;
      const sessionToken = Cx.Storage.get('ubdesigner2.sessionToken');
      const sessionCoach = Cx.Storage.get('ubdesigner2.sessionCoach');
      
      let hasSession ;
      
      if (sessionToken && sessionCoach) 
        hasSession = await getCoachSessionData$(sessionToken, partnersKey, sessionCoach)

      if (!hasSession && canPoll)  { // ensures the function exucutes
          setTimeout(p, interval);
      }
      else {
        startTime = 0 ;
        finishedCb(hasSession);
      }
  })();
}
