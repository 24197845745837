import React, { useEffect } from 'react';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { CustomizeTab } from '../../../../app/domain/Gui/CustomizeTab';
import { setActiveDAPanelId } from '../../../../app/usecases/setIndexDecorationAreaPanel';
import DecorationAreaPanel from '../../steps/customize/DecorationAreaPanel';
import NameEditorFields from './NameEditorFields';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  decorationArea: DecorationArea;
  nameFonts: any;
  allFonts: any;
  hiddenFontsIds: any;
  activeCustomizeTab: string;
}

const NameEditor = (props: Props) => {
  const decorationArea = props.decorationArea;

  useEffect(() => {
    if (props.activeCustomizeTab === CustomizeTab.NAME_NUMBER) {
      setActiveDAPanelId({ activeId: decorationArea.Id });
    }
  }, [props.activeCustomizeTab]);

  return <div className="name-editor">
    <DecorationAreaPanel
      decorationArea={decorationArea}
      activeId={decorationArea.Id}
      activeFillTarget={props.activeFillTarget}>
      <NameEditorFields
        activeFillTarget={props.activeFillTarget}
        canvasId={props.canvasId}
        decorationArea={decorationArea}
        allFonts={props.allFonts}
        nameFonts={props.nameFonts}
        hiddenFontsIds={props.hiddenFontsIds} />
    </DecorationAreaPanel>
  </div>;

}

export default NameEditor;