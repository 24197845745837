import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DecorationArea } from '../../domain/Design/DecorationArea';
import { DesignElement } from '../../domain/Design/DesignElement';
import { Outline } from '../../domain/Design/Outline';
import { TextDesignElement, textDesignElementToFigure } from '../../domain/Design/TextDesignElement';
import { clearDecorationArea, enumDecorationAreas } from './canvas';
import { setFigureInDecorationArea$ } from './setFigureInDecorationArea$';

function getDecorationAreasToUpdate (canvas: Cx.Canvas, designElement: DesignElement): DecorationArea[] {
  const allDecorationAreas = enumDecorationAreas(canvas);
  const areaId = designElement.decorationAreaId;
  const area = allDecorationAreas.find((item) => {
    return item.Id === areaId;
  });

  // Get all decoration areas to update ( e.g 'Sleeve-Number )
  const decorationAreas = allDecorationAreas.filter((a: DecorationArea) => {
    // Updating a group? Or by Id
    return area.GroupId ? a.GroupId === area.GroupId : a.Id === area.Id;
  });

  return decorationAreas;
}

export function updateTextDesignElement$ (textDesignElement: TextDesignElement) {
  const canvasId = textDesignElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const decorationAreas = getDecorationAreasToUpdate(canvas, textDesignElement);
  const fn$ = [];

  decorationAreas.forEach((area: DecorationArea) => {
    const textFigure = textDesignElementToFigure(textDesignElement);
    const effects = [];
    const effectsOptions = [];

    if (textDesignElement.fontIsScript)
      textFigure.extraCharSpacing = null ;

    clearDecorationArea(canvas, area.Id);

    // processing will be done in the same order
    if (textDesignElement.effect) {
      effects.push(textDesignElement.effect);
      effectsOptions.push({});
    }

    if (textDesignElement.outlines.length > 0)
    {
      effects.push('PenContour');
      const contourOptions = [];
      textDesignElement.outlines.forEach((outline: Outline) => {
        contourOptions.push({ offset: outline.Offset, brush: outline.Brush, lineJoin: textDesignElement.outlineJoin });
      });
      effectsOptions.push(contourOptions);
    }

    //@ts-ignore
    fn$.push(setFigureInDecorationArea$( textDesignElement.canvasId
          , textFigure
          , area.Figure
          , effects
          , effectsOptions
          , false )
      );
  });

  return Cx.whenAll(fn$).then(() => {
    return Cx.resolve();
  });
}