export function toHex2(x) {
  return ("0" + parseInt(x).toString(16)).slice(-2);
};

export function rgbArrayToHex(cm){
  return toHex2(cm[0]) + toHex2(cm[1]) + toHex2(cm[2]);
};

export function toARGB(color:Cx.Color){
  var alpha = color.alpha,
  hex = color.rgb,
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    alpha: alpha,
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function mergeEqualZones(zones)
{
  let r = {};
  zones.forEach( z => {
    let existingIndex = -1 ;
    Object.keys(r).forEach( (k, i) =>{
      const rZones = r[k];
      const currentIndex = rZones.findIndex( v => v.value.spotColorName == z.value.spotColorName);
      if (currentIndex > -1)
        existingIndex = currentIndex ;
    });
    if (existingIndex > -1)
      r[existingIndex].push(z);
    else
    {
      const key = Object.keys(r).length ;
      r[key] = [z];
    }
  })
  return r;
}

function expandMap(map)
{
  Object.keys(map).forEach(key =>{
    const obj = map[key];
    const len = obj.length ;
    for (var i = 1 ; i < len; i++)
    {
      const index = Object.keys(map).length ;
      map[index] = [obj[i]];
    }
  });
  return map ;
}

//review for tint
export function GetDefaultColorZones(zones, colors, map)
{
  const clonedZones = Cx.cloneDeep(zones);
  const expandedMap = expandMap(map);
  clonedZones.forEach((z, i)=>{
    z.value.rgb = colors.Color[i].RGB
    z.value.spotColorName = colors.Color[i].ColorName
    z.value.tag = expandedMap[i][0]
  });
  return clonedZones;
}

export function GetSpotColorBasesWithMap(colors, map)
{
  let baseColors = [];
  let singleNames = [];

  Object.keys(map).forEach((m)=> {
    const ixTag = map[m][0] ;
    const singleName = colors.find((c)=> { return c.tag == ixTag } ).spotColorName ;
    singleNames.push(singleName);
  });
  singleNames.forEach(function(s) {
    const c = colors.find( (fz) => { return ( (fz.spotColorName == s) && (fz.tint == 1) )}); //tint = 1: opaque, color base
    baseColors.push(c);
  });
  return baseColors ;
};

export function GetServiceColors(colors, zones)
{
  return colors?.map( (c,i) => { return { ColorName: c.spotColorName, RGB: c.rgb, Tag: zones[i].value.tag } } )
}

export function GetSpotColorBases(colors)
{
  let baseColors = [];
  let z = colors.map(z => z.spotColorName);
  let singleNames = z.filter(function(item, pos) {
    return z.indexOf(item) == pos;  
  }); 
  singleNames.forEach(function(s) {
    const c = colors.find( (fz) => { return ( (fz.spotColorName == s) && (fz.tint == 1) )}); //tint = 1: opaque, color base
    baseColors.push(c);
  });
  return baseColors ;
};

export function interpolateColor(target:Cx.Color, tint: number): Cx.Color //target should have tint = 1
{
  let interpolated = Cx.Color.fromRgb( rgbArrayToHex( Cx.interpolate_rgb( Cx.Color.White().rgbArray, target.rgbArray, tint ) ) ) ;
  interpolated.spotColorName = target.spotColorName ;
  return interpolated ;
}

export function GetSpotColorTuplesTargets(colorSet, colors)
{
  let colorTuples = [];
  const baseZones = GetSpotColorBases(colors);
  baseZones.forEach((z,i) => {
    let targetColor = Cx.Color.fromRgb(colorSet[i].RGB);
    targetColor.spotColorName = colorSet[i].ColorName ;
    colorTuples.push({
      Item1: targetColor,
      Item2: z
    });
  });
  return colorTuples;
}

export function GetSpotColorBasesFromZoneMap(zoneMap, colors)
{
  let bases = []
  Object.keys(zoneMap).forEach((zk) => {
    const z = zoneMap[zk];
    bases.push(colors.filter((c) => {return c.tag == z[0]; } )[0]);
  });
  return bases ;
}

export function GetSpotColorTuples(colors, initialColors, zones)
{
  let colorTuples = [];

  const z = GetSpotColorBases(zones);

  zones.forEach( (c, i) => {
    let targetColorName = colors[z.findIndex(i => i.spotColorName === c.spotColorName)].ColorName;
    let targetColorRgb  = colors[z.findIndex(i => i.spotColorName === c.spotColorName)].RGB;
    if(c.tint < 1 ) //interpolate
    {
      const originalRGB = Cx.Color.fromRgb(targetColorRgb);
      const interpolated = interpolateColor(originalRGB, c.tint);
      targetColorRgb = interpolated.rgb ;
    }
    colorTuples.push({
      Item1: {
        Item1: initialColors[i].ColorName,
        Item2: initialColors[i].RGB
      },
      Item2: {
        Item1: targetColorName,
        Item2: targetColorRgb
      }
    });
  })
  return colorTuples;
}

export function GetSpotColorReplacementMap(bases, existing, map)
{
  Object.keys(map).forEach((k,i) =>{
    const colorTargetsMap = map[k];
    const replaceBase = bases[i];
    colorTargetsMap.forEach((target) => {
      const targetColorIndex = existing.findIndex( c => c.tag === target );
      if (targetColorIndex > -1)
      {
        const existingColor = existing[targetColorIndex];
        let target = Cx.Color.fromRgb(replaceBase.rgb);
        if (existingColor.tint < 1) //interpolate
          target = interpolateColor(target, existingColor.tint)
        target.tag = existingColor.tag ;
        target.spotColorName = replaceBase.spotColorName ;
        target.tint = existingColor.tint;
        existing[targetColorIndex] = target ;
      }
    });
  })
  return new Cx.TaggedColors({ colors: existing });
}

export function GetSpotColorDefaultIndexesZoneMap(existing)
{
  let indexZoneMap = new Object();
  const existingColorNames = GetSpotColorBases(existing);
  existing.forEach( (c, i) => {
    const targetIndex = existingColorNames.findIndex(s => s.spotColorName === c.spotColorName);
    if (! indexZoneMap[targetIndex] )
      indexZoneMap[targetIndex] = [];
    indexZoneMap[targetIndex].push(c.tag)
  });
  return indexZoneMap;
}