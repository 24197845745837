import {verify} from 'crypto';
import { succeedAccessStart } from '../../../gui/services/storage';
import { app } from '../../initApp';
import { getCoachSessionData$ } from '../actions/getCoachSessionData$';
import { verifyPhoneCode$ } from '../actions/login'
import { UseCase } from '../usecase/UseCase';

export interface VerifyPhoneCodeUseCaseOptions {
  code: string;
  phone: string;
  partner: string;
}

export const verifyPhoneCodeUseCase: UseCase = {
  type: 'VERIFY_PHONE_CODE',

  run: async ( options: VerifyPhoneCodeUseCaseOptions ) => {
    // TODO: Enter wrong code to test 400 results
    const verifyPhoneResult = await verifyPhoneCode$(options.code, options.phone);

    if(verifyPhoneResult.verified) {

      const coachSessionData = await getCoachSessionData$(verifyPhoneResult.sessionToken, options.partner);

      if ( Cx.Storage.isAvailable() ) {
        Cx.Storage.set('ubdesigner2.sessionToken', verifyPhoneResult.sessionToken);
        Cx.Storage.set('ubdesigner2.sessionCoach', coachSessionData.coach.coachesKey);
      }

      return coachSessionData;
    }
    else {
      return Promise.reject({
        errorMessage: verifyPhoneResult.errorMessage
      });
    }
  }
};

export function verifyPhoneCode( options: VerifyPhoneCodeUseCaseOptions ) {
  app.runUseCase( verifyPhoneCodeUseCase, options );
}
