import { UPLOAD_IMAGE_URL } from '../../../backendApi/backendApi';
import { getToken } from '../../../gui/services/backend';

export function uploadLogo$ (file: File, coachesKey: string, partnersKey: string) {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const formData = new FormData();

  formData.append('coachesKey', coachesKey);
  formData.append('partnersKey', partnersKey);
  formData.append('file', file);

  return fetch(UPLOAD_IMAGE_URL, {
    body: formData,
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${apiToken}`
    }
  })
  .then(response => response.json())
  .then(data => { return Cx.resolve(data) });
}
