import React from 'react';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';

// Styles
import styles from './ColorChiclet.module.css'

interface Props {
  color: Cx.Color,
  index: number;
  selected: boolean;
  title: string,
  onColorClick?: Function,
  className?: string // Let the parent component control style if necc.
  isSmallThumb?: boolean;
  activeStep?: string;
}

const ColorChiclet = (props: Props) => {
  const selected = props.selected;
  const checkmarkClassName =
    selected ? (props.isSmallThumb ? [styles.checkmark, styles.reducedCheckmark].join(' ') : styles.checkmark) : styles.checkmarkHidden;
  let className = props.className + ' ' + styles.tooltip + ((selected && props.activeStep !== GuiSteps.REVIEW) ? ' ' + styles.selected : '');
  const hex = `#${props.color.argb.replace(/(..)(......)/, '$2$1')}`;

  if (selected && hex === '#FFFFFFFF') {
    className += ' ' + styles.selectedIsWhite;
  }

  return (
    <div
      style={{ background: hex, outlineColor: hex }}
      className={className}
      onClick={() => props.onColorClick(props.color, props.index)}
      title={props.title}>
      <span className={styles.tooltiptext}>{props.title}</span>
      {props.activeStep !== GuiSteps.REVIEW && <span className={checkmarkClassName + ' fas fa-check'}></span>}
    </div>
  );
};

export default ColorChiclet;
