import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { designStyleFromActionResult } from '../domain/Design/DesignStyle';
import { grayGoodFromActionResult } from '../domain/Design/GrayGood';
import { sportFromActionResult } from '../domain/Design/Sport';
import { initJerseyThumbColorMapUseCase } from '../usecases/initJerseyThumbColorMap'
import SparkMD5 from 'spark-md5';
import global from '../../gui/services/global'
import { putNextOnQueueBatchThumbUseCase } from '../usecases/putNextOnQueueBatchThumbUseCase';
import { reportMessage } from '../../gui/util/rollbar';

export interface Thumb3DReducerState {
  designStylesTree: any;
  jerseyColors: any;
  notProcessed: string[],
  currentInstances: string[];  
  remaining: number;
  jerseyThumbColorMap: object;
}

const initialState: Thumb3DReducerState = { 
  designStylesTree: null,
  jerseyColors: [],
  notProcessed: [],
  currentInstances: [],  
  remaining: 0,
  jerseyThumbColorMap: {}
};

export function Thumb3DReducer(state: Thumb3DReducerState = initialState, actionResult): Thumb3DReducerState {

  switch (actionResult.type) {
    
    case initJerseyThumbColorMapUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        setCheckedForProccessed(state.designStylesTree, actionResult.data.jerseyThumbColorMap) ;        
        return {
          ...state,
          designStylesTree: state.designStylesTree,
          currentInstances: state.currentInstances,
          jerseyThumbColorMap: JSON.parse(actionResult.data.jerseyThumbColorMap)
        }
      }
      return state ;
      
    case initPartnerWithColorsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        
        const partnerData = actionResult.data.partnerData;
        const allDesignStyles = partnerData.PartnerDesignStyle.map((item) => {
          return designStyleFromActionResult(item);
        });
        const allGrayGoods = partnerData.PartnerGrayGood.map((item) => {
          return grayGoodFromActionResult(item);
        });
        const allSports = partnerData.PartnerSport.map((item) => {
          return sportFromActionResult(item);
        });

        let colors = getColors(actionResult.data.colors).sort((a, b) => (a.name > b.name) ? 1 : -1);
        colors = sanitaizeColors(colors);    
        const jerseyColors = completeSpotColorsForJerseyColorConfig(actionResult.data.partnerData, colors);

        const stylesDic = getDesignStylesDic(allDesignStyles, allGrayGoods, allSports) ;
        const stylesTree = dicToTree(stylesDic)

        let notProcessedQueue = [];
        getQueue(notProcessedQueue, stylesTree, state.currentInstances, jerseyColors)

        const currentInstances = fillUpcomingInstances(notProcessedQueue, global._3D_THUMBS_MAX_CONCURRENT_INSTANCES_);

        return {
          ...state,
          designStylesTree: stylesTree,
          currentInstances,       
          jerseyColors,
          notProcessed: notProcessedQueue,
          remaining: notProcessedQueue.length
        }
    }
    return state ;

    // case grayGoodGetThumb3DUseCase.type:
    //   if(actionResult.start()) {
    //     return state;
    //   } 
    //   else if(actionResult.success()) {
    //     let currentInstances = state.currentInstances.filter((item) => { return item !== actionResult.data.thumb3DKey }) //release from concurrent instance
    //     const notProcessed = state.notProcessed.filter((item) => { return item !== actionResult.data.thumb3DKey }) //release from not processed queue                 

    //     if (actionResult.data.thumb3DKey.indexOf(',') == -1)
    //       actionResult.data.thumb3DKey = actionResult.data.originalDocId + ',' + actionResult.data.thumb3DKey ;
          
    //     return {
    //       ...state,
    //       currentInstances,
    //       notProcessed,
    //       remaining: notProcessed.length,
    //       jerseyThumbColorMap: {
    //         ...state.jerseyThumbColorMap, [actionResult.data.thumb3DKey]: actionResult.data.createdDocId
    //       }
    //     }
    //   }
    //   else if(actionResult.fail()) {
    //     const currentInstances = state.currentInstances.filter((item) => { return item !== actionResult.data.thumb3DKey }) //release from concurrent instance

    //     return {
    //       ...state,
    //       currentInstances          
    //     }
    //   }
    //   return state ;

    case putNextOnQueueBatchThumbUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if(actionResult.success()) {
        const notProcessed = state.notProcessed.filter((item) => { return item !== actionResult.data.thumb3DKey });
        let currentInstances = state.currentInstances ;
        currentInstances.push(notProcessed.pop());

        return {
          ...state,
          currentInstances          
        }
      }
  }
  return state ;
}

function getColors (colorList): Cx.Color[] {
  return colorList.map((data) => {
    return colorFromCore(data);
  });
}

function sanitaizeColors(colorList): Cx.Color[] {
  return colorList.map((c) => {
    c.name = sanitizeColorName(c.name);
    return c ;
  });
}

function sanitizeColorName(name) {
  return name.substring(name.indexOf('TS_')+3, name.length).replaceAll('_', ' ')
}

function completeSpotColorsForJerseyColorConfig(partnerData, colors) {
  let schemeColors = [];
  Object.keys(partnerData.PartnerBranding[0].jerseyDefaultColors).forEach((colorKey) => {
    const color = partnerData.PartnerBranding[0].jerseyDefaultColors[colorKey].replace('#','');
    let schemeColor = { RGB : color, SpotColorName: ''}
    const matchingColor = colors.filter( (c) => { return c.rgb.toUpperCase() === color.toUpperCase() } );
    if (matchingColor.length > 0 )
      schemeColor.SpotColorName = matchingColor[0].spotColorName 
    schemeColors.push(schemeColor);
  });
  return schemeColors ;
}

function colorFromCore (data): Cx.Color {
  const argb = data.ARGB;

  return new Cx.Color({
    alpha: +(parseInt(argb.substring(0, 2), 16) / 255).toFixed(2),
    cmyk: null,
    name: data.Name,
    owner: {}, // Preserve ownership
    rgb: argb.substring(2, 8).toUpperCase(),
    spotColorName: data.SpotColorName
  });
}

function fillUpcomingInstances(notProccessed, total)
{
  return notProccessed.slice(notProccessed.length - total - 1, notProccessed.length -1);
}

function getQueue(queue, node, concurrent, colors)
{
  if (node.length > 0)
  {
    node.forEach((d)=>{
      if ( d.docId && ! d.children )//only last node
      {
        const md5Hash = SparkMD5.hash(JSON.stringify({ colorSchemme: colors }));
        queue.push(d.docId + ',' + md5Hash);
      }
      else
        if (d.children)
          getQueue(queue, d.children, concurrent, colors);  
    }); 
  }
}

function setCheckedForProccessed(node, colorMap)
{
  if (node && colorMap)
  {
    node.forEach((d)=>{
      if (d.docId && colorMap[d.docId])
        d.checked = 1
      if (d.children)
        setCheckedForProccessed(d.children, colorMap);  
    });
  }
}

function dicToTree(node) {
  if(node)
  {
    return Object
      .keys(node)
      .map(k => ({ name: node[k].name, docId: node[k].DocId, children: dicToTree(node[k].children)}))
  }
} 

//designStyle.PartnerGrayGoodId > grayGood.sportId
function getDesignStylesDic(designStyles, grayGoods, sports) //no need for recursion as tree levels are given
{
  let tree = {}

  grayGoods.forEach((grayGood) => {

    const sport = sports.find((s) => { return s.Raw.sport.id === grayGood.SportId });

    if(sport)
    {
      if(!tree[grayGood.SportId])
          tree[grayGood.SportId] = { name: sport.Name, Id: sport.Id, DocId: sport.DocId, children: {} }

      tree[grayGood.SportId].children[grayGood.Id] = { name: grayGood.Name, DocId: grayGood.DocId, children: {} };

      const grayGoodDesignStyles = designStyles.filter( (d) => { return d.PartnerGrayGoodId === grayGood.Id })

      grayGoodDesignStyles.forEach((design) => {
        tree[grayGood.SportId].children[grayGood.Id].children[design.Id] = { name: design.Name, DocId: design.DocId, checked: false };
      });
    }
  });
  return tree ;
}