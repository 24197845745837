import { backendClient } from '../../gui/services/backend';
import { hasCoachesKey } from '../domain/Login/Conditions';
import { hasOpenedSavedOrder } from '../domain/Order/Conditions';
import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderStatesByCoachesKey$ } from './actions/getOrderStatesByCoachesKey$';
import { prepareOrderForSaving$ } from './actions/prepareOrderForSaving$';
import { UseCase } from './usecase/UseCase';

export interface UpdateOrderUseCaseOptions {
  coachesKey: string;
  partnersKey: string;
  id: string;
  products: Product[];
  shippingMethod: any;
  contactInfo: any;
  retailTotal: number;
  costTotal: number;
  cadworxSessionId: string;
  salesTax: number;
  minOrderFee: number;
  productLogos: any[];
  deliveryMethod: string;
  shippingAddress: any;
}

export const updateOrderUseCase: UseCase = {
  type: 'UPDATE_ORDER',

  checkConditions: (state) => {
    return Condition.evaluate(state, [hasCoachesKey, hasOpenedSavedOrder]);
  },

  run: (options: UpdateOrderUseCaseOptions) => {
    const coachesKey = options.coachesKey;

    return prepareOrderForSaving$(options.products
      , null
      , null
      , options.shippingMethod
      , null
      , options.contactInfo
      , options.retailTotal
      , options.costTotal
      , options.salesTax
      , options.minOrderFee
      , options.productLogos
      , options.deliveryMethod
      , options.shippingAddress)
      .then((result) => {
        return backendClient.post<any>({
          endPoint: 'coaches',
          method: 'updateOrderState',
          payload: {
            'coachesKey': coachesKey,
            'id': options.id,
            'orderState': result.orderState,
            'orderStateSnippet': result.orderStateSnippet
          }
        })
          .then(() => {
            return getOrderStatesByCoachesKey$(coachesKey, options.partnersKey)
              .then((data) => {
                return Cx.resolve({
                  savedOrders: data
                });
              });
          });
      });
  }
};

/**
 * @category Can Use Case
 */
export function canUpdateOrder(state): boolean {
  return updateOrderUseCase.checkConditions(state).isAllowed;
}

/**
 * @category Use Case
 */
export function updateOrder(options: UpdateOrderUseCaseOptions) {
  app.runUseCase(updateOrderUseCase, options);
}
