import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { getDecorationAreasBrushes, getUniqueBrushes } from './actions/canvas';

export interface getUniqueDocumentBrushesUseCaseOptions {
  canvasId: string
  decorationAreas: any;
}

export const getUniqueDocumentBrushesUseCase: UseCase = {
  type: 'GET_UNIQUE_ZONE_BRUSHES',

  run: (options: getUniqueDocumentBrushesUseCaseOptions) => {
    const colorZonesBrushes = getUniqueBrushes(options.canvasId);
    const decorationAreasBrushed = getDecorationAreasBrushes(options.canvasId, options.decorationAreas);
    const uniqueBrushes = colorZonesBrushes.concat(decorationAreasBrushed);

    return Cx.resolve(uniqueBrushes);
  }
};

export function getUniqueDocumentBrushes(options: getUniqueDocumentBrushesUseCaseOptions) {
  app.runUseCase(getUniqueDocumentBrushesUseCase, options);
}
