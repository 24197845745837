import { cloneProduct, Product } from '../../domain/Product/Product';
import { productSnippetFromProduct } from '../../domain/Product/ProductSnippet';
import { defaultRosterItem, filterForValidRosterItems } from '../../domain/Roster/RosterItem';
import { RosterSizeMap } from '../../domain/Roster/RosterSizeMap';

export function prepareOrderForSaving$(products: Product[]
  , partnerStyleData: any
  , shippingMethod: any
  , storeLocation: any
  , contactInfo: any
  , partnerInfo: any
  , retailTotal: number
  , costTotal: number
  , salesTax: number
  , minOrderFee: number
  , productLogos: any[]
  , deliveryMethod: string
  , shippingAddress: any) {

  products.forEach((p: Product) => {
    prepareRosterItems(p);
  });

  let orderState = products.map((p: Product, index: number) => {
    // Remove base64 encoded image data and Cdl

    const newProduct = cloneProduct(p);

    newProduct.BackSnapshot = { DocId: undefined, Image: undefined }
    newProduct.FrontSnapshot = { DocId: undefined, Image: undefined }
    // Use RosterSizeMap to update roster items with an 'OriginalDocPageName'
    const rosterSizeMap = newProduct.RosterSizeMap;

    if (productLogos && productLogos?.length > 0) {
      const logos = productLogos?.filter((logo: any) => {
        const key = Object.keys(logo);
        return key[0] === newProduct.ItemNumber;
      });

      newProduct.UploadedLogos = logos || [];
    }

    newProduct.RosterItems.forEach((item) => {
      const map = findRosterSizeMap(rosterSizeMap, item.Size);
      item.OriginalDocPageName = map ? map.OriginalDocPageName : undefined;
    });

    return newProduct;
  });

  if (storeLocation && partnerInfo) {
    partnerInfo.address1 = storeLocation.address1;
    partnerInfo.address2 = storeLocation.address2;
    partnerInfo.city = storeLocation.city;
    partnerInfo.state = storeLocation.state;
    partnerInfo.postalCode = storeLocation.postalCode;
    partnerInfo.salesTaxType = storeLocation.salesTaxType;
    partnerInfo.supportEmail = partnerInfo.supportEmail || partnerInfo.email;
    partnerInfo.supportPhone = partnerInfo.supportPhone || partnerInfo.phone;
  }

  const orderStateSnippet = {
    products: products.map((p: Product, index: number) => {
      const productSnippet = productSnippetFromProduct(p);
      productSnippet.savedDocId = p.SavedDocId;
      return productSnippet;
    })
    , shippingMethod: shippingMethod
    , coachContactInfo: contactInfo
    , partnerInfo: partnerInfo
    , retailTotal
    , costTotal
    , salesTax
    , minOrderFee
    , partnerStyleData
    , deliveryMethod: deliveryMethod
    , shippingAddress: shippingAddress
  };

  return Cx.resolve({
    orderState,
    orderStateSnippet
  });
}

function prepareRosterItems(product: Product) {
  const noSizeOptions = product.RosterSizeOptions.length === 0;
  const defaultItem = defaultRosterItem(product.RosterSizeOptions && { Size: '' });
  const items = filterForValidRosterItems(product.RosterItems, defaultItem);

  if (noSizeOptions) {
    items.forEach((item) => {
      item.Size = 'OSFA';
    });
  }

  product.RosterItems = items;
}

function findRosterSizeMap(items: RosterSizeMap[], size: string): RosterSizeMap {
  return items.find((item) => {
    return item.Size === size;
  });
}
