import { cloneColorZoneFillTarget, ColorZoneFillTarget, fillTargetIsColorZone } from './ColorZoneFillTarget';
import { cloneOutlineFillTarget, fillTargetIsOutline, OutlineFillTarget } from './OutlineFillTarget';
import { cloneTextFillTarget, fillTargetIsText, TextFillTarget } from './TextFillTarget';
import { ColorZoneBrush } from './ColorZone';

export interface FillTarget {
  type: string;
}

export function cloneFillTarget (fillTarget: FillTarget): FillTarget {
  if (fillTargetIsColorZone(fillTarget)) {
    return cloneColorZoneFillTarget(fillTarget as ColorZoneFillTarget);
  }

  if (fillTargetIsOutline(fillTarget)) {
    return cloneOutlineFillTarget(fillTarget as OutlineFillTarget);
  }

  if (fillTargetIsText(fillTarget)) {
    return cloneTextFillTarget(fillTarget as TextFillTarget);
  }

  return undefined;
}

export function getFillTargetBrush  (fillTarget: FillTarget): Cx.Brush {
  if (fillTargetIsColorZone(fillTarget)) {
    const colorZoneFillTarget = fillTarget as ColorZoneFillTarget;
    //return new Cx.Brush({ color: colorZoneFillTarget.colorZone.Value.color }); //TODO check this
    return colorZoneFillTarget.colorZone.Value ;
  }

  if (fillTargetIsOutline(fillTarget)) {
    const outlineFillTarget = fillTarget as OutlineFillTarget;
    const index = outlineFillTarget.outlineIndex;
    const outline = outlineFillTarget.textDesignElement.outlines[index];
    return outline ? outline.Brush : undefined;
  }

  if (fillTargetIsText(fillTarget)) {
    const textFillTarget = fillTarget as TextFillTarget;
    return textFillTarget.textDesignElement.brush;
  }

  return undefined;
}

// export function getFillTargetColor  (fillTarget: FillTarget): Cx.Color {
//   if (fillTargetIsColorZone(fillTarget)) {
//     const colorZoneFillTarget = fillTarget as ColorZoneFillTarget;
//     return colorZoneFillTarget.colorZone.Value.value;
//   }

//   if (fillTargetIsOutline(fillTarget)) {
//     const outlineFillTarget = fillTarget as OutlineFillTarget;
//     const index = outlineFillTarget.outlineIndex;
//     const outline = outlineFillTarget.textDesignElement.outlines[index];
//     return outline ? outline.Color : undefined;
//   }

//   if (fillTargetIsText(fillTarget)) {
//     const textFillTarget = fillTarget as TextFillTarget;
//     return textFillTarget.textDesignElement.color;
//   }

//   return undefined;
// }

export function setFillTargetBrush  (fillTarget: FillTarget, brush: Cx.Brush) {
  if (fillTargetIsColorZone(fillTarget)) {
    const colorZoneFillTarget = fillTarget as ColorZoneFillTarget;
    const zoneBrush: ColorZoneBrush = { Name: colorZoneFillTarget.colorZone.Name, Value: brush }
    colorZoneFillTarget.colorZone= zoneBrush
  }

  if (fillTargetIsOutline(fillTarget)) {
    const outlineFillTarget = fillTarget as OutlineFillTarget;
    const index = outlineFillTarget.outlineIndex;
    outlineFillTarget.textDesignElement.outlines[index].Brush = brush ;
  }

  if (fillTargetIsText(fillTarget)) {
    const textFillTarget = fillTarget as TextFillTarget;
    textFillTarget.textDesignElement.brush = brush ;
  }
}