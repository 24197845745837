import { requestAccessByPhone, verifyPhoneCode, requestAccessByEmail, verifyEmailHash } from '../../../backendApi/authentication';
import {
  COACH_HAS_SESSION_URL,
  COACH_LOGOUT_URL,
  COACH_BELONGS_TO_PARTNER,
  getGoogleSignInUrl$,
} from '../../../backendApi/backendApi';
import { backendClient, getToken } from '../../../gui/services/backend'
import { cleanPhoneNumber } from '../../utility/cleanPhoneNumber';
import { coachContactInfo } from '../createCoachContactInfo';

// TODO: Organize.  Go through BackendAPI interface.  Separate files.

export function createCoach$(email: string, name: string, phone: string, partner: any, cadworxid: string) {
  return backendClient.post<any>({
    endPoint: 'coaches',
    method: 'create',
    payload: { email, name, phone, partner, cadworxSessionId: cadworxid }
  });
}

export function createCoachContactInfo$(coachesKey: string, contactInfo: coachContactInfo) {
  return backendClient.post<any>({
    endPoint: 'coaches',
    method: 'createContactInfo',
    payload: { coachesKey, ...contactInfo }
  });
}

export async function requestForAccessByEmail$(email: string, returnUrl: string, partnerInfo?: any, token?: string) {
  return await requestAccessByEmail({ email, returnUrl, partnerInfo, token });
}

export async function requestForAccessByGoogle$(returnUrl: string, hash: string) {
  return getGoogleSignInUrl$(returnUrl, hash)
    .then((resp) => {
      window.open(resp.loginUrl.url, '_blank');
    });
}

export async function requestForAccessByPhone$(phone: string) {
  phone = cleanPhoneNumber(phone);
  return await requestAccessByPhone({ phone });
}

export async function verifyPhoneCode$(code: string, phone: string) {
  return await verifyPhoneCode({ code, phone });
}

export async function verifyEmailHash$(hash: string, email: string) {
  return await verifyEmailHash({ hash, email });
}

export async function logOut$(sessionToken: string) {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const payload = { sessionToken };

  const logoutResult = await fetch(COACH_LOGOUT_URL, {
    body: JSON.stringify(payload),
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  if (logoutResult.ok) {
    const jsonResp = await logoutResult.json();
    localStorage.removeItem('ubdesigner2.apiToken');

    return jsonResp;
  }

  return Promise.reject('Problem occurred during logout.');
}

export async function coachHasSession$(sessionToken?: string, coachesKey?: string, superSessionToken?: string) {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const payload = { coachesKey, superSessionToken, sessionToken };

  const hasSessionResp = await fetch(COACH_HAS_SESSION_URL, {
    body: JSON.stringify(payload),
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  if (hasSessionResp.ok) {
    const jsonResp = await hasSessionResp.json();
    return jsonResp;
  }

  return Promise.reject('Problem occurred when requesting session.');
}

export async function getCoachBelongsToPartner$(coachesKey?: string, partnersKey?: string) {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const payload = { coachesKey, partnersKey };

  const hasSessionResp = await fetch(COACH_BELONGS_TO_PARTNER, {
    body: JSON.stringify(payload),
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  if (hasSessionResp.ok) {
    const jsonResp = await hasSessionResp.json();
    return jsonResp;
  }

  return Promise.reject('Problem occurred when requesting session.');
}
