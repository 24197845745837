import React from 'react';
import { connect } from 'react-redux';
import { Product } from '../../../app/domain/Product/Product';
import { hideModal, showAddRosterStep, showCustomizeStep, showGrayGoodsStep, showSavedOrders } from '../../../app/initApp';
import { showCustomMsgPopup } from '../../../app/usecases/customMsgPopupUseCase';
import { loadCanvasDocId } from '../../../app/usecases/loadCanvasDocId';
import { showRemoveProductPopup } from '../../../app/usecases/removeProductPopupUseCase';
import { setActiveProductItemNumber } from '../../../app/usecases/setActiveProductItemNumber';
import global from '../../services/global';
import uniqueKey from '../../util/uniqueKey';
import Button from '../misc/Button';
import ReviewFooter from './ReviewFooter';
import ReviewItem from './ReviewItem';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';

// Styles
import styles from './Review.module.css';

interface Props {
  hidden: boolean;
  products: Product[];
  total?: number;
  totalCount?: number;
  activeItemNumber?: string;
  activeStep: string;
  priceSizeOffsetTotal?: number;
  minOrderFee?: number;
  cadworxSessionId?: string;
  totalSalesTax?: number;
  colorsInUseByProduct: any;
}

const Review = (props: Props) => {
  const activeStep = props.activeStep;
  const className = `${styles.review}${activeStep === GuiSteps.REVIEW ? ` ${styles.addFooter}` : ""}`;
  const products = props.products;
  const haveProducts = products.length > 0;
  const cadworxSessionId = props.cadworxSessionId;

  if (!props.hidden) {
    return (
      <div className={className}>

        <div className={styles.header}>
          <div className={styles.headerText + ' partner-color1'}>Review My Order</div>
          {/* Temporarily hidden */}
          {/* <Button
              className={styles.headerButton}
              iconCls="fa-download"
              onClick={() => { }} />
            <Button
              className={styles.headerButton}
              iconCls="fa-print"
              onClick={() => { }} />
            <Button
              className={styles.headerButton}
              iconCls="fa-share-alt"
              onClick={() => { }} /> */}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.leftContent}>
            <div className={styles.header2 + ' partner-background-color2'}>
              <div className={styles.header2Text}>My Products {"(" + products?.length + ")"}</div>
              <AddProductButton className={styles.addProductButtonBlue + ' partner-background-color2'} />
            </div>
            <div className={styles.body}>
              {
                haveProducts
                  ?
                  <React.Fragment>
                    {products.map((product, index) => {
                      return <ReviewItem
                        key={product.ItemNumber ?? uniqueKey()}
                        onEditDesignClick={() => {
                          setActiveProductItemNumber({ activeItemNumber: product.ItemNumber })
                          loadCanvasDocId({ canvasId: global.__MAIN_CANVAS_ID__, docId: product.SavedDocId })
                          showCustomizeStep();
                        }}
                        onEditRosterClick={() => {
                          setActiveProductItemNumber({ activeItemNumber: product.ItemNumber })
                          loadCanvasDocId({ canvasId: global.__MAIN_CANVAS_ID__, docId: product.SavedDocId })
                          showAddRosterStep({ itemNumber: product.ItemNumber });
                        }}
                        onDeleteProductClick={() => {
                          const itemNumber: string = product.ItemNumber;
                          showRemoveProductPopup({
                            props: { itemNumber }
                          });
                        }}
                        product={product}
                        cadworxSessionId={cadworxSessionId}
                        activeStep={activeStep} />
                    })}
                  </React.Fragment>
                  :
                  <div className={styles.noProductsMessage}>
                    You currently have no products...
                  </div>
              }
            </div>
            <div className={styles.addProductRow}>
              <Button
                className={styles.addBtn + ' btn-primary partner-color1 partner-border-color1'}
                iconCls="fa-plus"
                label="Add Another Product"
                onClick={() => handleAddNewProductClick()} />
            </div>
          </div>
          <div className={styles.rightContent}>
            <ReviewFooter
              products={props.products}
              total={props.total}
              totalCount={props.totalCount}
              priceSizeOffsetTotal={props.priceSizeOffsetTotal}
              minOrderFee={props.minOrderFee}
              totalSalesTax={props.totalSalesTax}
              key={uniqueKey()} />
          </div>
        </div>
      </div>
    );
  } else {
    return (<div className={className}></div>);
  }
}

const AddProductButton = (props) => {
  return <Button
    className={props.className}
    iconCls="fa-plus"
    label="Add Another Product"
    onClick={() => handleAddNewProductClick()} />;
}

function openSavedOrdersModal() {
  showSavedOrders();
}

function goToGrayGoodStep() {
  showGrayGoodsStep();
  hideModal();
}

const handleAddNewProductClick = () => {
  showCustomMsgPopup({
    title: 'ADD ANOTHER PRODUCT:',
    onClick: goToGrayGoodStep,
    onCancelClick: openSavedOrdersModal,
    showCancel: true,
    showConfirm: true,
    showConfirmMsg: "NEW PRODUCT",
    showCancelMsg: "FROM SAVED ORDER",
    isCancelOutline: true,
    confirmBtnIcon: "fas fa-plus"
  })
}

const mapStateToProps = (state, props) => {
  const products = state.Order.products;
  const total = state.Order.totalPrice;
  const totalCount = state.Order.totalCount;
  const priceSizeOffsetTotal = state.Order.totalSizePriceOffset;
  const cadworxSessionId = state.Session.partnerCadworxSessionId;
  const minOrderFee = state.Order.minOrderFee;
  const totalSalesTax = state.Order.totalSalesTax;

  return {
    products,
    total,
    totalCount,
    priceSizeOffsetTotal,
    minOrderFee,
    activeItemNumber: state.Order.activeItemNumber,
    activeStep: state.GUI.activeStep,
    cadworxSessionId,
    totalSalesTax
  };
};

export default connect(mapStateToProps, null)(Review);
