import React, { useEffect, useState } from 'react';
import { Product, getValidThumbUrl, CxRenderThumbnail } from '../../../app/domain/Product/Product';
import Button from '../misc/Button';
import DisplayRosterGrid from './roster/DisplayRosterGrid';
import { isMobile } from '../../../gui/util/isMobile';
import global from '../../../gui/services/global';
import { uniqueId } from 'lodash';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';
import StylesCarousel from '../design/StylesCarousel';
import { connect } from 'react-redux';
import { GetDefaultColorZones, GetServiceColors, GetSpotColorDefaultIndexesZoneMap } from '../../../app/domain/Pattern/colorZoneHelper';

// Styles
import styles from './ReviewItem.module.css';

interface Props {
  product: Product;
  onEditDesignClick?: Function; // Optionally show edit button
  onEditRosterClick?: Function; // Optionally show edit button
  onDeleteProductClick?: Function; // Optionally show delete button
  cadworxSessionId?: string;
  activeStep?: string;
  colors: any;
}

const ReviewItem = (props: Props) => {
  const product = props.product;
  const cadworxSessionId = props.cadworxSessionId;
  const designStyle = product.DesignStyle;
  const designStyleName = designStyle ? designStyle.Name : '';
  const grayGood = product.GrayGood;
  const grayGoodName = grayGood ? grayGood.Name : '';
  const showMobile: boolean = isMobile();
  const price = product.Price;
  const rosterItems = product.RosterItems;
  const totalItems = product.RosterItems?.length;
  const imgContainer: string = showMobile ? styles.imgContainerMobile : styles.imgContainer;
  const rosterGridCls: string = rosterItems.length === 0
    ? (!showMobile ? [styles.rosterGridTable, styles.noRosterWarning].join(' ') : [styles.rosterGridContainer, styles.noRosterWarning].join(' '))
    : (!showMobile ? styles.rosterGridTable : styles.rosterGridContainer);
  const [colorsInUse, setColorsInUse] = useState<any>(props.colors);

  useEffect(() => {
    if (props.colors) {
      const colors = removeDuplicates(props.colors);
      setColorsInUse(colors)
    }
  }, [props.colors]);

  const frontThumbModel: CxRenderThumbnail = {
    DocId: product.SavedDocId,
    Size: global._SNAPSHOT_THUMB_SIZE_,
    Camera: 'Front',
    cadworxSessionId,
    type: 'img'
  }

  const backThumbModel: CxRenderThumbnail = {
    DocId: product.SavedDocId,
    Size: global._SNAPSHOT_THUMB_SIZE_,
    Camera: 'Back',
    cadworxSessionId,
    type: 'img'
  }

  const sizes = Array.from(product.RosterItems
    .reduce((m, { Size, Quantity }) =>
      m.set(Size, (m.get(Size) || 0) + Number(Quantity)), new Map),
    ([size, quantity]) => ({ size, quantity }));

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.style.display = "flex";
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  function getDocThumb(id) {
    const thumbSize = 260;
    const requestModel = {
      Doc: { Id: id },
      Format: 'png',
      Height: thumbSize,
      Width: thumbSize
    };

    return Cx.Core.Doc3D.thumbnailUrl(requestModel);
  }

  const removeDuplicates = (arr: any) => {
    const seen = new Set();
    const uniqueRgb = arr.filter(item => {
      const duplicate = item instanceof Cx.Color && seen.has(item?.rgb);
      seen.add(item.rgb);
      return !duplicate;
    });

    return uniqueRgb;
  }

  return (<>
    {showMobile
      ?
      <div className={styles.reviewItemMobile}>
        <div className={styles.reviewContainer}>
          <div className={styles.thumbFront}>
            <img src={getDocThumb(product?.GrayGood.DocId)} width="100%" className={styles.blur} />
            <img src={getValidThumbUrl(frontThumbModel, product)} onLoad={handleOnLoad} style={{ display: "none" }} className={styles.fadeIn} />
          </div>
          <div className={styles.productDescription}>
            <div className={styles.productName}>
              <div className={styles.grayGood + ' partner-color2'}>{grayGoodName}</div>
            </div>
            <div className={styles.designStyle}>{designStyleName}</div>
            <div className={styles.divider}></div>
            <div className={styles.quantity}>{totalItems} ITEMS</div>
            <div className={styles.pruductPrice}>
              <div className={styles.price}>{props.activeStep === GuiSteps.REVIEW
                ? "$" + price + " Each"
                : "LOAD ORDER TO SEE PRICE"}</div>
              <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>
                {props.activeStep === GuiSteps.REVIEW
                  ? "$" + product.TotalPrice?.toFixed(2)
                  : "TBD"}</span></div>
            </div>
          </div>
        </div>
        <div
          className={styles.sizesSummary}>
          SIZES: {sizes.map((item: any, i: number) => {
            return <span
              className={styles.sizes}
              key={uniqueId()}>
              {item.size} - <b>{item.quantity}</b>{i === (sizes.length - 1) ? '' : ','}
            </span>
          })}
        </div>
        <div className={rosterGridCls}>
          <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} /></div>
        {rosterItems.length === 0 &&
          <div className={styles.warningMsg}>
            <span>
              <i className="icon fas fa-light fa-exclamation"></i>
              No roster found. Click edit roster button below to add a roster
            </span>
          </div>}
        <div className={styles.mobileFooterButtons}>
          <EditDesignButton
            className={styles.footerEditDesignButton}
            onClick={props.onEditDesignClick} />
          <span className={styles.separator}>|</span>
          <EditRosterButton
            className={styles.editRosterButton}
            onClick={props.onEditRosterClick} />
          <span className={styles.separator}>|</span>
          <DeleteProductButtonMobile
            className={styles.deleteButton}
            onClick={props.onDeleteProductClick} />
        </div>
        {colorsInUse &&
          <>
            <div className={styles.colorsWrapper}>
              <span className={styles.designStyle}>SELECTED COLORS</span>
              <StylesCarousel
                styleList={colorsInUse}
                inUseSelected={true}
                disabled={false}
                hidden={false}
              />
            </div>
          </>
        }
      </div>
      :
      <><div className={styles.reviewItem}>
        <div className={styles.itemHeader}>
          <div className={styles.grayGood + ' partner-color3'}>{grayGoodName}</div>
          <div className={styles.price}>{props.activeStep === GuiSteps.REVIEW
            ? "$" + price + " Each"
            : "LOAD ORDER TO SEE PRICE"}</div>
          <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>{props.activeStep === GuiSteps.REVIEW
            ? "$" + product.TotalPrice?.toFixed(2)
            : "TBD"}</span></div>
        </div>
        <div className={styles.designStyle}>{designStyleName}</div>
        <div className={styles.itemNumber}>Item Number: {product.ItemNumber}</div>

        <div className={styles.removeItem}>
          <DeleteProductButton
            className={styles.deleteButton}
            onClick={props.onDeleteProductClick} />
        </div>
        <div className={styles.body}>
          <div className={styles.bodyColumnOne}>
            <div className={imgContainer}>
              <div className={styles.thumbFront}>
                <img src={getDocThumb(product?.GrayGood.DocId)} style={{ width: "70%" }} className={styles.blur} />
                <img src={getValidThumbUrl(frontThumbModel, product)} onLoad={handleOnLoad} style={{ display: "none" }} className={styles.fadeIn} />
                <img src={getValidThumbUrl(backThumbModel, product)} onLoad={handleOnLoad} style={{ display: "none" }} className={styles.fadeIn} />
              </div>
            </div>
          </div>
          <div className={rosterGridCls}>
            <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} />
            {rosterItems.length === 0 &&
              <div className={styles.warningMsg}>
                <span>
                  <i className="icon fas fa-light fa-exclamation"></i>
                  No roster found. Click edit roster button below to add a roster
                </span>
              </div>}
          </div>
        </div>
        {(props.activeStep && GuiSteps.REVIEW) &&
        <div className={styles.footer}>
          <div className={styles.columnOne}>
            <EditDesignButton
              className={styles.footerEditDesignButton}
              onClick={props.onEditDesignClick} />
          </div>
          <div className={styles.columnTwo}>
            <EditRosterButton
              className={styles.editRosterButton}
              onClick={props.onEditRosterClick} />
          </div>
        </div>
        }

        {(props.activeStep && GuiSteps.REVIEW) &&
          <div className={styles.colorsWrapper}>
            <span className={styles.designStyle}>SELECTED COLORS</span>
            {colorsInUse ?
              <StylesCarousel
                styleList={colorsInUse}
                inUseSelected={true}
                disabled={false}
                hidden={false}
              />

              :
              <><div className={styles.colorsCarousel}>
                <div className={styles.containerSkeleton}>
                  {Array.from(Array(6)).map(m => {
                    return <><div className={styles.skeleton}></div></>
                  })}
                </div>
              </div>
              </>
            }
          </div>
        }

      </div></>
    }
  </>)
};

const DeleteProductButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1' + ' ' + styles.underline}
      iconCls="fa-trash-alt"
      label="Remove This Item"
      onClick={props.onClick} />
  }

  return null;
}

const EditDesignButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1' + ' ' + styles.underline}
      label="Edit Design"
      onClick={props.onClick} />
  }

  return null;
}

const EditRosterButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1' + ' ' + styles.underline}
      label="Edit Roster"
      onClick={props.onClick} />
  }

  return null;
}

const DeleteProductButtonMobile = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1' + ' ' + styles.underline}
      label="Remove"
      onClick={props.onClick} />
  }

  return null;
}

const mapStateToProps = (state, props) => {
  const colorsInUseByProduct = state.GUI.colorsInUseByProduct;
  const productColors = colorsInUseByProduct?.length > 0 && colorsInUseByProduct?.find(ci => { return ci.docId === props.product.SavedDocId });
  const uniqueBrushes = productColors && productColors?.uniqueBrushes;

  const parseUniqueDocumentBrushes = (brushes: any, patterns: any) => {
    const styles = brushes.map(b => {
      if (b.color)
        return b.color
      else {
        const pattern = patterns.find(p => p.Config.public.metadata.patternLink == b._link);
        const selectedCxColors = b.colorMap.colors;
        const map = GetSpotColorDefaultIndexesZoneMap(b.getColors());
        const initialColorZones = GetDefaultColorZones(Cx.uniqueColorZones(b), pattern.Config.public.colors[0], map);
        const initialColors = pattern.Config.public.colors[0];
        const inUseMetadata = { selectedColors: GetServiceColors(selectedCxColors, initialColorZones), initialColorZones, initialColors: initialColors.Color, selectedCxColors }
        return Object.assign(pattern, { metadata: inUseMetadata });
      }
    })

    return styles;
  }

  return {
    colors: uniqueBrushes && parseUniqueDocumentBrushes(uniqueBrushes, state.Patterns.patterns)
  };
};

export default connect(mapStateToProps, null)(ReviewItem);
