import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface SaveLogoDPIUseCaseOptions {
  decorationAreaId: string,
  decorationAreaName: string,
  dpi: string
}

export const saveLogoDPIUseCase: UseCase = {
  type: 'SAVE_LOGO_DATA',

  run: ( options: SaveLogoDPIUseCaseOptions ) => {
    return Cx.resolve(options)
  }
};


export function saveLogoDPIData( options: SaveLogoDPIUseCaseOptions ) {
  app.runUseCase( saveLogoDPIUseCase, options );
}