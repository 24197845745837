import React, { useEffect, useState } from 'react';
import TextInput from '../../misc/field/TextInput'
import Button from '../../misc/Button'
import { coachContactInfo, createCoachContactInfo } from '../../../../app/usecases/createCoachContactInfo';
import NumberInput from '../../misc/field/NumberInput';
import { validateEmail } from '../../../util/validateEmail';
import { validatePhone } from '../../../util/validatePhone';

// Styles
import styles from './Login.module.css';

export interface CoachContactInfoProps {
  coachKey: string,
  coachContactInfo: coachContactInfo
}

// name: string,
// email: string,
// phone: string,
// organizationName: string,
// streetAddress: string,
// city: string,
// state: string,
// zip: string

const CoachContactInfo = (props: CoachContactInfoProps) => {
  const id = null;
  const [name, handleNameChange] = useState(props.coachContactInfo?.name);
  const [email, handleEmailChange] = useState(props.coachContactInfo?.email);
  const [phone, handlePhoneChange] = useState(props.coachContactInfo?.phone);
  const [organizationName, handleOrganizationNameChange] = useState(props.coachContactInfo?.organizationName);
  const [streetAddress, handleStreetAddressChange] = useState(props.coachContactInfo?.streetAddress);
  const [city, handleCityChange] = useState(props.coachContactInfo?.city);
  const [state, handleStateChange] = useState(props.coachContactInfo?.state);
  const [zip, handleZIPChange] = useState(props.coachContactInfo?.zip);
  const [coachInfoCreateInited, handleCoachInfoCreateInited] = useState(false);
  const [isValidForm, setValidForm] = useState<boolean>(false);
  const [nameErrorMsg, setNameErrorMsg] = useState<string>("");
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState<string>("");
  const [zipErrorMsg, setZipErrorMsg] = useState<string>("");
  const nameRegEx = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

  useEffect(() => {
    const isEmail = validateEmail(email?.toLowerCase());
    const isPhone = phone ? validatePhone(phone) : false;
    validate(isEmail, isPhone);

    setValidForm((isEmail && isPhone && (name?.length > 0 && name.match(nameRegEx)?.length) && zip?.length > 0) ? true : false);
  }, [email, phone, name, zip]);

  const handleCreateCoachContactInfo = (id, email, name, phone, organizationName, streetAddress, city, state, zip) => {
    const contactInfo: coachContactInfo = {
      id, email, name, phone, organizationName, streetAddress, city, state, zip
    }
    handleCoachInfoCreateInited(true);
    createCoachContactInfo({ coachesKey: props.coachKey, contactInfo: { ...contactInfo } });
  }

  function validate(isEmail, isPhone) {
    setEmailErrorMsg((!isEmail && email?.length ? "Invalid Email" : (!isEmail && email?.length == 0 ? "Email is required" : "")));
    setNameErrorMsg((name?.length && !name.match(nameRegEx) ? "Invalid name" : (name?.length == 0 ? "Name is required" : "")));
    setPhoneErrorMsg((isPhone ? "" : (phone?.length == 0 ? "Phone is required" : (phone?.length > 0 && !isPhone ? "Invalid phone number" : ""))));
    setZipErrorMsg(zip?.length === 0 ? "Postal Code is required" : "");
  }

  return (
    <div className={styles.coachContactMain}>
      <div className={styles.parentcontent}>
        <div className={styles.loginMainTitle + ' partner-color1'}>CONTACT INFO</div>
        <div className={styles.loginTitle + ' partner-color2'}>PLEASE PROVIDE CONTACT INFO TO CONTINUE</div>
        <div className="provider">
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="Name" onChange={handleNameChange} value={name} ></TextInput>
            <span className={styles.textDanger}>{nameErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {nameErrorMsg}</span>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="Email" onChange={handleEmailChange} value={email} ></TextInput>
            <span className={styles.textDanger}>{emailErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {emailErrorMsg}</span>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <NumberInput
              className={styles.coachContactField}
              label="Phone"
              onChange={handlePhoneChange}
              value={phone} />
            <span className={styles.textDanger}>{phoneErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {phoneErrorMsg}</span>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="Organization Name" onChange={handleOrganizationNameChange} value={organizationName} ></TextInput>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="Street Address" onChange={handleStreetAddressChange} value={streetAddress} ></TextInput>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="City" onChange={handleCityChange} value={city} ></TextInput>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="State" onChange={handleStateChange} value={state} ></TextInput>
          </div>
          <div className={styles.coachContactInput + ' partner-color2'}>
            <TextInput className={styles.coachContactField} label="ZIP" onChange={handleZIPChange} value={zip}></TextInput>
            <span className={styles.textDanger}>{zipErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {zipErrorMsg}</span>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                handleCreateCoachContactInfo(id, email, name, phone, organizationName, streetAddress, city, state, zip);
              }}
              className={styles.saveContactBtn + ' partner-background-color1 partner-border-color1'}
              iconCls={coachInfoCreateInited ? 'fas fa-circle-notch fa-spin' : undefined}
              label={coachInfoCreateInited ? "" : "SAVE CONTACT INFO"}
              disabled={coachInfoCreateInited || !isValidForm}></Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoachContactInfo;
