import { getOrderStatesByCoachesKeyUrl } from "../../../backendApi/backendApi";
import { getToken } from "../../../gui/services/backend";

export async function getOrderStatesByCoachesKey$ (key: string, partnersKey: string) {
  const url = getOrderStatesByCoachesKeyUrl(key, partnersKey);
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const resp = await fetch(url, {
    credentials: 'include',
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Content-Type': 'application/json'
    }
  });

  if(resp.ok) {
    const jsonResp = await resp.json();
    return Promise.resolve(jsonResp);
  }

  return Promise.reject('Problem with getOrderStatesByCoachesKey');
}
