import { DesignStyle, designStyleFromActionResult } from '../domain/Design/DesignStyle';
import { cloneFillTarget, getFillTargetBrush } from '../domain/Design/FillTarget';
import { GrayGood, grayGoodFromActionResult } from '../domain/Design/GrayGood';
import { Sport, sportFromActionResult } from '../domain/Design/Sport';
import { TeamColor } from '../domain/Design/TeamColor';
import { CustomizeTab } from '../domain/Gui/CustomizeTab';
import { GuiSteps } from '../domain/Gui/GuiSteps';
import { GetSpotColorBasesWithMap } from '../domain/Pattern/colorZoneHelper'
import { isPatternBrush, isRasterPatternBrush } from '../domain/Pattern/Pattern'
import { activePatternFromBrush, emptyPattern, Pattern } from '../domain/Pattern/Pattern'
import { addOutlineUseCase } from '../usecases/addOutline';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { editRosterUseCase } from '../usecases/editRoster';
import { grayGoodPageNumDecrementUseCase } from '../usecases/grayGoodPageNumDecrementUseCase'
import { grayGoodPageNumIncrementUseCase } from '../usecases/grayGoodPageNumIncrementUseCase'
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { removeOutlineUseCase } from '../usecases/removeOutline';
import { setBatchModeUseCase } from '../usecases/setBatchMode'
import { setColorTypeMainColorSelectorUseCase } from '../usecases/setColorTypeMainColorSelector';
import { setFillUseCase } from '../usecases/setFill';
import { setFillTargetUseCase } from '../usecases/setFillTarget';
import { setFillTargetByClickUseCase } from '../usecases/setFillTargetByClick';
import { setGrayGoodUseCase } from '../usecases/setGrayGood';
import { setJerseySchemmeColorListIndexUseCase } from '../usecases/setJerseySchemmeColorListIndex'
import { setPatternComboVisiblityUseCase } from '../usecases/setPatternComboVisibility'
import { setSelectedPatternColorIndexUseCase } from '../usecases/setSelectedPatternColorIndex'
import { setSportUseCase } from '../usecases/setSport';
import { setTeamColorUseCase } from '../usecases/setTeamColor';
import { showAddRosterStepUseCase } from '../usecases/showAddRosterStep';
import { showColorZonesTabUseCase } from '../usecases/showColorZonesTab';
import { showCustomizeStepUseCase } from '../usecases/showCustomizeStep';
import { showDesignStylesStepUseCase } from '../usecases/showDesignStylesStep';
import { showGraphicsTabUseCase } from '../usecases/showGraphicsTab';
import { showGrayGoodsStepUseCase } from '../usecases/showGrayGoodsStep';
import { showNameNumberTabUseCase } from '../usecases/showNameNumberTab';
import { showSportsStepUseCase } from '../usecases/showSportsStep';
import { setInitialOrderUseCase } from '../usecases/setInitialOrder';
import { coachContactInfo } from '../usecases/createCoachContactInfo';
import global from '../../gui/services/global';
import { showEditColorOptionsUseCase } from '../usecases/showEditColorOptions';
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { selectCoachContactInfoUseCase } from '../usecases/selectCoachContactInfo';
import { isLoadingOrderUseCase } from '../usecases/loadingOrder';
import { reportMessage } from '../../gui/util/rollbar';
import { prepareOrderForSavingUseCase } from '../usecases/prepareOrderForSaving';
import { setTextUseCase } from '../usecases/setText';
import { incrementTextHeightUseCase } from '../usecases/incrementTextHeight';
import { incrementTextSpacingUseCase } from '../usecases/incrementTextSpacing';
import { setFontUseCase } from '../usecases/setFont';
import { Units } from '../../canvas/helpers/units'
import { setEffectUseCase } from '../usecases/setEffect';
import { setActiveDecorationAreaPanelUseCase } from '../usecases/setIndexDecorationAreaPanel';
import { editTextRadioCheckUseCase } from '../usecases/setEditTextCheck';
import { editLogoRadioCheckUseCase } from '../usecases/setEditLogoCheck';
import { fillTargetIsOutline } from '../domain/Design/OutlineFillTarget';
import { getUniqueDocumentBrushesUseCase } from '../usecases/getUniqueDocumentBrushes';
import { getColorsInUseByProductUseCase } from '../usecases/setColorsInUseByProduct';
import { loadPatternsUseCase } from '../usecases/loadPatterns';
import { saveLogoDPIUseCase, SaveLogoDPIUseCaseOptions } from '../usecases/saveLogoDPI';

export interface GuiReducerState {
  activeCustomizeTab: CustomizeTab;
  activeFillTarget: any; //give proper interface
  activeFillTargetBrush: Cx.Brush,
  activeFillTargetBrushIsPattern: boolean;
  activeFillTargetBrushIsRasterPattern: boolean;
  activeJerseySchemmeColorIndex: Number;
  activeGrayGood: GrayGood;
  activeGrayGoodDesignStyles: DesignStyle[];
  activeGrayGoodPageNum: Number;
  activeGrayGoodPageTotal: Number;
  activeGrayGoodDesignStylesInPage: DesignStyle[];
  activeStep: GuiSteps;
  activeSport: Sport;
  activeSportGrayGoods: GrayGood[];
  inUseStyles: any[];
  allDesignStyles: DesignStyle[];
  allGrayGoods: GrayGood[];
  allSports: Sport[];
  loadingOrder: boolean,
  mainColorSelectorHidden: boolean;
  partnerData: any;
  partnerColorScheme: string[];
  partnerLogoUrl: string;
  productLogo: any;
  partnerLogoDocId: string;
  ready: boolean;
  teamColors: TeamColor[];
  // pattern combo GUI visibility flags
  mainColorTabSelectedOption: number;
  patternsComboVisible: boolean;
  // selected pattern
  activePattern: Pattern;
  grayGoodsThumb3DCache: string[];
  showEditColorOptions: boolean;
  isPatternSelectable: boolean;
  isBatchMode: boolean;
  initialOrder: string;
  initialOrderOpenAsCopy: string;
  coachContactInfo: coachContactInfo[];
  coachContactInfoSelected: coachContactInfo;
  activeDecorationAreaPanelId: any;
  colorSetIndex: number;
  colorsInUseByProduct: any;
  patternsList: any;
  productLogoDALocations: any[];
  productLogos: SaveLogoDPIUseCaseOptions[];
  currentDpiNumber: any;
};

const initState = {
  activeCustomizeTab: CustomizeTab.COLORZONES,
  activeFillTarget: undefined,
  activeFillTargetBrush: undefined,
  activeFillTargetBrushDocId: undefined,
  activeFillTargetBrushIsPattern: false,
  activeFillTargetBrushIsRasterPattern: false,
  activeGrayGood: undefined,
  inUseStyles: [],
  activeGrayGoodDesignStyles: undefined,
  activeGrayGoodDesignStylesInPage: undefined,
  activeGrayGoodPageNum: 1,
  activeGrayGoodPageTotal: undefined,
  activeJerseySchemmeColorIndex: 0,
  activePattern: emptyPattern(),
  activeSport: undefined,
  activeSportGrayGoods: undefined,
  activeStep: GuiSteps.SPORT,
  allDesignStyles: [],
  allGrayGoods: [],
  allSports: [],
  grayGoodsThumb3DCache: [],
  loadingOrder: false,
  mainColorSelectorHidden: true,
  partnerData: undefined,
  partnerColorScheme: undefined,
  partnerLogoDocId: undefined,
  partnerLogoUrl: undefined,
  mainColorTabSelectedOption: 2, //pattern type
  patternsComboVisible: false,
  productLogo: undefined,
  ready: false,
  showEditColorOptions: false,
  teamColors: undefined,
  isPatternSelectable: true,
  isBatchMode: false,
  initialOrder: undefined,
  initialOrderOpenAsCopy: undefined,
  coachContactInfo: [],
  coachContactInfoSelected: undefined,
  activeDecorationAreaPanelId: undefined,
  colorSetIndex: -1,
  colorsInUseByProduct: undefined,
  patternsList: [],
  productLogoDALocations: [],
  productLogos: [],
  currentDpiNumber: undefined
};

export function GuiReducer(state: GuiReducerState = initState, actionResult): GuiReducerState {
  switch (actionResult.type) {

    case setInitialOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      if (actionResult.success()) {

        return Object.assign({}, state, {
          initialOrder: actionResult.data.orderId,
          initialOrderOpenAsCopy: actionResult.data.initialOrderOpenAsCopy
        });
      }

      return state;

    case selectCoachContactInfoUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachContactInfoSelected: actionResult.data.coachContactInfo
        });
      }
      return state;

    // case getCoachDataReadByKeyUseCase.type:
    //   if (actionResult.failure()) {
    //     reportMessage(actionResult.type, actionResult.rejectionReason)
    //   }

    //   if (actionResult.success()) {
    //     return Object.assign({}, state, {
    //       coachContactInfo: actionResult.data.coachData?.CoachContactInfo ?? []
    //     });
    //   }
    //   return state;

    case setBatchModeUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          isBatchMode: true
        });
      }

      return state;

    case initPartnerWithColorsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const partnerData = actionResult.data.partnerData;

        partnerData.Partner[0].addressOptions = parseAddressOptions(actionResult.data.partnerData.Partner[0]);

        return Object.assign({}, state, {
          allDesignStyles: partnerData.PartnerDesignStyle.map((item) => {
            return designStyleFromActionResult(item);
          }),
          allGrayGoods: partnerData.PartnerGrayGood.map((item) => {
            return grayGoodFromActionResult(item);
          }),
          allSports: partnerData.PartnerSport.map((item) => {
            return sportFromActionResult(item);
          }),
          partnerData: partnerData.Partner[0],
          partnerColorScheme: partnerData.PartnerBranding[0].colorScheme,
          partnerLogoUrl: getBrandingLogoUrl(partnerData),
          productLogo: getProductLogo(partnerData),
          productLogoDALocations: getProductLogoDALocationsPerSport(partnerData.PartnerBranding[0]),
          ready: true,
          teamColors: getTeamColors(partnerData)
        });
      }

      return state;

    case loadSubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const product = actionResult.data.product;
        const grayGood = product.GrayGood;
        const sportId = grayGood.SportId;
        const sport = state.allSports.find((s) => {
          return s.SportId === sportId;
        });

        const activeStep = getActiveStep(actionResult.data);
        const newState = Object.assign({}, state, {
          activeFillTarget: actionResult.data.fillTarget,
          activeStep,
          mainColorSelectorHidden: (activeStep === GuiSteps.ROSTER || activeStep === GuiSteps.REVIEW) ? true : false,
          isPatternSelectable: true,
          loadingOrder: false
        });

        updateStateOnSportchange(newState, sport);
        updateStateOnGrayGoodchange(newState, grayGood);
        return newState;
      }

      return state;

    case setColorTypeMainColorSelectorUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          mainColorTabSelectedOption: actionResult.data.mainColorTabSelectedOption
        });
      }

      return state;

    case getUniqueDocumentBrushesUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          inUseStyles: actionResult.data
        });
      }

      return state;

    case showSportsStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.SPORT,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case showGrayGoodsStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.PRODUCT,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case showDesignStylesStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.STYLE,
          mainColorSelectorHidden: false,
          isPatternSelectable: false
        });
      }

      return state;

    case showCustomizeStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.CUSTOMIZE,
          mainColorSelectorHidden: false,
          isPatternSelectable: true
        });
      }

      return state;

    case showColorZonesTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.COLORZONES,
          mainColorSelectorHidden: false
        });
      }

      return state;

    case showGraphicsTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.GRAPHICS,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case showNameNumberTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.NAME_NUMBER,
          mainColorSelectorHidden: false
        });
      }

      return state;

    case showAddRosterStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.ROSTER,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case setSportUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const newState = Object.assign({}, state, {
          activeStep: GuiSteps.PRODUCT,
          mainColorSelectorHidden: true
        });

        updateStateOnSportchange(newState, actionResult.data.sport);
        return newState;
      }

      return state;

    case setGrayGoodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const grayGood = actionResult.data.grayGood;
        const newState = Object.assign({}, state, {
          activeStep: GuiSteps.STYLE,
          mainColorSelectorHidden: false,
          isPatternSelectable: false
        });

        updateStateOnGrayGoodchange(newState, grayGood)
        return newState;
      }

      return state;

    case setTeamColorUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const argb = actionResult.data.color.ARGB;
        const newState = Object.assign({}, state, {
          teamColors: [].concat(state.teamColors)
        });

        newState.teamColors[actionResult.data.index] = {
          Alpha: parseInt(argb.substr(0, 2), 16),
          Hex: argb.substring(2)
        };

        return newState;
      }

      return state;

    case addProductUseCase.type:
    case editProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeFillTarget: actionResult.data.fillTarget,
          activeStep: GuiSteps.CUSTOMIZE,
          mainColorSelectorHidden: false,
          isPatternSelectable: true
        });
      }

      return state;

    case editRosterUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.ROSTER,
          mainColorSelectorHidden: true
        });
      }

      return state;

    //case showReviewStepUseCase.type:
    case prepareOrderForSavingUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.REVIEW,
          mainColorSelectorHidden: true
        });
      }

      return state;

    // case toggleDecorationAreaHiddenUseCase.type:
    //   if (actionResult.success()) {
    //     return Object.assign({}, state, {
    //       playerNameDisabled: !state.playerNameDisabled
    //     });
    //   }

    //   return state;

    case removeOutlineUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.isActiveFillTarget) {
        return Object.assign({}, state, {
          activeFillTarget: undefined,
          activeFillTargetBrush: undefined,
          activeFillTargetBrushIsPattern: false
        });
      }

      return state;

    // case incrementTextHeightUseCase.type:
    //   if (actionResult.failure()) {
    //     reportMessage(actionResult.type, actionResult.rejectionReason)
    //   }

    //   if (actionResult.success()) {
    //     const activeFillTarget = Object.assign(state.activeFillTarget, { })  ;


    //     return Object.assign({}, state, {
    //       activeFillTarget
    //     });
    //   }

    //   return state;
    case setEffectUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement,
          { effect: actionResult.data.effect });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;
    case setFontUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement, { fontFamily: actionResult.data.fontName, fontId: actionResult.data.fontId });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;

    case setTextUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement,
          { text: actionResult.data.text });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;
    case setFontUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement, { fontFamily: actionResult.data.fontName, fontId: actionResult.data.fontId });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;

    case incrementTextHeightUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement,
          { fontSize: state.activeFillTarget.textDesignElement.fontSize + Units.toDocument(actionResult.data.increment) });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;

    case incrementTextSpacingUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const activeFillTargetTextDesignElement = Object.assign(state.activeFillTarget.textDesignElement,
          { spacing: state.activeFillTarget.textDesignElement.spacing + actionResult.data.increment });
        const activeFillTarget = Object.assign(state.activeFillTarget, { textDesignElement: activeFillTargetTextDesignElement })

        return Object.assign({}, state, {
          activeFillTarget
        });
      }

      return state;

    case addOutlineUseCase.type:
    case setFillTargetUseCase.type:
    case setFillTargetByClickUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const fillTarget = actionResult.data.fillTarget;
        const activeFillTarget = fillTarget ? cloneFillTarget(fillTarget) : undefined;
        const activeFillTargetBrush = activeFillTarget ? getFillTargetBrush(activeFillTarget) : undefined;
        const targetIsPattern = isPatternBrush(activeFillTargetBrush);
        const targetIsRasterPattern = isRasterPatternBrush(activeFillTargetBrush);
        const activePattern = !targetIsPattern ? emptyPattern() : activePatternFromBrush(activeFillTargetBrush, getPatterns(state));
        const isPatternSelectable = !fillTargetIsOutline(actionResult.data.fillTarget)

        return Object.assign({}, state, {
          activePattern,
          activeFillTarget,
          activeFillTargetBrush,
          activeFillTargetBrushIsPattern: targetIsPattern,
          activeFillTargetBrushIsRasterPattern: targetIsRasterPattern,
          patternsComboVisible: targetIsPattern,
          patternComboSelectorVisible: targetIsPattern,
          isPatternSelectable
        });
      }

      return state;

    case setFillUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const data = actionResult.data;
        const isPattern = isPatternBrush(data.brush);
        const isRasterPattern = isRasterPatternBrush(data.brush);
        // const target = cloneFillTarget(data.fillTarget);

        // setFillTargetBrush(target, data.brush);
        const target = {
          activeFillTargetBrush: data.brush,
          activeFillTargetBrushIsPattern: isPattern,
          activeFillTargetBrushIsRasterPattern: isRasterPattern
        }

        if (isPattern) {
          Object.assign(target, {
            activePattern:
            {
              docId: data.docId ? data.docId : state.activePattern.docId,
              colorZones: data.zones ? data.zones : state.activePattern.colorZones,
              activeColors: !isRasterPattern ? GetSpotColorBasesWithMap(data.brush.colorMap.getColors(), data.colorZoneMap) : [],
              colorIndexZoneMap: data.colorZoneMap ? data.colorZoneMap : state.activePattern.colorIndexZoneMap,
              activeColorSetIndex: -1
            }
          });
        }
        return Object.assign({}, state, target);
      }

      return state;

    case setSelectedPatternColorIndexUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activePattern:
          {
            docId: actionResult.docId ? actionResult.docId : state.activePattern.docId,
            colorZones: actionResult.zones ? actionResult.zones : state.activePattern.colorZones,
            activeColors: state.activePattern.activeColors,
            activeColorSetIndex: actionResult.data.selectedPatternColorIndex,
            colorSetIndex: actionResult.data.selectedPatternColorIndex,
            colorIndexZoneMap: state.activePattern.colorIndexZoneMap
          }
        });
      }
      return state;


    case setJerseySchemmeColorListIndexUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          activeJerseySchemmeColorIndex: actionResult.data.jerseySchemmeColorListIndex // getFillTargetBrush(target)
        });
      }

      return state;

    case setPatternComboVisiblityUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          patternsComboVisible: actionResult.data.patternsComboVisible // getFillTargetBrush(target)
        });
      }

      return state;

    case grayGoodPageNumDecrementUseCase.type:
    case grayGoodPageNumIncrementUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        updateStateOnGrayGoodPageChange(state, actionResult.data.activeGrayGoodPageNum);
        return state;
      }

      return state;

    // case grayGoodGetThumb3DUseCase.type:
    //   if (actionResult.success()) {

    //     return {
    //       ...state,
    //       grayGoodsThumb3DCache: {
    //         ...state.grayGoodsThumb3DCache, [actionResult.data.thumb3DKey]: actionResult.data.thumb3DValue
    //       }
    //     }
    //   }
    //   return state;

    case showEditColorOptionsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          showEditColorOptions: true
        });
      }

      return state;

    case isLoadingOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          loadingOrder: true
        });
      }

      return state;


    case setActiveDecorationAreaPanelUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeDecorationAreaPanelId: actionResult.data.activeId
        });
      }

      return state;

    case editTextRadioCheckUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          mainColorSelectorHidden: false
        });
      }

      return state;

    case editLogoRadioCheckUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          mainColorSelectorHidden: true
        });
      }

      return state;

    case getColorsInUseByProductUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          colorsInUseByProduct: actionResult.data
        });
      }
      return state;

    case loadPatternsUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          patternsList: actionResult.data
        });
      }
      return state;

    case saveLogoDPIUseCase.type:
      if (actionResult.success()) {
        let logosData = state.productLogos || [];
        const obj: any = {};
        obj[actionResult.data.itemNumber] = {
          dpi: actionResult.data.dpi,
          name: actionResult.data.name,
          decorationAreaId: actionResult.data.decorationAreaId
        }

        if (logosData.length > 0 && actionResult.data.decorationAreaId) {
          const newArr = logosData.filter((logo) => {
            const key = Object.keys(logo)
            return ((key[0] === actionResult.data.itemNumber) && (logo[key[0]].decorationAreaId !== obj[actionResult.data.itemNumber].decorationAreaId))
              || (key[0] !== actionResult.data.itemNumber);
          });

          if (newArr?.length !== logosData?.length) {
            newArr.push(obj);
            logosData = newArr;
          } else {
            logosData.push(obj)
          }
        }
        else {
          if (actionResult.data.decorationAreaId)
            logosData.push(obj);
        }

        return Object.assign({}, state, {
          productLogos: logosData,
          currentDpiNumber: actionResult.data.dpi
        });
      }

      return state;

  }

  return state;
}

// I could subscribe to emitCanvasEventUseCase to pull namedColorZones to Gui state but it will be more performant to lookup in the target canvas directly
// function getFillTargetfromZone(target:any) {
//   const t = target as ColorZoneFillTarget;
//   let fill ;
//   if (t)
//   {
//     let activeColors = getNamedColorZones(target.canvasId, true) ;
//     activeColors = activeColors.filter( (z) =>  { return z.Name === target.colorZoneId });
//     fill = activeColors ? activeColors[0].Value : null ;
//   }
//   return fill;
// }

function updateStateOnSportchange(state, sport) {
  Object.assign(state, {
    activeSport: sport,
    activeSportGrayGoods: getGrayGoods(state, sport.SportId)
  });
}

function updateStateOnGrayGoodchange(state, grayGood) {
  const designStyles = getDesignStyles(state, grayGood.Id);
  const pageTotal = Math.ceil(designStyles.length / global._3D_THUMBS_PER_PAGE_);
  Object.assign(state, {
    activeGrayGood: grayGood,
    activeGrayGoodDesignStyles: designStyles,
    activeGrayGoodPageNum: 1,
    // activeGrayGoodDesignStylesInPage: getDesignStylesInPage(designStyles, 1, pageTotal),
    activeGrayGoodPageTotal: pageTotal
  });
}

function updateStateOnGrayGoodPageChange(state, pageNum) {
  Object.assign(state, {
    activeGrayGoodPageNum: pageNum
    // activeGrayGoodDesignStylesInPage: getDesignStylesInPage(state.activeGrayGoodDesignStyles, pageNum, state.activeGrayGoodPageTotal)
  });
}

function getPatterns(state) {
  const patterns = state.patternsList?.patterns;
  return patterns;
}

function getDesignStylesInPage(designStyles, pageNum, totalNum) {
  const start = pageNum - 1;
  return designStyles.slice(start, Math.min(start + global._3D_THUMBS_PER_PAGE_, totalNum));
}

function getDesignStyles(state, grayGoodId: string) {
  const data = state.allDesignStyles;

  return data.filter(function (item) {
    return (item.PartnerGrayGoodId === grayGoodId && item.DocId != null)
  });
}

function getGrayGoods(state, sportId: string) {
  const data = state.allGrayGoods;

  return data.filter(function (item) {
    return item.SportId === sportId;
  });
}

function getTeamColors(data): TeamColor[] {
  const partnerId = data.Partner[0].id;
  const branding = data.PartnerBranding.find(function (item) {
    return item.partnerId === partnerId;
  });
  const colors = branding.colorScheme || {};
  const teamColors: TeamColor[] = [];
  let key;

  for (key in colors) {
    teamColors.push({
      Alpha: 255,
      Hex: colors[key]
    });
  }

  return teamColors;
}

function getActiveStep(data: any) {
  if (!data.editDesign && !data.editRoster) {
    return GuiSteps.REVIEW;
  } else {
    return data.editDesign ? GuiSteps.CUSTOMIZE : GuiSteps.ROSTER;
  }
}

function parseAddressOptions(partnerData) {
  const defaultAddress = {
    "name": partnerData.defaultAddressName || partnerData.address1
    , "address1": partnerData.address1
    , "address2": partnerData.address2
    , "postalCode": partnerData.postalCode
    , "city": partnerData.city
    , "state": partnerData.state
    , "salesTaxType": partnerData.salesTaxType
  };

  if (!partnerData.addressOptions || partnerData.addressOptions.length == 0)
    return [defaultAddress]
  else {
    const allAddressOptions = partnerData.addressOptions.filter(a => a.frontEndVisible || a.frontEndVisible == undefined);
    if (allAddressOptions.length == 0)
      return [defaultAddress]
    else {
      return partnerData.defaultAddressFrontEndVisible ? [defaultAddress, ...allAddressOptions] : allAddressOptions;
    }
  }
}

const getBrandingLogoUrl = (config: any) => {
  if (config && config.PartnerBranding && config.PartnerBranding.length > 0) {
    return config.PartnerBranding[0].webLogoUrl
  }

  return undefined;
};

const getProductLogo = (config: any) => {
  if (config && config.PartnerBranding && config.PartnerBranding.length > 0) {
    return config.PartnerBranding[0].productLogo
  }

  return undefined;
};

const getProductLogoDALocationsPerSport = (config: any) => {
  if (config && config.productLogoDALocations && config.productLogoDALocations?.length > 0) {
    const locations = config.productLogoDALocations;
    const flattenedArray = locations.flat();

    // Group by sport as key
    const groupedArray = flattenedArray.reduce((acc, curr) => {
      if (!acc[curr["name"]]) {
        acc[curr["name"]] = [];
      }
      if (curr.checked)
        acc[curr["name"]].push(curr.location);
      return acc;
    }, {});

    return groupedArray;
  }

  return undefined;
};