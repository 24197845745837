import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setGrayGood } from '../../../app/initApp';
import { uniqueId } from 'lodash';

// Styles
import './GrayGoods.css';

declare let Cx: any;

interface Props {
  hidden: boolean
  grayGoods: any
}

const GrayGoods = (props: Props) => {
  let className = 'gray-goods flex-one';
  const hidden = props.hidden;
  const grayGoods = props.grayGoods;
  const count = grayGoods.length;

  if (hidden) {
    className += ' hidden';
  }

  // Filters
  const [genderOptions, setGenderOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [filters, setFilters] = useState({
    age: [],
    gender: [],
    productType: [],
  });

  useEffect(() => {
    const uniqueKeywords = Array.from(new Set(grayGoods.flatMap(getKeywords)));
    const uniqueGenders = uniqueKeywords.filter(
      (keyword) => keyword === "WOMEN'S" || keyword === "MEN'S / UNISEX" || keyword === "GIRLS"
    );

    const uniqueAges = uniqueKeywords.filter(
      (keyword) => keyword === "ADULT" || keyword === "YOUTH"
    );

    const uniqueProductTypes = uniqueKeywords.filter(
      (keyword) =>
        keyword === "JERSEYS" || keyword === "SHORTS" || keyword === "SOCKS"
    );

    setAgeOptions(uniqueAges);
    setGenderOptions(uniqueGenders);
    setProductTypeOptions(uniqueProductTypes);
  }, [grayGoods]);

  const getKeywords = (product) => {
    const productName = product.Name.toUpperCase();
    let genderKeyword = "MEN'S / UNISEX";
    let ageKeyword = "ADULT";

    if (productName.includes("WOMEN'S") || productName.includes("GIRLS")) {
      genderKeyword = "WOMEN'S";
    }

    if (productName.includes("YOUTH")) {
      genderKeyword = "YOUTH";
    }

    const productTypeKeyword = productName.includes("SOCKS")
      ? "SOCKS"
      : productName.includes("SHORTS")
        ? "SHORTS"
        : "JERSEYS";

    return [ageKeyword, genderKeyword, productTypeKeyword];
  };

  // Update filters state
  const handleFilterChange = (name, type, checked) => {
    const value = name;
    if (checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [type]: [...prevFilters[type], value],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [type]: prevFilters[type].filter((item) => item !== value),
      }));
    }
  };

  // Filter products based on selected filters
  const filterProducts = (product) => {
    const productName = product.Name.toUpperCase();
    const isGenderMatch =
      filters.gender.length === 0 ||
      filters.gender.some((gender) =>
        gender === "WOMEN'S"
          ? (productName.includes("WOMEN'S") || productName.includes("GIRLS"))
          : gender === "MEN'S / UNISEX"
            ? (!productName.includes("WOMEN'S") && !productName.includes("GIRLS")) 
            : !productName.includes("WOMEN'S") && !productName.includes("YOUTH") && !productName.includes("GIRLS")
      );

    const isAgeMatch =
      filters.age.length === 0 ||
      filters.age.some((age) =>
        age === "YOUTH"
          // SOCKS should be show in both Youth and Adult. Socks are only one size
          ? (productName.includes("YOUTH") || productName.includes("SOCKS"))
          : age === "ADULT"
            ? (!productName.includes("YOUTH") || productName.includes("SOCKS"))
            : !productName.includes("ADULT") && !productName.includes("YOUTH")
      );

    const isProductTypeMatch =
      filters.productType.length === 0 ||
      filters.productType.includes(
        productName.includes("SOCKS")
          ? "SOCKS"
          : productName.includes("SHORTS")
            ? "SHORTS"
            : "JERSEYS"
      );

    return isGenderMatch && isAgeMatch && isProductTypeMatch;
  };

  const filteredProducts = grayGoods?.filter(filterProducts);

  // Section: render thumbnail once scrolled into view
  const rootRef = createRef<HTMLDivElement>();
  const itemRefs = grayGoods.reduce((acc: any, value: any) => {
    // @ts-ignore
    acc[value.Name] = {
      id: value.Name,
      ref: React.createRef(),
      urlRendered: false
    };

    return acc;
  }, {});

  const callback = (entries: any) => {
    // @ts-ignore
    entries.forEach((entry: any) => {
      const dom = entry.target;
      const name = dom.id;
      const thumb = itemRefs[name];

      if (entry.intersectionRatio > 0.5 && !thumb.rendered) {
        const foundDesign = grayGoods.find((f) => f.Name === name);

        if (foundDesign) {
          dom.firstChild.style.backgroundImage = 'url(' + getDocThumb(foundDesign.DocId) + ')';
          thumb.rendered = true;
        }
      }
    });
  };

  const observer = new IntersectionObserver(callback, {
    root: rootRef.current,
    threshold: new Array(101).fill(0).map((v, i) => i * 0.01)
  });

  useEffect(() => {
    Object.values(itemRefs).forEach((value: any) => {
      if (value.ref.current) {
        observer.observe(value.ref.current);
      }
    });
  }, [grayGoods, itemRefs]);

  function getTotalProducts() {
    if (filteredProducts?.length > 0 && grayGoods?.length > 0) {
      if (filteredProducts.length === grayGoods.length) {
        return `SHOWING ALL PRODUCTS (${grayGoods.length})`;
      } else {
        return `SHOWING (${filteredProducts.length}) ${filteredProducts.length > 1 ? "PRODUCTS" : "PRODUCT"}`;
      }
    } else {
      return "(0) PRODUCT";
    }
  };

  if (count > 0) {
    return (
      <div className={className}>
        <div className="graygoods-container">
          <div className="filters-column">
            <div className='edit-options'>
              <div className="header2Text partner-color2">
                Sort and Filter
              </div>
              <div className="filters-container">
                <span className="total-products partner-color1" >
                  {getTotalProducts()}
                </span>
                {/* by Product Type */}
                <h3>PRODUCT TYPE:</h3>
                {productTypeOptions.map((type) => (
                  <div className="title partner-color2" key={type}>
                    <div
                      className={
                        "titleCheckmark" +
                        (filters.productType.includes(type) ? " fas fa-check-square" : " far fa-square")
                      }
                      onClick={() => handleFilterChange(type, "productType", !filters.productType.includes(type))}
                    ></div>
                    <div className="titleText">{type}</div>
                  </div>
                ))}

                <div className='divider' />

                {/* by Gender */}
                {genderOptions.map((gender) => (
                  <div className="title partner-color2" key={gender}>
                    <div
                      className={
                        "titleCheckmark" +
                        (filters.gender.includes(gender) ? " fas fa-check-square" : " far fa-square")
                      }
                      onClick={() => handleFilterChange(gender, "gender", !filters.gender.includes(gender))}
                    ></div>
                    <div className="titleText">{gender}</div>
                  </div>
                ))}

                <div className='divider' />

                {/* by Age */}
                {ageOptions.map((age) => (
                  <div className="title partner-color2" key={age}>
                    <div
                      className={
                        "titleCheckmark" +
                        (filters.age.includes(age) ? " fas fa-check-square" : " far fa-square")
                      }
                      onClick={() => handleFilterChange(age, "age", !filters.age.includes(age))}
                    ></div>
                    <div className="titleText">{age}</div>
                  </div>
                ))}

              </div>
            </div>
          </div>
          <div className='graygoods-column'>
            <div className="list" ref={rootRef}>
              {filteredProducts.map(function (item: any) {
                const name = item.Name;
                return <div
                  className="item"
                  id={name}
                  key={uniqueId()}
                  onClick={() => { onItemClick(item) }}
                  // @ts-ignore
                  ref={itemRefs[name].ref}
                  title={name}>
                  <div className="thumb" />
                  <div className="name">{name}</div>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className={className}>
      <div className="no-items-message">
        Coming soon...
      </div>
    </div>
  }

  function onItemClick(item) {
    setGrayGood({
      grayGood: item
    });
  }
}

const defaultThumbSize = 300;
function getDocThumb(id) {
  const thumbSize = defaultThumbSize;
  const requestModel = {
    Doc: { Id: id },
    Format: 'png',
    Height: thumbSize,
    Width: thumbSize
  };

  return Cx.Core.Doc3D.thumbnailUrl(requestModel);
}

const mapStateToProps = (state) => {
  const grayGoods = state.GUI.activeSportGrayGoods?.sort((a, b) => a.Name.localeCompare(b.Name)) || [];

  // Hack
  // grayGoods = grayGoods.concat(grayGoods);
  // grayGoods = grayGoods.concat(grayGoods);

  return {
    grayGoods
  };
}

export default connect(mapStateToProps, null)(GrayGoods);
