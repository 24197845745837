import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../app/Application';
import { DecorationArea, isMainLogoDecorationArea } from '../../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../../app/domain/Design/FillTarget';
import { getMainLogoAsTextDesignElement, isTextDesignElement } from '../../../../../app/domain/Design/TextDesignElement';
import { textFillTarget } from '../../../../../app/domain/Design/TextFillTarget';
import { isUserArtDesignElement } from '../../../../../app/domain/Design/UserArtDesignElement';
import { CustomizeTab } from '../../../../../app/domain/Gui/CustomizeTab';
import { bumpDown, bumpLeft, bumpRight, bumpUp, scaleDesignElement, setCanvasCameraPosition, updateDecorationArea, clearDecorationArea, uploadLogo, setFillTarget } from '../../../../../app/initApp';
import setEditLogoRadio from '../../../../../app/usecases/setEditLogoCheck';
import setEditTextRadio from '../../../../../app/usecases/setEditTextCheck';
import { setActiveDAPanelId } from '../../../../../app/usecases/setIndexDecorationAreaPanel';
import NameEditorFields from '../../../design/namenumber/NameEditorFields';
import PlusMinusField from '../../../misc/field/PlusMinusField';
import Radio from '../../../misc/field/Radio';
import DecorationAreaPanel from '../DecorationAreaPanel';
import BumpArrows from './BumpArrows';
import LogoSelector from './LogoSelector';
import { saveLogoDPIData } from '../../../../../app/usecases/saveLogoDPI';
import { GuiSteps } from '../../../../../app/domain/Gui/GuiSteps';

// Styles
import styles from './MainLogoEditor.module.css';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  coachKey?: string;
  collapsed?: boolean;
  decorationArea: DecorationArea;
  allFonts: any;
  logoIsLoading?: boolean;
  nameFonts: any[];
  onBumpLogoClick?: Function;
  onFileSelected?: Function;
  onLogoClick?: Function;
  onTextClick?: Function;
  onScaleClick?: Function;
  partnerKey: string;
  scaleFactor: number;
  hiddenFontsIds?: [];
  imageLink?: string;
  uploadedImgDpi?: number;
  activeCustomizeTab: string;
  activeDAIndex?: any;
  uploadedLogo?: any;
  activeStep?: string;
  currentDpiNumber?: any;
}

let textDesignElement;
let userArtDesignElement;

const MainLogoEditor = (props: Props) => {
  const [showText, setShowText] = useState(false);
  const canvasId = props.canvasId;
  const coachKey = props.coachKey;
  const decorationArea = props.decorationArea;
  const designElement = decorationArea.DesignElement;
  const editingLogo = isUserArtDesignElement(designElement);
  const editingText = isTextDesignElement(designElement);

  if (editingLogo) {
    userArtDesignElement = designElement;
  } else if (editingText) {
    textDesignElement = designElement;
  }

  useEffect(() => {
    if (props.activeCustomizeTab === CustomizeTab.GRAPHICS) {
      setActiveDAPanelId({ activeId: decorationArea.Id });
    }

  }, [props.activeCustomizeTab]);

  useEffect(() => {
    if (props.activeCustomizeTab === CustomizeTab.GRAPHICS
      && props.activeDAIndex === decorationArea.Id
      && showText
      && editingText) {
      setEditTextRadio();
    }
  }, [props.activeDAIndex]);

  useEffect(() => {
    if (props.uploadedImgDpi && props.activeStep === GuiSteps.CUSTOMIZE) {
      saveLogoDPIData(props.uploadedLogo)
    }
  }, [props.uploadedImgDpi]);

  useEffect(() => {
    if (showText && editingText && props.activeCustomizeTab === CustomizeTab.GRAPHICS) {
      setFillTarget({
        fillTarget: textFillTarget(textDesignElement)
      });
    }
  }, [editingText, showText]);

  return (
    <div className="main-logo-editor">
      <DecorationAreaPanel
        decorationArea={decorationArea}
        activeId={decorationArea.Id}
        activeFillTarget={props.activeFillTarget}>
        {/* Logo or Text? */}
        <div className={styles.logoOrText}>
          <Radio
            checked={!showText}
            className={styles.radioField}
            label="Logo"
            onClick={() => {
              setShowText(false);
              props.onLogoClick();
              setEditLogoRadio();
            }} />
          <div className={styles.logoOrTextOr}>Or</div>
          <Radio
            checked={showText}
            className={styles.radioField}
            label="Text"
            onClick={() => {
              setShowText(true);
              props.onTextClick();
              setEditTextRadio();
            }} />
        </div>
        {/* Logo */}
        <LogoSelector
          canvasId={canvasId}
          imageLink={props.imageLink}
          currentDpiNumber={props.currentDpiNumber}
          disabled={!(coachKey?.length > 0)}
          hidden={showText}
          loginRequiredBeforeUpload={coachKey ? false : true}
          logoIsLoading={props.logoIsLoading}
          onSelectClipartClick={() => {
            alert('Coming Soon');
          }}
          onRemove={() => {
            clearDecorationArea({ designElement: decorationArea.DesignElement, clearGroupDecorationAreas: false })
          }}
          onFileSelected={(file) => props.onFileSelected(file)} />
        <div className={showText ? styles.logoPlacementHidden : styles.logoPlacement}>
          <BumpArrows
            disabled={!editingLogo}
            onClick={(direction) => props.onBumpLogoClick(direction)} />
          <PlusMinusField
            disabled={!editingLogo}
            label="Scale"
            onMinusClick={() => {
              props.onScaleClick(0.95);
            }}
            onPlusClick={() => {
              props.onScaleClick(1.05);
            }}
            value={+(props.scaleFactor).toFixed(2)} />
        </div>
        {/* Text */}
        <div style={{ display: showText ? 'block' : 'none' }}>
          <NameEditorFields
            activeFillTarget={props.activeFillTarget}
            canvasId={canvasId}
            decorationArea={decorationArea}
            nameFonts={props.nameFonts}
            allFonts={props.allFonts}
            hiddenFontsIds={props.hiddenFontsIds} />
        </div>
      </DecorationAreaPanel>
    </div>
  );

}

const mapStateToProps = (state: RootState, ownProps) => {
  const decorationArea = ownProps.decorationArea;
  const uploading = state.App.uploadingLogo;
  const uploadingToAreaId = state.App.uploadingLogoToDecorationAreaId;
  const imageLink = decorationArea.DesignElement['fileLocation'];
  const uploadedImgDpi = decorationArea.DesignElement['dpi'];
  const uploadedLogo = {
    decorationAreaId: uploadingToAreaId,
    dpi: uploadedImgDpi,
    name: decorationArea.Label,
    itemNumber: state.Order.activeItemNumber
  }

  return {
    imageLink,
    uploadedImgDpi,
    scaleFactor: decorationArea.DesignElement.scale || 1,
    coachKey: state.Session.coachesKey,
    logoIsLoading: uploading && uploadingToAreaId === decorationArea.Id,
    partnerKey: state.Session.partnersKey,
    activeDAIndex: state.GUI.activeDecorationAreaPanelId,
    uploadedLogo,
    activeStep: state.GUI.activeStep,
    currentDpiNumber: state.GUI.currentDpiNumber
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {
    onBumpLogoClick: (direction: string) => {
      const options = {
        designElement: ownProps.decorationArea.DesignElement
      };

      switch (direction) {
        case 'down': bumpDown(options); break;
        case 'left': bumpLeft(options); break;
        case 'right': bumpRight(options); break;
        case 'up': bumpUp(options); break;
      }
    },
    onScaleClick: (factor: number) => {
      const options = {
        designElement: ownProps.decorationArea.DesignElement,
        factor: factor
      };
      scaleDesignElement(options);
    },
    onFileSelected: (file: File) => {
      //const designElement = userArtDesignElement || ownProps.decorationArea.DesignElement;
      const designElement = ownProps.decorationArea.DesignElement;
      uploadLogo({
        coachKey: stateProps.coachKey,
        designElement,
        dispatchStartData: { decorationAreaId: designElement.decorationAreaId },
        file,
        partnerKey: stateProps.partnerKey
      });
    },
    onLogoClick: () => {
      setCanvasCameraPosition({
        canvasId: ownProps.canvasId,
        positionToFront: true
      });

      if (userArtDesignElement) {
        updateDecorationArea({
          designElement: userArtDesignElement
        });
      }
    },
    onTextClick: () => {
      setCanvasCameraPosition({
        canvasId: ownProps.canvasId,
        positionToFront: true
      });

      updateDecorationArea({
        designElement: textDesignElement || getMainLogoAsTextDesignElement(ownProps.decorationArea.DesignElement)
      });
    }
  });
}

export default connect(mapStateToProps, null, mergeProps)(MainLogoEditor);
