import React, { useState } from 'react';
import styles from './LoginSignIn.module.css';
import { requestPhoneCode, RequestPhoneCodeOptions, requestForAccessByEmail, RequestForAccessByEmailOptions } from '../../../../app/initApp';
import { getLoginReturnUrl } from '../../../../gui/util/queryString'

interface Props {
  phone: string;
  email: string;
  token: string;
  partnerInfo: any;
}

export const SelectVerification = (props: Props) => {
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [verificationMsg, setVerificationMsg] = useState<string>("");

  const onPhoneVerifyClick = (phone: string) => {
    setVerificationMsg("code to phone");
    setIsVerifying(true);
    const options: RequestPhoneCodeOptions = { phone };
    requestPhoneCode(options);
  }

  const onEmailVerifyClick = (email: string, hash: string, partnerInfo: any) => {
    setVerificationMsg("link to email");
    setIsVerifying(true);
    const returnUrl = getLoginReturnUrl();
    const promptForEmailVerification = true;
    const options: RequestForAccessByEmailOptions = { email, hash, partnerInfo, returnUrl, promptForEmailVerification, dispatchStartData: { promptForEmailVerification } };
    requestForAccessByEmail(options);
  }

  return (
    <div className={isVerifying ? (styles.loginMain + " " + styles.noClick) : styles.loginMain}>
      <div className={styles.parentcontent}>
        <div className={styles.loginMainTitle + ' partner-color1'}>YOUR ACCOUNT HAS BEEN CREATED!</div>
        <div className={styles.loginTitle + ' partner-color2'}>HOW WOULD YOU LIKE TO SIGN IN?</div>
        <div>
          <i className={styles.verifyIcon + ' fas fa-sms'} onClick={() => { onPhoneVerifyClick(props.phone) }} ></i>
          <i className={styles.verifyIconRight + ' fas fa-envelope'} onClick={() => { onEmailVerifyClick(props.email, props.token, props.partnerInfo ) }} ></i>
        </div>
        <div>
          <i className={styles.verifyTextLeft}>Send Code to Phone</i>
          <i className={styles.verifyTextRight}>Send Link to Email</i>
        </div>
      </div>
      {
        isVerifying
          ?
          <div className={styles.verifyingMsg}>
            <i className='icon fas fa-circle-notch fa-spin'></i>
            <span> Sending {verificationMsg}</span>
          </div>
          :
          null
      }
    </div>
  )
}
