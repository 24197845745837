import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ColorZoneBrush } from '../../../app/domain/Design/ColorZone';
import ColorZoneChiclet from './ColorZoneChiclet';
import { setFillTarget } from '../../../app/initApp';
import { colorZoneFillTarget, fillTargetIsColorZone } from '../../../app/domain/Design/ColorZoneFillTarget';

// Styles
import './ColorZoneList.css';

interface Props {
  className?: string;
  target: string;
  colorZones?: ColorZoneBrush[];
  activeFillTargetBrush?: Cx.Brush;
  fillTarget?: any;
}

class ColorZoneList extends Component<Props> {

  public render() {
    let className = 'color-zone-list';
    const content = [];

    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    const onColorZoneClicked = (canvasId, colorZone) => {
      setFillTarget({
        fillTarget: colorZoneFillTarget(canvasId, colorZone)
      });
    }

    if ((this.props.colorZones) && (this.props.colorZones.length > 0)) {
      this.props.colorZones.forEach((colorZone) => {
        const selected = this.props.fillTarget &&
          fillTargetIsColorZone(this.props.fillTarget) &&
          this.props.fillTarget.canvasId === this.props.target &&
          this.props.fillTarget.colorZone.Name === colorZone.Name;

        content.push(<ColorZoneChiclet
          canvasId={this.props.target}
          name={colorZone.Name}
          brush={colorZone.Value}
          key={colorZone.Name}
          selected={selected}
          onColorZoneClicked={() => {
            onColorZoneClicked(this.props.target, colorZone);
          }}
        ></ColorZoneChiclet>);
      })
    }

    return (<div className={className}>
      {content}
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    fillTarget: state.GUI.activeFillTarget,
    colorZones: state.CanvasFullState.colorZoneBrushMap,
    activeFillTargetBrush: state.GUI.activeFillTargetBrush
  };
}

export default connect(mapStateToProps, null)(ColorZoneList)
