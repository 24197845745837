import { app } from '../../initApp';
import { getCoachSessionData$ } from '../actions/getCoachSessionData$';
import { verifyEmailHash$ } from '../actions/login'
import { UseCase } from '../usecase/UseCase';

export interface VerifyEmailHashUseCaseOptions {
  hash: string;
  email: string;
  partner: string;
}

export const verifyEmailHashUseCase: UseCase = {
  type: 'VERIFY_EMAIL_HASH',

  run: async ( options: VerifyEmailHashUseCaseOptions ) => {
    // TODO: Enter wrong hash to test 400 results
    const verifyEmailResult = await verifyEmailHash$(options.hash, options.email);

    if(verifyEmailResult && verifyEmailResult.sessionToken) {

      const coachSessionData = await getCoachSessionData$(verifyEmailResult.sessionToken, options.partner);

      if ( Cx.Storage.isAvailable() ) {
        Cx.Storage.set('ubdesigner2.sessionToken', verifyEmailResult.sessionToken);
        Cx.Storage.set('ubdesigner2.sessionCoach', coachSessionData.coach.coachesKey);
      }

      return coachSessionData;
    }
    else {
      return Promise.reject({
        errorMessage: verifyEmailResult.error
      });
    }
  }
};

export function verifyEmailHash( options: VerifyEmailHashUseCaseOptions ) {
  app.runUseCase( verifyEmailHashUseCase, options );
}
