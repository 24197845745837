import { Units } from '../../../canvas/helpers/units';
import { Outline } from '../../domain/Design/Outline';
import { getBlackColorBrush, TextDesignElement } from '../../domain/Design/TextDesignElement';
import { getContourThicknessForSize, getOutlineSum } from './font';
import { updateTextDesignElement$ } from './updateTextDesignElement$';

export function addOutline$ (designElement: TextDesignElement) {
  const outline: Outline = {
    Brush: getBlackColorBrush(),
    Offset: Units.toDocument(getContourThicknessForSize(Units.toUser(designElement.fontSize)))
  };

  designElement.outlines.push(outline);

  // use pre calculated (or config value) text separation
  if(!designElement.fontIsScript)
    designElement.spacing = +(Units.toUser(getOutlineSum(designElement))).toFixed(2) ;

  // update on figure
  return updateTextDesignElement$(designElement);
}