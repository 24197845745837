import React from 'react';
import { Component } from 'react';
import { syncCanvasSize } from '../../../app/initApp';
import { initCanvas, initCanvasUseCaseOptions } from '../../../app/usecases/initCanvas';
import { initCanvasInstance } from '../../../app/usecases/actions/canvas';
import * as ElementResizeDetector from '../../util/elementResizeDetector';

interface Props {
  elementId: string;
  className: string;
  backgroundColor: string;
  defaultCameraState: string;
  parentCallback: Function;
  isReady: boolean;
}

class Canvas extends Component<Props> {

  private canvasElement: HTMLElement;
  private elementResizeHandler: Function;
  private canvas: Cx.Canvas;

  constructor (props) {
    super(props);
  }

  public render () {
    return <div
      className={this.props.className}
      id={this.props.elementId}></div>
  }

  public componentDidMount () {
    this.initCanvasInstance();
    this.addResizeListener();
  }

  public componentWillUnmount(){
    this.removeResizeListener();
  }

  private initCanvasInstance () {
    const canvasId = this.props.elementId;

    this.canvas = initCanvasInstance(canvasId, true, null); // 3rd parameter is override any existing instance
    initCanvas({
      canvasId,
      inited: true
    } as initCanvasUseCaseOptions);
  }

  private addResizeListener () {
    const canvasId = this.props.elementId;

    this.canvasElement = document.getElementById(canvasId);
    this.elementResizeHandler = function() { syncCanvasSize({ canvasId: canvasId }) };
    ElementResizeDetector.addResizeListener(this.canvasElement, this.elementResizeHandler);
  }

  private removeResizeListener () {
    ElementResizeDetector.removeResizeListener(this.canvasElement, this.elementResizeHandler);
  }

}

export default Canvas;
