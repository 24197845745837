/* global Cx */

import { report } from "../util/rollbar";
import global from "../services/global"

export const getContainersFromLocalStorage = ( container, hash ) => {
  if ( Cx.Storage.isAvailable() ) {
    const key = 'ubdesigner2.' + container + '.' + hash;
    const time_stamp_key = key + '-timestamp';
    const time_stamp = Cx.Storage.getObject(time_stamp_key)
    const day_diff = Math.floor((Date.now() - time_stamp) / (1000*60*60*24))
    if (day_diff >= global._LOCAL_CACHE_EXPIRE_DAYS_)
      return null ;
    return Cx.Storage.getObject( key );
  }
};

export const saveContainersToLocalStorage = ( container, hash, data ) => {
  if ( Cx.Storage.isAvailable() ) {
    const key = 'ubdesigner2.' + container + '.' + hash;
    try {
      Cx.Storage.setObject( key, data );
      Cx.Storage.setObject( key + '-timestamp', Date.now() );
    } catch ( e ) {
      localStorage.clear();
      report(e);
    }
  }
};

export const saveAccessStart = (hash) => {
  saveContainersToLocalStorage('access', hash, 'pending');
}

export const saveAccessDelete = (hash) => {
  saveContainersToLocalStorage('access', hash, 'completed');
}

export const succeedAccessStart = (hash) => {
  saveContainersToLocalStorage('access', hash, 'success');
}

export const checkForAccess = (hash) => {
  let val = getContainersFromLocalStorage('access', hash)
  return val && val == 'success';
}

// export default class Storage {
//
//   public static Instance( cx = false ) {
//     if ( cx ) {
//       return Cx.Storage;
//     }
//     else {
//       // need more space than 5MBs
//
//       if ( 'storage' in navigator && 'estimate' in navigator.storage ) {
//
//       }
//       else if ( 'webkitTemporaryStorage' in navigator && 'queryUsageAndQuota' in navigator.webkitTemporaryStorage ) {
//
//       }
//
//       return {
//         setObject: function( key, val ) {
//
//         }
//         , getObject: function( key ) {
//
//         }
//       };
//     }
//   }
//
//   public static getContainersFromLocalStorage( container, hash ) {
//     if ( Cx.Storage.isAvailable() ) {
//       return Storage.Instance( true ).getObject( 'ubdesigner2.' + container + '.' + hash );
//     }
//   }
//
//   public static saveContainersToLocalStorage( container, hash, patternContainers ) {
//     if ( Cx.Storage.isAvailable() ) {
//       const key = 'ubdesigner2.' + container + '.' + hash;
//       try {
//         Storage.Instance( true ).setObject( key, patternContainers );
//         Storage.Instance( true ).setObject( key + '-timestamp', Date.now() );
//       } catch ( e ) {
//         localStorage.clear();
//       }
//     }
//   }
// }
