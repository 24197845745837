import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';
import { Pattern } from '../../../app/domain/Pattern/Pattern';
import uniqueKey from '../../util/uniqueKey';
import { MAIN_COLOR_TAB_OPTIONS } from '../steps/customize/MainColorTabOptions';
import ColorChiclet from './ColorChiclet';
import patternStyles from './PatternCombo.module.css';
import ThumbChiclet from './ThumbChiclet';

// Styles
import styles from './StylesCarousel.module.css';

interface Props {
  activeColorSpotName?: string;
  styleList: Cx.Color[] | Pattern[];
  styleConfig?: any;
  disabled?: boolean;
  hidden?: boolean;
  showPatternsCombo?: boolean;
  selectedOption?: number;
  inUseSelected?: boolean;
  activeStep?: string;
  isPatternSelectable?: boolean;
  //Functions 
  onColorClick?: Function;
  onPatternClick?: Function;
  onPatternColorClick?: Function;
}

const StylesCarousel = (props: Props) => {
  const className = props.disabled ?
    styles.colorsCarouselDisabled :
    (props.hidden ? styles.colorsCarouselHidden : styles.colorsCarousel);

  const patternComboClassname = props.hidden ? styles.patternSelectorHidden : styles.patternSelector;
  const appliedClassName = props.showPatternsCombo ? patternComboClassname : className;

  return <div className={appliedClassName}>
    <div className={styles.container}>
      <List
        activeColorSpotName={props.activeColorSpotName}
        disabled={props.disabled}
        styleList={props.styleList}
        patternComboActive={props.showPatternsCombo}
        onColorClick={props.onColorClick}
        onPatternClick={props.onPatternClick}
        onPatternColorClick={props.onPatternColorClick}
        selectedOption={props.selectedOption}
        inUseSelected={props.inUseSelected}
        activeStep={props.activeStep}
        isPatternSelectable={props.isPatternSelectable}
      />
    </div>
  </div>;
};

function sanitizeColorName(name) {
  return name?.replace('TS', '').trim();
}

const List = (props) => {
  const allColors = props.styleList;
  const pattern = props.inUseSelected && props.styleList;

  // All colors styles (solid and pattern carousels) This area is shown for SOLIDS and PATTERN options
  const allStyles = allColors && allColors.map((style, i) => {
    if (style instanceof Cx.Color) {
      const color = style;
      const selected = props.activeColorSpotName?.length > 0 && color.spotColorName == props.activeColorSpotName;
      const onColorClick = props.disabled ? () => { } : (props.patternComboActive ? props.onPatternColorClick : props.onColorClick);
      const name = props.inUseSelected ? color.spotColorName : color.name;
      const isReviewStep = props.activeStep === GuiSteps.REVIEW;
      const colorChicletCls = [(props.inUseSelected ? styles.inUseSolidsWrapper : ""), (isReviewStep ? styles.noClick : "")].join(' ');

      return <div
        className={colorChicletCls}
        key={uniqueKey()}>
        <ColorChiclet
          className={[styles.colorChiclet, isReviewStep && styles.reduced].join(' ')}
          index={i}
          key={uniqueKey()}
          color={color}
          onColorClick={onColorClick}
          selected={selected}
          title={sanitizeColorName(name)}
          isSmallThumb={false}
          activeStep={props.activeStep} />
        {props.inUseSelected && <span>{color.spotColorName && sanitizeColorName(color.spotColorName)}</span>}
      </div>
    } else {
      const docId = style.Config.publishedDocId;
      const name = style.Name && sanitizeColorName(style.Name);
      const selected = false;
      const isReviewStep = props.activeStep === GuiSteps.REVIEW;
      const inUseColorSet = props.selectedOption === MAIN_COLOR_TAB_OPTIONS.IN_USE || isReviewStep;

      return !props.inUseSelected && (<ThumbChiclet
        //metadata selectedColors, initialColorZones, initialColors
        index={i}
        className={[patternStyles.thumbChiclet, (isReviewStep ? (styles.noClick + " " + styles.reduced) : "")].join(' ')}
        config={style.Config.metadata}
        colorSet={inUseColorSet && style.metadata?.selectedColors}
        initialColors={inUseColorSet && style.metadata?.initialColors}
        initialZones={inUseColorSet && style.metadata?.initialColorZones}
        key={uniqueKey()}
        docId={docId}
        onColorClick={props.onPatternClick}
        selected={selected}
        title={name} />);
    }
  });

  // Pattern thumbs and colors in use (it'll only be shown if a pattner was selected and the IN USE radio button is checked)
  const patternDesign = pattern && pattern.map((style, i) => {
    if (!(style instanceof Cx.Color)) {
      const docId = style.Config.publishedDocId;
      const name = style.Name;
      const selected = false;
      const isReviewStep = props.activeStep === GuiSteps.REVIEW;
      const inUseColorSet = props.selectedOption === MAIN_COLOR_TAB_OPTIONS.IN_USE || props.selectedOption === MAIN_COLOR_TAB_OPTIONS.PATTERNS || isReviewStep;
      const patternColors = style.metadata?.selectedCxColors;

      return (<>
        <div className={styles.divider}
          key={uniqueKey()}>
        </div>
        <div className={styles.patternImg}>
          <ThumbChiclet
            //metadata selectedColors, initialColorZones, initialColors
            index={i}
            className={[patternStyles.thumbChiclet, (isReviewStep ? (styles.noClick + " " + styles.reduced) : "")].join(' ')}
            config={style.Config.metadata}
            colorSet={inUseColorSet && style.metadata?.selectedColors}
            initialColors={inUseColorSet && style.metadata?.initialColors}
            initialZones={inUseColorSet && style.metadata?.initialColorZones}
            key={uniqueKey()}
            docId={docId}
            onColorClick={props.onPatternClick}
            selected={selected}
            title={name}
            grayedOut={
              (props.inUseSelected && props.selectedOption == MAIN_COLOR_TAB_OPTIONS.PATTERNS) ||
              (props.inUseSelected && !props.isPatternSelectable)} />
          <span>{name}</span>
        </div>

        {
          patternColors?.map((pc: any) => {
            if (pc instanceof Cx.Color) {
              const color = pc;
              const selected = props.activeColorSpotName?.length > 0 && color.spotColorName == props.activeColorSpotName;
              const onColorClick = props.disabled ? () => { } : (props.patternComboActive ? props.onPatternColorClick : props.onColorClick);
              const name = props.inUseSelected ? color.spotColorName : color.name;
              const isReviewStep = props.activeStep === GuiSteps.REVIEW;
              const colorChicletCls =
                [styles.colorChiclet, ((props.inUseSelected && !isReviewStep) ? styles.patternInUseColorChiclet : styles.patternReduced)].join(' ');

              return <div
                className={[(props.inUseSelected ? styles.inUseWrapper : ""), (isReviewStep ? styles.noClick : "")].join(' ')}
                style={{ paddingLeft: isReviewStep ? "0" : "", paddingRight: isReviewStep ? "0" : "" }}
                key={uniqueKey()}>
                <ColorChiclet
                  className={colorChicletCls}
                  index={i}
                  key={uniqueKey()}
                  color={color}
                  onColorClick={onColorClick}
                  selected={selected}
                  title={sanitizeColorName(name)}
                  isSmallThumb={true}
                  activeStep={props.activeStep} />
                {props.inUseSelected && <span>{color.spotColorName && sanitizeColorName(color.spotColorName)}</span>}
              </div>
            } else { return null }
          })
        }
      </>);
    } else {
      return null;
    }
  });

  // Combine scenarios
  const merged = allStyles.concat(patternDesign);

  return merged;
}

const mapStateToProps = (state: RootState) => {
  const brush = state.GUI.activeFillTargetBrush;
  const isPattern = state.GUI.activeFillTargetBrushIsPattern;
  const selectedOption = state.GUI.mainColorTabSelectedOption;
  let activeColorSpotName = undefined;
  const activeStep = state.GUI.activeStep;
  const isPatternSelectable = state.GUI.isPatternSelectable

  if (brush)
    activeColorSpotName = !isPattern ? brush.color.spotColorName : state.GUI.activePattern.activeColors[state.GUI.activePattern.activeColorSetIndex]?.spotColorName;

  return { activeColorSpotName, selectedOption, activeStep, isPatternSelectable };
};

export default connect(mapStateToProps)(StylesCarousel);
