import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { poll } from '../../gui/util/poll';
import { setInitialCoach } from './setInitialCoachKey';

export interface pollForAccessStartUseCaseOptions {
  timeout: number,
  interval: number,
  partnersKey: string,
}

export interface pollForAccessStopUseCaseOptions {
  result: boolean
}

const stopCallback = (result: boolean) => {
  if (result) 
    setInitialCoach({ sessionData: result })
}

export const pollForAccessStartUseCase: UseCase = {
  type: 'POLL_FOR_ACCESS_START',

  run: (options: pollForAccessStartUseCaseOptions) => {
    return new Promise<void>((resolve, reject) => {
      let intervalId: NodeJS.Timeout;
      let hasCompleted = false;

      const pollingProcess = () => {
        poll(async (result: boolean) => {
          if (hasCompleted) return;

          if (result) {
            hasCompleted = true;
            clearInterval(intervalId);
            stopCallback(result);
            resolve();
          }
        }, options.timeout, options.interval, options.partnersKey);
      };


      intervalId = setInterval(pollingProcess, options.interval);

      setTimeout(() => {
        if (!hasCompleted) {
          clearInterval(intervalId);
          stopCallback(false);
        }
      }, options.timeout);
    });
  }
};

export const pollForAccessStopUseCase: UseCase = {
  type: 'POLL_FOR_ACCESS_STOP',

  run: (options: pollForAccessStopUseCaseOptions) => {
    return Cx.resolve({ pollingResult: options.result });
  }
};

/**
 * @category Use Case
 */
export function pollForAccessStart(options: pollForAccessStartUseCaseOptions) {
  app.runUseCase(pollForAccessStartUseCase, options);
}
