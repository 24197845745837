import { backendClient } from '../../gui/services/backend';
import { hasCoachesKey } from '../domain/Login/Conditions';
import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderStatesByCoachesKey$ } from './actions/getOrderStatesByCoachesKey$';
import { prepareOrderForSaving$ } from './actions/prepareOrderForSaving$';
import { UseCase } from './usecase/UseCase';
import { saveCanvasDocument$ } from './actions/canvas';
import global from '../../gui/services/global';

export interface SaveOrderUseCaseOptions {
  coachesKey: string;
  partnersKey: string;
  products: Product[];
  shippingMethod: any;
  contactInfo?: any;
  retailTotal?: number;
  costTotal?: number;
  cadworxSessionId?: string;
  salesTax: number;
  minOrderFee: number;
  activeItemNumber: string;
  productLogos: any[];
  deliveryMethod: string;
  shippingAddress: any;
}

export const saveOrderUseCase: UseCase = {
  type: 'SAVE_ORDER',

  checkConditions: (state) => {
    return Condition.evaluate(state, [hasCoachesKey]);
  },

  run: (options: SaveOrderUseCaseOptions) => {
    const coachesKey = options.coachesKey;

    return saveCanvasDocument$(global.__MAIN_CANVAS_ID__)
      .then((doc) => {

        const ix = options.products.findIndex(p => p.ItemNumber == options.activeItemNumber);
        options.products[ix].SavedDocId = doc.id;

        return prepareOrderForSaving$(options.products
          , null
          , options.shippingMethod
          , null
          , null
          , options.contactInfo
          , options.retailTotal
          , options.costTotal
          , options.salesTax
          , options.minOrderFee
          , options.productLogos
          , options.deliveryMethod
          , options.shippingAddress)
          .then((result) => {
            return backendClient.post<any>({
              endPoint: 'coaches',
              method: 'createOrderState',
              payload: {
                'coachesKey': coachesKey,
                'orderState': result.orderState,
                'orderStateSnippet': result.orderStateSnippet,
                'partnersKey': options.partnersKey
              }
            })
              .then(() => {
                return getOrderStatesByCoachesKey$(coachesKey, options.partnersKey)
                  .then((data) => {
                    return Cx.resolve({
                      savedOrders: data
                    });
                  });
              });
          });
      });
  }
};

/**
 * @category Can Use Case
 */
export function canSaveOrder(state): boolean {
  return saveOrderUseCase.checkConditions(state).isAllowed;
}

/**
 * @category Use Case
 */
export function saveOrder(options: SaveOrderUseCaseOptions) {
  app.runUseCase(saveOrderUseCase, options);
}
