import { getToken } from '../gui/services/backend';
import {apiPath, sendApiPostRequest, getFullUrl} from './backendApi';

interface RequestAccessByPhonePayload {
  phone: string
}

interface RequestAccessByEmailPayload {
  email: string,
  returnUrl: string,
  partnerInfo?: any,
  token?: string
}

export const requestAccessByPhone = async (payload: RequestAccessByPhonePayload) => {
  return await sendApiPostRequest(apiPath.coachRequestAccessByPhone, payload);
};

export const requestAccessByEmail = async (payload: RequestAccessByEmailPayload) => {
  return await sendApiPostRequest(apiPath.coachRequestAccessByEmail, payload);
};

interface VerifyPhoneCodePayload {
  phone: string,
  code: string
}

interface VerifyEmailHashPayLoad {
  email: string,
  hash: string
}

export const verifyPhoneCode = async (payload: VerifyPhoneCodePayload) => {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const fullPath = getFullUrl(apiPath.verifyPhoneCode);
  const resp = await fetch(fullPath, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    },
    method: 'POST'
  });

  return resp.json();
};

export const verifyEmailHash = async (payload: VerifyEmailHashPayLoad) => {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const fullPath = getFullUrl(apiPath.verifyEmailCode);
  const resp = await fetch(fullPath, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    },
    method: 'POST'
  });

  return resp.json();
};
