import { CanvasAreaFactory, CanvasConfig } from '../../../canvas/CanvasArea';
import { CanvasHelper } from '../../../canvas/helpers/canvas'
import global from '../../../gui/services/global';
import { ColorZoneBrush } from '../../domain/Design/ColorZone';
import { DecorationArea, isRosterDecorationAreaType } from '../../domain/Design/DecorationArea';
import { Outline } from '../../domain/Design/Outline';
import { toARGB } from '../../domain/Pattern/colorZoneHelper'
import { DECORATION_AREA_TYPES } from '../../domain/Design/DecorationArea';
import { Units } from '../../../canvas/helpers/units';
// Decoration Area Metadata And Use
// daLabel - The display label, the title of the editor in the Customize step.
// daId- Unique identifier and used to link a design element to a decoration area.
// daType - Not unique, currently used to determine what type of editor to show.
// daGroupId - For example, “Sleeve-Numbers” or “Event-Patch”
// daGroupLabel - For example, “Sleeve Number”, “Event Patch” etc.

const COLOR_ZONE_KEY = 'ubZoneName';
const DECORATION_AREA_KEY = 'daId';
const IN_DECORATION_AREA_KEY = 'inDecorationArea';
const DECORATION_AREA_LABEL_KEY = 'daLabel';
const DECORATION_AREA_TYPE_KEY = 'daType';
const DECORATION_AREA_GROUP_ID = 'daGroupId';
const DECORATION_AREA_GROUP_LABEL = 'daGroupLabel';

enum DesignEvents {
  COMMIT = 'commit',
  RENDER = 'render',
  USE_CASE_INVOKED_COMMIT = 'usecaseinvokedcommit',
  USE_CASE_INVOKED_RENDER = 'usecaseinvokedrender',
  COMMIT_STARTED = 'commitstarted',
  SELECTION_CHANGED = 'selectionchanged',
  DOUBLE_CLICK = 'dblclick'
}

export function saveCanvasDocument$(canvasId)
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const doc = canvas.doc ;
  setFigureInDecorationAreaHidden(doc.figures, DECORATION_AREA_TYPES.CA, false);
  return doc.save$();
}

export function updateCanvasColorZonesFromState$(canvasId, zones, colors)
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  return CanvasHelper.updateCanvasColorZones$(canvas, zones, colors);
}

export function replaceCanvasColorZone$(zone,color,spotColor,target)
{
  const canvas = CanvasAreaFactory.getCanvasById(target);
  const rgba = color.replace(/(..)(......)/, '$2$1')
  return CanvasHelper.replaceColorZoneColor(canvas, zone, rgba, spotColor ).then( (r) => {
    const newZone = CanvasHelper.selectUniqueZoneByColor(canvas, rgba, spotColor);
    return Promise.resolve({ zone: newZone, rgb: rgba});
  })
}

export function getUniqueBrushes(target:string)
{
  const canvas = CanvasAreaFactory.getCanvasById(target);
  return CanvasHelper.getUniqueDocumentBrushes(canvas);
}

export function getDecorationAreasBrushes(target:string, decorationAreas: any)
{
  const canvas = CanvasAreaFactory.getCanvasById(target);
  return CanvasHelper.getUniqueDecorationAreasBrushes(canvas, decorationAreas);
}

export function replaceZoneBrushByZoneId$(targetCanvas: string, zone:string, brush: Cx.Brush )
{
  const canvas = CanvasAreaFactory.getCanvasById(targetCanvas);
  return CanvasHelper.replaceBrushZoneColorByZoneId$(canvas, zone, brush).then ( (r) => {
    const newZone = CanvasHelper.selectUniqueZoneById(canvas, zone);
    return Promise.resolve({ zone: newZone, brush: brush});
  });
}

export function replaceZonePenByZoneId$(targetCanvas: string, zone:string, pen: Cx.Pen )
{
  const canvas = CanvasAreaFactory.getCanvasById(targetCanvas);
  return CanvasHelper.replacePenZoneColorByZoneId$(canvas, zone, pen).then ( (r) => {
    const newZone = CanvasHelper.selectUniqueZoneById(canvas, zone);
    return Promise.resolve({ zone: newZone, pen: pen});
  });
}

export function 
replaceCanvasColorZoneByZoneId$(zone: string, color: string, spotColor: string, target: string)
{
  const canvas = CanvasAreaFactory.getCanvasById(target);
  const rgba = color.replace(/(..)(......)/, '$2$1')
  return CanvasHelper.replaceColorZoneColorByZoneId$(canvas, zone, rgba, spotColor ).then( (r) => {
    const newZone = CanvasHelper.selectUniqueZoneByColor(canvas, rgba, spotColor);
    return Promise.resolve({ zone: newZone, rgb: rgba});
  })
}

export function initCanvasInstance(id:string, ovride: boolean, config: CanvasConfig)
{
  return CanvasAreaFactory.Canvas(id, ovride, config)
}

export function getCanvasById(id:string)
{
  return CanvasAreaFactory.getCanvasById(id);
}

function addPenContourExtraParams(p) {
  if ( p.lineJoin == 'Miter') {
    return Object.assign(p, { miterLimit: 3 });
  }
  else {
    return p ;
  }
}

function filterAndRemoveProcesses(arr, condition) {
  const indexesToRemove = [];

  // Find indexes of elements that meet the condition
  for (let i = 0; i < arr.length; i++) {
    if (condition(arr[i])) {
      indexesToRemove.push(i);
    }
  }

  // Remove elements from the array in reverse order to avoid index shifting
  for (let i = indexesToRemove.length - 1; i >= 0; i--) {
    arr.splice(indexesToRemove[i], 1);
  }

  return arr;
}

export function ContourToPenContour(doc)
{
  let contourPens = [];
  doc.figures.forEach((f)=>{
    contourPens = [];
    f.processes.forEach((p)=>{
      if ( p.type == "Contour" )
      {
        contourPens.push(new Cx.Pen(addPenContourExtraParams({ 
                    width: p.offset * 2
                    , color: p.brush.color
                    , lineJoin: p.lineJoin
        })));
      }
    })
    if (contourPens.length > 0 )
    {
      const penContour = new Cx.Process.PenContour({ pens: contourPens
        , advancedSpacing: true
      });
      const cond = (p) => p.type == 'Contour';
      const filteredProcesses = filterAndRemoveProcesses(f._processes, cond);
      filteredProcesses.push(penContour);
      f._processes = filteredProcesses ;
    }
  });
}

export function loadDocument$(canvasId: string, docId: string, sessionId: string)
{
  // return CanvasHelper.loadDocument$(
  //   canvasId
  //   , docId
  //   , cb);

  const prevSessionId = Cx.User.getSessionId();
  CanvasHelper.clear3DView(canvasId);

  // This can be moved back to CanvasLibTS later
  let canvas = CanvasAreaFactory.getCanvasById(canvasId);

  if (sessionId)
    Cx.User.setSession(sessionId, false, false);

  return Cx.Core.Document.read$({ id: docId })
  .then((docModel) => {
    if (sessionId)
      Cx.User.setSession(prevSessionId, false, false);
    
      // @ts-ignore
    const loadedDoc = Cx.Document.fromXmlString(docModel.Cdl);
    const canvas3d = <Cx.Canvas>canvas;

    ContourToPenContour(loadedDoc);
    //TODO: Analyze why it is necessary to use two different objects (loadedDoc and clonedDoc)
    const clonedDoc = Cx.cloneDeep(loadedDoc);

    let pageCount = loadedDoc.pages.length;

    if (pageCount > 1) {
      // Hack: As a go around since Cx.Document.fromXmlString() fails when we attempt to
      // submit an order.  The cause seems to be textblocks.
      // Without textblocks it fails gracefully (textblocks inside groups don't seem to count)
      for (; pageCount > 1; pageCount--) {
        loadedDoc.pages.pop();
      }
    }

    return canvas3d.showDocument$(loadedDoc)
    .then(() => {
      // TODO:
      // return canvas.ready$().then( () => {
        return canvas.object3D.ready$() .then( () => {
          // @ts-ignore
          return canvas.textureView.draw$() .then( () => {
            return Cx.resolve(clonedDoc);
          });
        // });
      });
    });
  });
}

export function getCdl(id: string): string
{
  const canvas = CanvasAreaFactory.getCanvasById(id );
  return canvas.document.toXmlString();
}

export function getColorZoneName(figure: Cx.Figure): string
{
  return figure.metadata.get(COLOR_ZONE_KEY);
}

export function getFigureInDefaultColorZone(canvasId: string)
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const figures = canvas.figures ; 
  let defaultZoneFigure = figures.filter( f => getColorZoneName(f)?.toUpperCase() === 'MAIN BODY');
  if (!defaultZoneFigure)
  {
    const namedColorZones = getNamedColorZones(canvasId).filter(f=>f);
    defaultZoneFigure = figures.filter( f => getColorZoneName(f)?.toUpperCase() === namedColorZones[0].Name );
  }
  return defaultZoneFigure[0] ;
}

export function getColorZoneFromFigure(figure: Cx.Figure, colorZones?: any, canvasId?: string): ColorZoneBrush {
  const zoneName = getColorZoneName(figure);

  if (canvasId && !colorZones) {
    const canvas = CanvasAreaFactory.getCanvasById(canvasId);
    colorZones = CanvasHelper.getUniqueDocumentZones(canvas);
  }

  if (zoneName && zoneName.length > 0) {
    // @ts-ignore
    const polyregions = figure.polyregions || [];
    const len = polyregions.length;
    let i = 0;

    for (; i < len; i++) {
      if ( ! polyregions[i].brush.link )//not a pattern
      {
        const argb = polyregions[i].brush.color.argb;
        const zone = colorZones.find((z) => {
          return z.value.argb == argb;
        });
  
        if (zone) {
          return { Name: zoneName, Value: new Cx.Brush({ color: zone.value }) };
        }
      }
      else {
        return { Name: zoneName, Value: polyregions[i].brush };
      }
    }
  }

  return undefined;
}

export function getDesignColorZonesGroups(figures:Cx.Figure[])
{
  const excludeFigureKey = 'ubExcludeFigure';
  const teamColorKey = 'ubTeamColor';
  const zoneNameKey = 'ubZoneName';
  let teamColorData = {};

  figures.forEach(function (f) {
    var meta = f.metadata,
        excludeFigure = meta.get(excludeFigureKey),
        teamColorId = meta.get(teamColorKey),
        // @ts-ignore
        brushZone = f.uniqueBrushZones().find(function (z) {
          return z.value.getType() === 'Brush';
        }),
        color,id;

    if (!excludeFigure && brushZone && brushZone.value.getType() === 'Brush') {
      color = brushZone.value.color;
      id = color.argb;

      if (teamColorId) {
        if (!teamColorData[teamColorId]) {
          teamColorData[teamColorId] = [];
        }

        if (teamColorData[teamColorId].indexOf(id) === -1) {
          teamColorData[teamColorId].push(id);
        }
      }
    }
  });
  return teamColorData ;
}

export function getNamedColorZones(id: string): ColorZoneBrush[]
{
  const namedColorZone = [];
  const canvas = CanvasAreaFactory.getCanvasById(id);
  const colorZones = CanvasHelper.getUniqueDocumentZones(canvas);

  canvas.figures.forEach((f) => {
    const colorZone = getColorZoneFromFigure(f, colorZones);

    if (colorZone) {
      const cz = namedColorZone.find((z) => { return z.Name === colorZone.Name; });

      if (!cz) {
        namedColorZone.push(colorZone);
      }
    }
  });

  return namedColorZone.sort((a, b) => { return a.Name > b.Name ? 1 : -1; });
}

export function getDecorationAreaLabel (figure: Cx.Figure): string {
  return figure.metadata.get(DECORATION_AREA_LABEL_KEY);
}

export function getDecorationAreaGroupId (figure: Cx.Figure): string {
  return figure.metadata.get(DECORATION_AREA_GROUP_ID);
}

export function getDecorationAreaGroupLabel (figure: Cx.Figure): string {
  return figure.metadata.get(DECORATION_AREA_GROUP_LABEL);
}

export function getDecorationAreaId(figure: Cx.Figure | string): string | undefined {
  if (typeof figure === 'string') {
    return figure;
  }

  return figure?.metadata?.get(DECORATION_AREA_KEY);
}

export function getDecorationAreaType (figure: Cx.Figure|string): string {
  return typeof figure === 'string' ? figure : figure.metadata.get(DECORATION_AREA_TYPE_KEY);
}

export function getInDecorationAreaName (figure: Cx.Figure|string): string {
  return typeof figure === 'string' ? figure : figure.metadata.get(IN_DECORATION_AREA_KEY);
}

export function setInDecorationAreaName (figure: Cx.Figure, areaId: string) {
  figure.metadata.set(IN_DECORATION_AREA_KEY, areaId);
}

export function getDecorationAreasByName(canvasId: string, areaId: string): Cx.Figure[] {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);

  return canvas.figures.filter((f) => {
    return getDecorationAreaId(f) === areaId;
  });
}

export function getDecorationAreaByType(figures, type: string): Cx.Figure[] {
  return figures.filter((f) => {
    return getDecorationAreaType(f) === type;
  });
}

export function setFigureInDecorationAreaHidden(figures: Cx.Figure[], areaType:string, hidden: boolean)
{
  const targetDecorationAreaFigure = getDecorationAreaByType(figures, areaType);
  if (targetDecorationAreaFigure.length > 0)
  {
    const targetDecorationAreaId = getDecorationAreaId(targetDecorationAreaFigure[0]);

    figures.forEach((f)=> {
      const inDecorationAreaId = getInDecorationAreaName(f);
      if (inDecorationAreaId)
      {      
        if ( targetDecorationAreaId === inDecorationAreaId )
        {
          f.hidden = f.hidden ? f.hidden : hidden;
        }
      }
    })
  }
}

export function getDecorationAreaFigureByType(canvasId, daId)
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const decorationAreasIds = enumDecorationAreas(canvas);
  const decorationAreaById =  decorationAreasIds.find( d => d.Label == daId);
  //const decorationArea = getDecorationAreaFigureByType(canvas, decorationAreaById?.Id);
  return getFigureInDecorationArea(canvas, decorationAreaById?.Id) ;
}

export function setFigureDecorationAreasDeltas(canvasId:string)
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const decorationAreasIds = enumDecorationAreaIds(canvas);
  decorationAreasIds.forEach((da) => {
    let figure = getFigureInDecorationArea(canvas, da);
    let daFigure = getDecorationArea(canvas, da);
    const dx = daFigure.bounds_().center.x - figure.bounds_().center.x ;
    const dy = daFigure.bounds_().center.y - figure.bounds_().center.y ;
    figure.metadata.set('bumpX', dx);
    figure.metadata.set('bumpY', dy);
  });
}

export function getNameEffect (canvasId: string, areaId: string): string {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const figure = getFigureInDecorationArea(canvas, areaId);

  if (figure) {
    const effect = figure.processes.filter((p) => {
      // @ts-ignore type does not exist on type of Cx.Process
      return p.type !== 'PenContour' && p.type !== 'Contour';
    })[0];
    // @ts-ignore type does not exist on type of Cx.Process
    const effectType = effect ? effect.type : undefined;

    switch (effectType) {
      case 'FitEnvelope': return 'None';
      case 'ClassicArc': return 'ClassicArc';
      case 'VerticalArch': return 'VerticalArch';
      //case 'ClassicArc': return 'InvertedClassicArc';
      // case 'InvertedVerticalArch':
      //   return Cx.Process.VerticalArch.createForBounds(bounds,text.fontSize.from(-170).to(-10));
      default: return 'None';
    }
  }

  return 'None';
}

export function getOutlineProcesses (canvasId: string, areaId: string) {
  const outlines = [];
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const figure = getFigureInDecorationArea(canvas, areaId);

  if (figure) {
    figure.processes.forEach((p) => {
      // @ts-ignore type does not exist on type of Cx.Process
      if (p.type === 'PenContour' || p.type === 'Contour') {
        outlines.push(p);
      }
    });
  }

  return outlines;
}

export function getOutlines (canvasId: string, areaId: string): Outline[] {
  const outlines: Outline[] = [];
  const processes = getOutlineProcesses(canvasId, areaId);

  processes.forEach((p) => {
    if (p.type === 'PenContour') {
      p.pens.forEach( (o) => {
        outlines.push({
          Brush: new Cx.Brush({ color: o.color }),
          Offset: o.width * 0.5
        });
      })
    }
    else if (p.type === 'Contour') { 
      outlines.push({
        Brush: new Cx.Brush({ color: p.brush.color }),
        Offset: p.offset
      });     
    }
  });

  return outlines;
}

export function enumDecorationAreasFigures (canvas: Cx.Canvas, filter?: Function): Cx.Figure[] {
  const tmp = [];

  canvas.figures.forEach( (f) => {
    const areaId = getDecorationAreaId(f);

    if (areaId) {
      if ( ( filter && filter(f, areaId) ) || ( ! filter ) ) {
        tmp.push(f);
      }
    }
  });

  return tmp;
}

export function enumDecorationAreas (canvas: Cx.Canvas, filter?: Function): DecorationArea[] {
  const tmp = [];

  canvas?.figures.forEach( (f) =>{
    const id = getDecorationAreaId(f);
    const type = getDecorationAreaType(f);

    if (id && type) {
      const area: DecorationArea = {
        Figure: f,
        GroupId: getDecorationAreaGroupId(f),
        GroupLabel: getDecorationAreaGroupLabel(f),
        Id: id,
        Label: getDecorationAreaLabel(f),
        Type: type,
        Active: true 
      };

      if (filter === undefined || filter(area)) {
        tmp.push(area);
      }
    }
  });

  return tmp;
}

export function enumDecorationAreaIds (canvas: Cx.Canvas): string[] {
  const tmp = [];

  canvas?.figures.forEach((f) => {
    const areaId = getDecorationAreaId(f);

    if (areaId) {
      tmp.push(areaId);
    }
  });

  return tmp;
}

export function getDecorationArea(canvas: Cx.Canvas, areaId: Cx.Figure|string): Cx.Figure {
  if (typeof areaId === 'string') {
    return canvas.figures.find((of) => {
      return of.metadata.get(DECORATION_AREA_KEY) === areaId;
    });
  }

  return areaId;
}

export function getFigureInDecorationArea(canvas: Cx.Canvas, areaId: Cx.Figure|string): Cx.Figure {
  areaId = getDecorationAreaId(areaId);

  return canvas.figures.find( (f) => {
    return f.metadata.get(IN_DECORATION_AREA_KEY) === areaId;
  });
}

export function hasDesignFigures(canvas: Cx.Canvas): boolean {
  return canvas.figures.find((f) => {
    return f.metadata.get(IN_DECORATION_AREA_KEY) ? true : false;
  }) ? true : false;
}

export function hasRosterItemFigures(canvas: Cx.Canvas): boolean {
  return canvas.figures.find((f) => {
    const type = f.metadata.get(IN_DECORATION_AREA_KEY);
    return type && isRosterDecorationAreaType(type) ? true : false;
  }) ? true : false;
}

export function resizeImage$(base64str, width, height) {

  var img = new Image();
  img.src = base64str;
  let d = Cx.Defer();
  img.onload = function() {
    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return d.resolve(canvas.toDataURL());
  }
  return d;
}

export function setCanvasCameraToDefault( canvasId: string )
{
  const canvas = CanvasAreaFactory.getCanvasById( canvasId );
  canvas.setCameraStateString(global.__DEFAULT_CAMERA_STATE__);
}

export function setCanvasCameraToBack( canvasId: string )
{
  const canvas = CanvasAreaFactory.getCanvasById( canvasId);
  canvas.setCameraStateString(global.__INVERTED_CAMERA_STATE__);
}

export function setupCanvasEvents(canvas:Cx.Canvas, onCanvasStateChangeCb: Function){  

  let _idsOfSelectedLastRender = [];
  canvas.on(DesignEvents.COMMIT, function(canvas){
    let events = [];
    let selectedFigures = canvas.selectedFigures;
    let idsOfSelected = selectedFigures.map( (f) => { return f['_localId'] } );
    //let selectionChanged = !(Array.isArray(App_.xor(idsOfSelected, _idsOfSelectedLastRender)));

    let selectionChanged = idsOfSelected
                          .filter(x => !_idsOfSelectedLastRender.includes(x))
                          .concat(_idsOfSelectedLastRender.filter(x => !idsOfSelected.includes(x)));

    if(selectionChanged){
      events.push(DesignEvents.SELECTION_CHANGED);
    }

    //Update for next check
    _idsOfSelectedLastRender = selectedFigures.map( (f) => { return f['_localId'] } );

    events.push(DesignEvents.COMMIT);

    onCanvasStateChangeCb(events);
  });
}

export function setupCanvasClickEvent (canvas: Cx.Canvas, onCanvasClickCb: Function) {
  canvas.on('click', function (event) {
    if (event.figure) {
      onCanvasClickCb(event.figure);
    }
  });
}

export function setupCanvasSelectTool (canvas: Cx.Canvas) {
  // @ts-ignore
  canvas.tool = Cx.Tool.Select();
  // @ts-ignore
  Cx.Figure.condition('movable', function(){ return false; });
  // @ts-ignore
  Cx.Figure.condition('rotatable', function(){ return false; });
  // @ts-ignore
  Cx.Figure.condition('scalable', function(){ return false; });
  // @ts-ignore
  Cx.Figure.condition('skewable', function(){ return false; });
  // @ts-ignore
  Cx.Figure.condition('selectable', function(figure) {
    let name = getInDecorationAreaName(figure);

    if (name) {
      return true;
    }

    name = getColorZoneName(figure);

    return name ? true : false;
  })
}

export function addToDecorationArea (figure: Cx.Figure, target: Cx.Figure) {
  const areaId = getDecorationAreaId(target);

  setInDecorationAreaName(figure, areaId);

  // Add the figure to the layer where the Area is now
  console.log('-----------');
  // @ts-ignore TODO: layer not defined on "Figure"
  console.log(target.layer.name);

  // @ts-ignore TODO: layer not defined on "Figure"
  return target.layer.figures.add(figure)[0];
}

export function clearDecorationArea (canvas: Cx.Canvas, areaId: string) {
  const figures = canvas.figures.filter( (f) => {
    return getInDecorationAreaName(f) === areaId;
  });

  figures.forEach((f) => {
    canvas.document.remove(f);
  });
}

//this should be replicated server side
export function setPositionInDecorationArea$(figure, target){
  let vA = target.metadata.get('daAlignmentY');
  let hA = target.metadata.get('daAlignmentX');
  return modifyFigureInDecorationArea$(figure, target, (figure, areaBounds, figureBounds) => {
    return figure.center$( areaBounds ).then( () =>{
      return figure.bounds$().then( (figureBounds) => {
     
          if(vA == 'Upper')
            figure.translate(0, areaBounds.hy - figureBounds.hy)
          else if(vA == 'Bottom')
            figure.translate(0, (figureBounds.ly - areaBounds.ly)*-1)

          if(hA == 'Left')
            figure.translate(areaBounds.lx - figureBounds.lx, 0)
          else if(hA == 'Right')
            figure.translate(areaBounds.hx - figureBounds.hx, 0)

          figure.metadata.set('rotationAngle', target.angle());  
          return Cx.resolve();
      });
    });
  });
}

export function centerInDecorationArea$ (figure: Cx.Figure, target: Cx.Figure, fit: boolean) {

  return modifyFigureInDecorationArea$(figure, target, function (figure, areaBounds) {
    return figure.center$( areaBounds );
  });
}

// export function centerInDecorationArea$ (figure: Cx.Figure, target: Cx.Figure, fit?: boolean) {

//   return figure.frame$()
//   .then((figureFrame) => {
//     return modifyFigureInDecorationArea$(figure, target, function (figure, areaBounds, figureBounds) {
//       fit = fit || (figureBounds.height > areaBounds.height || figureBounds.width > areaBounds.width) ? true : false;
//       return Promise.resolve()
//       .then( fit && figure.fit$( areaBounds ) )
//       .then( figure.center$( areaBounds ) );
//     });
//   });
// }

export function modifyFigureInDecorationArea$ (figure: Cx.Figure, target: Cx.Figure, modify) {
  // The figure is fitted to the area frame and not the bounds
  // because the area can be rotated in the 2D document
  // frame = matrix * preBounds
  return target.frame$()
  .then(function(frame){
    return figure.frame$()
    .then(function(figureFrame){
      const bounds = normalizedBoundsOfFrame(frame);
      const figureBounds = normalizedBoundsOfFrame(figureFrame);

      // Remove rotation and scaling
      figure.matrix22 = new Cx.Matrix();

      // @ts-ignore TODO: when not defined
      return Cx.when( modify(figure,bounds,figureBounds) )
      .then(function(){
        // @ts-ignore TODO: multiply not defined on Matrix
        const matrix = Cx.Matrix.multiply( Cx.Matrix.translate(frame.center),
          // @ts-ignore TODO: rotate not defined on Matrix
        Cx.Matrix.rotate(frame.angle() ) );
        figure.transform(matrix);
      });
    });
  });
}

export function normalizedBoundsOfFrame (frame) {
  const width_2 = frame.width/2;
  const height_2 = frame.height/2;
  return Cx.Bounds(-width_2,-height_2,width_2,height_2);
};