import { hideModalUseCase } from '../usecases/hideModal';
import { showAddPhonePromptUseCase } from '../usecases/showAddPhonePrompt';
import { showLoginUseCase } from '../usecases/showLogin';
import { showContactInfoUseCase } from '../usecases/showContactInfo';
import { showMoreMenuUseCase } from '../usecases/showMoreMenu';
import { showOrderStateSavedConfirmationUseCase } from '../usecases/showOrderStateSavedConfirmation';
import { showOrderSubmissionConfirmationUseCase } from '../usecases/showOrderSubmissionConfirmation';
import { showRosterItemEditorUseCase } from '../usecases/showRosterItemEditor';
import { showSavedOrdersUseCase } from '../usecases/showSavedOrders';
import { showSubmittedOrdersUseCase } from '../usecases/showSubmittedOrders';
import { uploadLogoUseCase } from '../usecases/uploadLogo';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { verifyPhoneCodeUseCase } from '../usecases/login/verifyPhoneCode';
import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import { signOutUseCase } from '../usecases/signOut';
import { showImportRosterUseCase } from '../usecases/showImportRosterPopup';
import { removeProductPopupUseCase } from '../usecases/removeProductPopupUseCase';
import { showSelectContactInfoModalUseCase } from '../usecases/showSelectContactInfoModal';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { verifyEmailHashUseCase } from '../usecases/login/verifyEmail';
import { customMsgPopupUseCase } from '../usecases/customMsgPopupUseCase';
import { showErrorDialog, showErrorDialogUseCase } from '../usecases/showErrorDialog';
import { showFAQPopupUseCase } from '../usecases/showFAQPopup';
import { showNewAddressModalUseCase } from '../usecases/showNewAddressModal';

export interface ModalReducerState {
  addPhonePromptOpen: boolean;
  alertOpen: boolean;
  alertProps: any;
  importRosterOpen: boolean;
  importRosterProps: any;
  loginOpen: boolean;
  contactInfoOpen: boolean;
  moreMenuOpen: boolean;
  orderStateSavedConfirmationOpen: boolean;
  orderSubmittionConfirmationOpen: boolean;
  errorDialogOpen: boolean;
  removeProductOpen: boolean;
  removeProductProps: any;
  rosterItemEditorOpen: boolean;
  rosterItemEditorProps: any;
  savedOrdersOpen: boolean;
  submittedOrdersOpen: boolean;
  contactInfoOpenProps: any;
  selectContactInfoOpen: boolean;
  selectContactInfoOpenProps: any;
  orderOpenCopyInfoOpen: boolean;
  orderOpenCopyInfoOpenProps: any;
  customMsgPopupOpen: boolean;
  customMsgPopupProps: any;
  faqPopupOpen: boolean;
  newAddressOpen: boolean;
  newAddressProps: any;
}

const initialState: ModalReducerState = {
  addPhonePromptOpen: false,
  alertOpen: false,
  alertProps: undefined,
  importRosterOpen: false,
  importRosterProps: undefined,
  loginOpen: false,
  moreMenuOpen: false,
  orderStateSavedConfirmationOpen: false,
  orderSubmittionConfirmationOpen: false,
  errorDialogOpen: false,
  removeProductOpen: false,
  removeProductProps: undefined,
  rosterItemEditorOpen: false,
  rosterItemEditorProps: undefined,
  savedOrdersOpen: false,
  submittedOrdersOpen: false,
  contactInfoOpen: false,
  contactInfoOpenProps: undefined,
  selectContactInfoOpen: false,
  selectContactInfoOpenProps: undefined,
  orderOpenCopyInfoOpen: false,
  orderOpenCopyInfoOpenProps: undefined,
  customMsgPopupOpen: false,
  customMsgPopupProps: undefined,
  faqPopupOpen: false,
  newAddressOpen: false,
  newAddressProps: undefined
}

// Currently only allowing one open modal so we are
// updating state from initialState on each use case
// to close any open modal
export function ModalReducer(state = initialState, actionResult: UseCaseResult): ModalReducerState {

  switch (actionResult.type) {

    case verifyPhoneCodeUseCase.type:
    case verifyEmailHashUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          loginOpen: false
        });
      }
      break;

    case coachHasSessionUseCase.type:
    case verifyPhoneCodeUseCase.type:
    case verifyEmailHashUseCase.type:
      if (actionResult.success() && state.loginOpen) {
        return Object.assign({}, state, {
          moreMenuOpen: true,
          loginOpen: false,
          errorDialogOpen: false
        });
      }

      return state;

    case signOutUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          loginOpen: true
        });
      }
      break;

    case hideModalUseCase.type:
      if (actionResult.success()) {
        const modalNamesToClose = actionResult.data;
        if (modalNamesToClose && modalNamesToClose.length > 0) {
          const updatedModalState = { ...state };
          modalNamesToClose.forEach(modalName => {
            if (initialState.hasOwnProperty(modalName)) {
              updatedModalState[modalName] = initialState[modalName];
            }
          });
          return updatedModalState;
        } else {
          return { ...initialState };
        }
      }
      break;

    case showSelectContactInfoModalUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          selectContactInfoOpen: true,
          selectContactInfoOpenProps: actionResult.data
        });
      }
      break;

    case showAddPhonePromptUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          addPhonePromptOpen: true
        });
      }
      break;

    case showLoginUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          loginOpen: true
        });
      }
      break;

    case showContactInfoUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          contactInfoOpen: true,
          selectContactInfoOpen: false,
          contactInfoOpenProps: actionResult.data
        });
      }
      break;

    case showMoreMenuUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          moreMenuOpen: true
        });
      }
      break;

    case showSavedOrdersUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          savedOrdersOpen: true
        });
      }
      break;

    case showOrderStateSavedConfirmationUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          orderStateSavedConfirmationOpen: true
        });
      }
      break;

    case showOrderSubmissionConfirmationUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          contactInfoOpen: false,
          orderSubmittionConfirmationOpen: true
        });
      }
      break;

    case showSubmittedOrdersUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          submittedOrdersOpen: true
        });
      }
      break;

    case showRosterItemEditorUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          rosterItemEditorOpen: true,
          rosterItemEditorProps: actionResult.data.props
        });
      }
      break;

    //case uploadLogoUseCase.type:
    // if (actionResult.failure()) {
    //   return Object.assign({}, initialState, {
    //     alertOpen: true,
    //     alertProps: {
    //       message: actionResult.rejectionReason
    //     }
    //   });
    // }
    // break;

    case showImportRosterUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          importRosterOpen: true,
          importRosterProps: actionResult.data.props
        });
      }
      break;

    case removeProductPopupUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          removeProductOpen: true,
          removeProductProps: actionResult.data.props
        });
      }
      break;

    case loadSubmittedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          orderOpenCopyInfoOpen: true,
          orderOpenCopyInfoOpenProps: {
            orderId: actionResult.data.savedOrder.ordersKey
          }
        });
      }
      break;

    case customMsgPopupUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          customMsgPopupOpen: true,
          customMsgPopupProps: {
            title: actionResult.data.title,
            url: actionResult.data.url,
            onClick: actionResult.data.onClick,
            onCancelClick: actionResult.data.onCancelClick,
            showCancel: actionResult.data.showCancel,
            showConfirm: actionResult.data.showConfirm,
            showCancelMsg: actionResult.data.showCancelMsg,
            showConfirmMsg: actionResult.data.showConfirmMsg,
            isConfirmOutline: actionResult.data.isConfirmOutline,
            isCancelOutline: actionResult.data.isCancelOutline,
            disableConfirmBtn: actionResult.data.disableConfirmBtn,
            disableCancelBtn: actionResult.data.disableCancelBtn,
            cancelBtnIcon: actionResult.data.cancelBtnIcon,
            confirmBtnIcon: actionResult.data.confirmBtnIcon
          }
        });
      }
      break;

    case showErrorDialogUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          errorDialogOpen: true,
          customMsgPopupProps: {
            title: actionResult.data.errorMessage,
            showConfirm: true
          }
        });
      }
      break;

    case showFAQPopupUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          faqPopupOpen: true
        });
      }
      break;

    case showNewAddressModalUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initialState, {
          newAddressOpen: true,
          newAddressProps: actionResult.data
        });
      }
      break;

  }

  return state;
}
