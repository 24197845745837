import { CanvasAreaFactory } from '../../canvas/CanvasArea'
import { defaultProduct, Product } from '../domain/Product/Product';
import { getRosterFieldMapFromCanvas } from '../domain/Roster/RosterFieldMap';
import { defaultRosterItem, filterForValidRosterItems, RosterItem, rosterItemHasDefaultValues } from '../domain/Roster/RosterItem';
import { enumDecorationAreaIds, getCdl, getNamedColorZones } from '../usecases/actions/canvas';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { editRosterUseCase } from '../usecases/editRoster';
import { emitCanvasEventUseCase } from '../usecases/emitCanvasEvent';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder'
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { addRosterItemUseCase } from '../usecases/roster/addRosterItem';
import { addMultipleRosterItemsUseCase } from '../usecases/roster/addMultipleRosterItems';
import { previewRosterItemUseCase } from '../usecases/roster/previewRosterItem';
import { removeRosterItemUseCase } from '../usecases/roster/removeRosterItem';
import { updateRosterItemUseCase } from '../usecases/roster/updateRosterItem';
import { showAddRosterStepUseCase } from '../usecases/showAddRosterStep';
import { showReviewStepUseCase } from '../usecases/showReviewStep';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { RosterItemReducer } from './RosterItemReducer';
import { getRosterTotalItems } from '../utility/order'
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { validateRosterItemsUseCase } from '../usecases/roster/validateRosterItems';
import { reportMessage } from '../../gui/util/rollbar';
import { loadCanvasDocIdUseCase } from '../usecases/loadCanvasDocId';

const initState: Product = defaultProduct();

export function ProductReducer(state: Product = initState, actionResult: UseCaseResult): Product {

  switch (actionResult.type) {

    case addMultipleRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        let rosterItems = state.RosterItems;
        const defaultItem = defaultRosterItem(state.RosterSizeOptions && { Size: '' });
        rosterItems = rosterItems.filter((r) => { return !rosterItemHasDefaultValues(r, defaultItem) });

        const multipleRosterItems = RosterItemReducer(undefined, actionResult);
        rosterItems = rosterItems.concat(multipleRosterItems);

        validateInputsErrors(rosterItems, state.RosterSizeOptions);
        checkForSizeOffsetprices(rosterItems, state.GrayGood.PriceSizeOffsets);

        const newState = Object.assign({}, state, {
          RosterItems: rosterItems,
          RosterItemsErrorMessage: validateRosterItems(rosterItems, state.RosterSizeOptions)
        });

        const totals = getTotal(newState);
        newState.TotalQuantity = totals.TotalQuantity;
        newState.TotalPrice = totals.TotalPrice;
        newState.CostPrice = totals.TotalCost;
        newState.TotalSizePriceOffset = totals.TotalSizePriceOffset;
        newState.RosterHasErrors = rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0;
        return newState;
      }

      return state;

    case addRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        const rosterItem = RosterItemReducer(undefined, actionResult);
        const rosterItems = [...state.RosterItems, rosterItem];

        const items = rosterItems.find(function (i) {
          //@ts-ignore
          return i.errorMessage?.validated;
        });

        if (items)
          validateInputsErrors(rosterItems, state.RosterSizeOptions);
        else
          checkForErrors(rosterItems, state.RosterSizeOptions);

        checkForSizeOffsetprices(rosterItems, state.GrayGood.PriceSizeOffsets);

        const newState = Object.assign({}, state, {
          RosterItems: rosterItems,
          RosterHasErrors: rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0,
          RosterItemsErrorMessage: validateRosterItems(rosterItems, state.RosterSizeOptions)
        });

        const totals = getTotal(newState);
        newState.TotalQuantity = totals.TotalQuantity;
        newState.TotalPrice = totals.TotalPrice;
        newState.CostPrice = totals.TotalCost;
        newState.TotalSizePriceOffset = totals.TotalSizePriceOffset;
        return newState;
      }

      return state;

    case addProductUseCase.type:
    case editProductUseCase.type:
    case loadSubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      
      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        const canvasId = actionResult.data.canvasId;
        const canvas = CanvasAreaFactory.getCanvasById(canvasId);
        const decorationAreaIds = enumDecorationAreaIds(canvas);
        const namedColorZones = getNamedColorZones(canvasId);
        const colorZoneNames = namedColorZones.map((item) => {
          return item.Name;
        });

        const rosterItems = state.RosterItems;
        checkForErrors(rosterItems, state.RosterSizeOptions);
        checkForSizeOffsetprices(rosterItems, state.GrayGood.PriceSizeOffsets);

        return Object.assign({}, state, {
          ColorZoneNames: colorZoneNames,
          DecorationAreaNames: decorationAreaIds,
          RosterFieldMap: getRosterFieldMapFromCanvas(actionResult.data.canvasId),
          RosterSizeMap: actionResult.data.rosterSizeMap,
          RosterSizeOptions: actionResult.data.rosterSizeOptions,
          RosterItems: ensureOneDefaultRosterItem(state.RosterItems, actionResult.data.rosterSizeOptions),
          RosterHasErrors: rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0,
        });
      }

      return state;

    case updateRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        const rosterItems = state.RosterItems.map(i => RosterItemReducer(i, actionResult));
        const items = rosterItems.find(function (i) {
          //@ts-ignore
          return (i.errorMessage?.validated && Object.values(i.errorMessage).some(function (e: any) { return e?.length > 0 }) );
        });

        if (items)
          validateInputsErrors(rosterItems, state.RosterSizeOptions);
        else
          checkForErrors(rosterItems, state.RosterSizeOptions);

        checkForSizeOffsetprices(rosterItems, state.GrayGood.PriceSizeOffsets);
        
        const newState = Object.assign({}, state, {
          RosterItems: ensureOneDefaultRosterItem(rosterItems, state.RosterSizeOptions),
          RosterItemsErrorMessage: validateRosterItems(rosterItems, state.RosterSizeOptions),
          RosterHasErrors: rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0,
        });

        const totals = getTotal(newState);
        newState.TotalQuantity = totals.TotalQuantity;
        newState.TotalPrice = totals.TotalPrice;
        newState.CostPrice = totals.TotalCost;
        newState.TotalSizePriceOffset = totals.TotalSizePriceOffset;
        return newState;
      }

      return state;


    case removeRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        const rosterItems = state.RosterItems.filter((item) => {
          return item.Id !== actionResult.data.rosterItem.Id;
        });

        const items = rosterItems.find(function (i) {
          //@ts-ignore
          return i.errorMessage?.validated;
        });

        if (items)
          validateInputsErrors(rosterItems, state.RosterSizeOptions);
        else
          checkForErrors(rosterItems, state.RosterSizeOptions);

        const newState = Object.assign({}, state, {
          RosterItems: ensureOneDefaultRosterItem(rosterItems, state.RosterSizeOptions),
          RosterItemsErrorMessage: validateRosterItems(rosterItems, state.RosterSizeOptions),
          RosterHasErrors: rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0,
        });

        const totals = getTotal(newState);
        newState.TotalQuantity = totals.TotalQuantity;
        newState.TotalPrice = totals.TotalPrice;
        newState.CostPrice = totals.TotalCost;
        return newState;
      }

      return state;

    case emitCanvasEventUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

      }

      return state;

    case previewRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      return state;

    case showAddRosterStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        return Object.assign({}, state, {
          SavedDocId: actionResult.data.savedDocId,
          RosterItems: ensureOneDefaultRosterItem(state.RosterItems, state.RosterSizeOptions)
        });
      }

      return state;

    case editRosterUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        checkForErrors(state.RosterItems, state.RosterSizeOptions);
        return Object.assign({}, state, {
          RosterItems: ensureOneDefaultRosterItem(state.RosterItems, state.RosterSizeOptions)
        });
      }

      return state;

    case validateRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        let rosterItems = state.RosterItems;
        const defaultItem = defaultRosterItem(state.RosterSizeOptions && { Size: '' });
        rosterItems = rosterItems.filter((r) => { return !rosterItemHasDefaultValues(r, defaultItem) });

        validateInputsErrors(rosterItems, state.RosterSizeOptions);
        checkForSizeOffsetprices(rosterItems, state.GrayGood.PriceSizeOffsets);

        return Object.assign({}, state, {
          RosterItems: rosterItems,
          RosterHasErrors: rosterItems.filter((r) => { return r.errorMessage ? Object.values(r.errorMessage).some(function (e: any) { return e?.length > 0 }) : false })?.length > 0,
        });
      }

      return state;

    case showReviewStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.itemNumber === state.ItemNumber) {
        const defaultItem = defaultRosterItem(state.RosterSizeOptions && { Size: '' });
        const rosterItems = filterForValidRosterItems(state.RosterItems, defaultItem);
        return Object.assign({}, state, {
          RosterItems: rosterItems
        });
      }

      return state;
  }

  return state;

}

function ensureOneDefaultRosterItem(rosterItems: RosterItem[], sizeOptions: any): RosterItem[] {
  const count = rosterItems.length;
  const defaultItem = defaultRosterItem(sizeOptions && { Size: '' });
  // Do we need to add a default item on the end
  // This is so we always have an empty row in the roster grid
  if (count > 0 && !rosterItemHasDefaultValues(rosterItems[count - 1], defaultItem)) {
    return [
      ...rosterItems,
      defaultItem
    ];
  }

  return count > 0 ? [...rosterItems] : [defaultItem];
}

function validateRosterItems(rosterItems: RosterItem[], sizeOptions: any) {
  const lastIndex = rosterItems.length - 1;
  let empty = 0;
  const defaultItem = defaultRosterItem(sizeOptions && { Size: '' });
  let error = '';

  rosterItems.forEach((item, index) => {
    if (index !== lastIndex && rosterItemHasDefaultValues(item, defaultItem)) {
      empty += 1;
    }
    if (item.errorMessage ? Object.values(item.errorMessage).some(function (e: any) { return e?.length > 0 }) : false)
      error += `Item #${String(index + 1)}: ${item.errorMessage}\n`
  });

  if (empty > 0)
    error += 'You have ' + empty + ' empty item' + (empty > 1 ? 's' : '') + ' please remove it';

  return error;
}

function getTotal(state: Product) {
  const grayGood = state.GrayGood;
  let totals = { TotalQuantity: 0, TotalPrice: 0, TotalCost: 0, TotalSizePriceOffset: 0 };
  if (grayGood) {
    totals.TotalQuantity = getRosterTotalItems(state.RosterItems);
    totals.TotalPrice = totals.TotalQuantity * parseFloat(grayGood.Price);
    totals.TotalCost = totals.TotalQuantity * parseFloat(grayGood.Cost);
    totals.TotalSizePriceOffset = getRosterTotalSizePriceOffset(state.RosterItems);
  }
  return totals;
}

function getRosterTotalSizePriceOffset(rosterItems) {
  let total = 0;
  rosterItems.forEach((r) => {
    const qty = parseInt(r.Quantity);
    const offset = parseFloat(r.SizePriceOffset);
    if (qty && offset)
      total += offset * qty;
  });
  return total;
}

function checkForSizeOffsetprices(rosterItems, priceSizeOffsets) {
  if (priceSizeOffsets && priceSizeOffsets.length > 0) {
    const offsetSizes = priceSizeOffsets.map(p => p.size.split('-')[0]);
    rosterItems.forEach((r) => {
      const sizeIndex = offsetSizes.indexOf(r.Size);
      if (sizeIndex > -1)
        r.SizePriceOffset = parseFloat(priceSizeOffsets[sizeIndex].priceOffset);
    })
  }
}

function checkForErrors(rosterItems: RosterItem[], sizeOptions) {
  rosterItems.forEach((r) => {
    r.errorMessage = { size: "", number: "", quantity: "", ca: "", validated: false };
    if (r.Size && sizeOptions.indexOf(r.Size) == -1)
      //@ts-ignore
      r.errorMessage.size = "Invalid Size. Not contained in list.";
    if (isNaN(Number(r.Number)) || (r.Number.length > 0 && !Number.isInteger(Number(r.Number))))
      //@ts-ignore
      r.errorMessage.number = "Invalid Number. Must be an integer.";
    if (isNaN(Number(r.Quantity)) || (r.Quantity.length > 0 && !Number.isInteger(Number(r.Quantity))))
      //@ts-ignore
      r.errorMessage.quantity = "Invalid Number. Must be an integer.";
    if (r.CA?.trim().length > 0) {
      if ('CA'.indexOf(r.CA) == -1)
        //@ts-ignore
        r.errorMessage.ca = "Invalid CA value. Must be C, A or empty.";
    }
  });
}

function validateInputsErrors(rosterItems: RosterItem[], sizeOptions) {
  const defaultItem = defaultRosterItem(sizeOptions && { Size: '' });
  rosterItems = rosterItems.filter((r) => { return !rosterItemHasDefaultValues(r, defaultItem) });

  rosterItems.forEach((r) => {
    r.errorMessage = {
      size: "",
      number: "",
      quantity: "",
      ca: "",
      validated: true
    };

    if ((r.Size === "" || !r.Size) || (r.Size && sizeOptions.indexOf(r.Size) == -1))
      //@ts-ignore
      r.errorMessage.size = "Invalid Size. Not contained in list.";
    if (isNaN(Number(r.Number)) || (r.Number.length > 0 && !Number.isInteger(Number(r.Number))))
      //@ts-ignore
      r.errorMessage.number = "Invalid Number. Must be an integer.";
    if ((r.Quantity === "" || !r.Quantity) || isNaN(Number(r.Quantity)) || (r.Quantity.length > 0 && !Number.isInteger(Number(r.Quantity))))
      //@ts-ignore
      r.errorMessage.quantity = "Invalid Number. Must be an integer.";
    if (r.CA?.trim().length > 0) {
      if ('CA'.indexOf(r.CA) == -1)
        //@ts-ignore
        r.errorMessage.ca = "Invalid CA value. Must be C, A or empty.";
    }
  });
}