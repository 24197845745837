import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../app/Application';
import { DecorationArea } from '../../../../../app/domain/Design/DecorationArea';
import { updateDecorationArea, uploadLogo, clearDecorationArea } from '../../../../../app/initApp';
import uniqueKey from '../../../../util/uniqueKey';
import DecorationAreaPanel from '../DecorationAreaPanel';
import LogoSelector from './LogoSelector';
import { CustomizeTab } from '../../../../../app/domain/Gui/CustomizeTab';
import setEditLogoRadio from '../../../../../app/usecases/setEditLogoCheck';
import { saveLogoDPIData } from '../../../../../app/usecases/saveLogoDPI';
import { GuiSteps } from '../../../../../app/domain/Gui/GuiSteps';

// Styles
import styles from './GraphicEditor.module.css';

interface Props {
  activeDecorationArea?: DecorationArea;
  activeLocation?: string;
  isMulti: boolean;
  canvasId: string;
  coachKey?: string;
  collapsed?: boolean;
  decorationAreas: DecorationArea[];
  defaultDecorationArea?: DecorationArea;
  locations?: string[];
  logoIsLoading?: boolean;
  onFileSelected?: Function;
  onSinglePlacementClick?: Function;
  onMultiPlacementClick?: Function;
  partnerKey?: string;
  uploadedImgDpi?: number;
  activeDAIndex?: any;
  activeCustomizeTab?: string;
  uploadedLogo?: any;
  activeStep?: string;
  currentDpiNumber?: any;
}

function Tab(props) {
  let cls = 'tab flex-row';
  const onClick = props.disabled ? () => { } : props.onClick;

  if (props.active) {
    cls += ' active partner-background-color2';
  }

  if (props.activeArrow) {
    cls += ' active-arrow';
  }

  if (props.disabled) {
    cls += ' disabled';
  }

  return <div className={cls} onClick={onClick}>
    <div className="label">{props.label}</div>
    <div className={'arrow' + (props.activeArrow ? ' partner-background-color2' : '')}>
      <div className={'arrow-inner' + (props.active ? ' partner-background-color2' : '')} />
    </div>
  </div>
}

const GraphicEditor = (props: Props) => {
  const canvasId = props.canvasId;
  const [placement, setPlacement] = useState([props.activeLocation]); //placement is now array because of multi
  const [selectedDecorationAreaIndex, setSelectedDecorationAreaIndex] = useState(0);
  const userLoggedIn = props.coachKey?.length > 0;

  useEffect(() => {
    if (props.activeCustomizeTab === CustomizeTab.GRAPHICS) {
      const validate = props.decorationAreas.filter((da) => {
        return da.Id === props.activeDAIndex;
      });

      if (validate.length > 0) {
        setEditLogoRadio();
      }
    }
  }, [props.activeDAIndex]);

  useEffect(() => {
    if (props.uploadedImgDpi && props.activeStep === GuiSteps.CUSTOMIZE) {
      saveLogoDPIData(props.uploadedLogo)
    }
  }, [props.uploadedImgDpi]);

  return (
    <div className={styles.eventPatch}>
      <DecorationAreaPanel decorationArea={props.defaultDecorationArea} activeId={props.defaultDecorationArea.Id}>
        {props.isMulti &&
          <div>
            {props.locations.map((option, index) => {
              return <ListItem
                selected={placement.indexOf(option) > -1}
                disabled={false}
                key={uniqueKey()}
                label={option}
                onClick={() => {
                  setPlacement([option]);
                  setSelectedDecorationAreaIndex(index);
                }} />
            })}
          </div>
        }
        {/* Logo */}
        <LogoSelector
          canvasId={canvasId}
          className={styles.logoSelector}
          imageLink={props.decorationAreas[selectedDecorationAreaIndex].DesignElement['fileLocation']}
          disabled={!placement || !userLoggedIn}
          logoIsLoading={props.logoIsLoading}
          currentDpiNumber={props.currentDpiNumber}
          onSelectClipartClick={() => {
            alert('Coming Soon');
          }}
          onRemove={() => {
            clearDecorationArea({ designElement: props.decorationAreas[selectedDecorationAreaIndex].DesignElement, clearGroupDecorationAreas: !props.isMulti })
          }}
          onFileSelected={(file) => props.onFileSelected(file, placement)} />
        {/* Placement */}
        {!props.isMulti &&
          <div>
            <div className={styles.selectPlacementLabel}>SELECT PLACEMENT</div>
            <div className={styles.selectPlacement}>
              {props.locations.map((option, index) => {
                return <Checkbox
                  checked={placement.indexOf(option) > -1}
                  disabled={false}
                  key={uniqueKey()}
                  label={option}
                  onClick={() => {
                    setPlacement([option]);
                    props.onSinglePlacementClick(props.activeDecorationArea, option);
                  }} />
              })}
            </div>
          </div>
        }
      </DecorationAreaPanel>
    </div>
  );

}

const Checkbox = (props) => {
  const disabled = props.disabled;
  const checked = disabled ? false : props.checked;
  const onClick = disabled ? () => { } : props.onClick;

  return <div className={disabled ? styles.checkboxDisabled : styles.checkbox} onClick={onClick}>
    <div className={checked ? styles.checkboxInputChecked : styles.checkboxInput}></div>
    <span className={styles.checkboxLabel}>{props.label}</span>
  </div>
}

const ListItem = (props) => {
  const disabled = props.disabled;
  const selected = disabled ? false : props.selected;
  const onClick = disabled ? () => { } : props.onClick;

  const className = selected ? styles.selectBoxSelected : styles.selectBox;

  return <div className={className} onClick={onClick}>
    <div className={selected ? styles.selectBoxSelected : styles.selectBox}></div>
    <span className={styles.checkboxLabel}>{props.label}</span>
  </div>
}

const mapStateToProps = (state: RootState, ownProps) => {
  const decorationAreas = ownProps.decorationAreas;
  const defaultDecorationArea = decorationAreas[0];
  const activeDecorationArea = decorationAreas[ownProps.selectedDecorationAreaIndex]
  const locations = decorationAreas.map((area) => {
    return area.Label;
  });
  const activeLocation = activeDecorationArea ? activeDecorationArea.Label : locations[0];
  const uploading = state.App.uploadingLogo;
  const uploadingToAreaId = state.App.uploadingLogoToDecorationAreaId;
  const uploadingToArea = decorationAreas.find((d) => { return d.Id === uploadingToAreaId });
  const uploadingToGroupLabel = uploadingToArea ? uploadingToArea.GroupLabel : null;
  const uploadedImgDpi = uploadingToArea && uploadingToArea?.DesignElement['dpi'];
  const uploadedLogo = { decorationAreaId: uploadingToAreaId, dpi: uploadedImgDpi, name: uploadingToArea?.Label, itemNumber: state.Order.activeItemNumber }

  return {
    activeDecorationArea,
    activeLocation,
    coachKey: state.Session.coachesKey,
    defaultDecorationArea,
    locations,
    logoIsLoading: ((uploading && uploadingToAreaId === defaultDecorationArea.Id && !ownProps.isMulti) || (uploading && uploadingToGroupLabel === activeDecorationArea.GroupLabel && ownProps.isMulti)),
    partnerKey: state.Session.partnersKey,
    uploadedImgDpi,
    activeDAIndex: state.GUI.activeDecorationAreaPanelId,
    activeCustomizeTab: state.GUI.activeCustomizeTab,
    uploadedLogo,
    activeStep: state.GUI.activeStep,
    currentDpiNumber: state.GUI.currentDpiNumber
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {
    onFileSelected: (file: File, activeLocations: string[]) => {
      const decorationArea = decorationAreaOfLocation(ownProps.decorationAreas, activeLocations[0]);
      const designElement = decorationArea.DesignElement;
      designElement.fit = true;

      uploadLogo({
        coachKey: stateProps.coachKey,
        designElement,
        dispatchStartData: { decorationAreaId: designElement.decorationAreaId },
        file,
        partnerKey: stateProps.partnerKey,
        clearGroupDecorationAreas: !ownProps.isMulti
      });
    },
    onSinglePlacementClick: (activeDecorationArea: DecorationArea, location: string) => {
      if (activeDecorationArea) {
        const area = decorationAreaOfLocation(ownProps.decorationAreas, location);

        if (area) {
          const designElement = Object.assign({}, activeDecorationArea.DesignElement, {
            decorationAreaId: area.Id,
            fit: true
          });

          updateDecorationArea({ designElement, clearGroupDecorationAreas: true });
        }
      }
    }
  });
}

function decorationAreaOfLocation(decorationAreas: DecorationArea[], location) {
  return decorationAreas.find((item) => {
    return item.Label === location;
  });
}

export default connect(mapStateToProps, null, mergeProps)(GraphicEditor);
