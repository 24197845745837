import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import SavedOrdersDetail from './SavedOrdersDetail';
import SavedOrdersSummary from './SavedOrdersSummary';

// Styles
import styles from './SavedOrders.module.css';

const SavedOrders = (props) => {
  const [activeSavedOrder, setActiveSavedOrder] = useState<any>(null);
  const [loadingClassName, setLoadingClassName] = useState<string>("");
  const detailClassName = activeSavedOrder ? '' : styles.hidden;
  const summaryClassName = !activeSavedOrder && props.ordersLoaded ? '' : styles.hidden;

  useEffect(() => {
    if (props.ordersLoaded) {
      setLoadingClassName(styles.hidden)
    }
  }, [props.ordersLoaded]);

  console.log(styles.hidden)

  return <div className={styles.savedOrders}>
    <SavedOrdersDetail
      className={detailClassName}
      activeStep={props.activeStep}
      onBackClick={() => setActiveSavedOrder(undefined)}
      savedOrder={activeSavedOrder}
      cadworxSessionId={props.cadworxSessionId}
      products={props.products} />
    <SavedOrdersSummary
      className={summaryClassName}
      onOrderClick={(savedOrder) => setActiveSavedOrder(savedOrder)}
      orders={props.orders}
      cadworxSessionId={props.cadworxSessionId} />
    <div className={loadingClassName}>Loading...</div>
  </div>
}

const mapStateToProps = (state: RootState) => {
  const orderState = state.SavedOrders;
  const cadworxSessionId = state.Session.partnerCadworxSessionId;
  const activeStep = state.GUI.activeStep;
  const products = state.Order.products;

  return {
    orders: orderState.orders,
    ordersLoaded: orderState.ordersLoaded,
    cadworxSessionId: cadworxSessionId,
    activeStep,
    products
  }
}

export default connect(mapStateToProps, null)(SavedOrders);