import { Address } from '../../gui/components/misc/NewAddressModal';
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface NewShippingAddressUseCaseOptions {
  address: Address
}

export const confirmNewShippingAddressUseCase: UseCase = {
  type: 'CONFIRM_NEW_SHIPPING_ADDRESS',

  run: (options: NewShippingAddressUseCaseOptions) => {
    return Cx.resolve({ address: options.address });
  }
};

export function confirmNewAddress(options: NewShippingAddressUseCaseOptions) {
  app.runUseCase(confirmNewShippingAddressUseCase, options);
}
