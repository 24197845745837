
import React, { useEffect, useState } from 'react';
import { hideModal } from '../../../app/usecases';
import { confirmNewAddress } from '../../../app/usecases/confirmNewShippingAddress';
import Button from './Button';
import SelectInput from './field/SelectInput';
import TextInput from './field/TextInput';

// Styles
import styles from './NewAddressModal.module.css';

export interface Address {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  postalCode: string;
  state: string;
};

interface NewAdressProps {
  address?: Address;
}

const NewAddress = (props: NewAdressProps) => {
  const [name, setName] = useState<string>(props.address?.name);
  const [address1, setAddress1] = useState<string>(props.address?.address1);
  const [address2, setAddress2] = useState<string>(props.address?.address2);
  const [city, setCity] = useState<string>(props.address?.city);
  const [state, setState] = useState<string>(props.address?.state);
  const [postalCode, setPostalCode] = useState<string>(props.address?.postalCode);
  const [isValidForm, setValidForm] = useState<boolean>(false);
  const [nameErrorMsg, setNameErrorMsg] = useState<string>("");
  const [address1ErrorMsg, setAddress1ErrorMsg] = useState<string>("");
  const [stateErrorMsg, setStateErrorMsg] = useState<string>("");
  const [cityErrorMsg, setCityErrorMsg] = useState<string>("");
  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState<string>("");
  const [newAddressCreateInited, handleNewAddressCreateInited] = useState<boolean>(false);

  useEffect(() => {
    const isValid = Boolean(name && address1 && city && postalCode && state);
    checkInputs()

    setValidForm(isValid);
  }, [name, address1, city, postalCode, state]);

  function checkInputs() {
    setNameErrorMsg(name?.length === 0 ? "Name is required" : "");
    setAddress1ErrorMsg(address1?.length === 0 ? "Address Line 1 is required" : "");
    setStateErrorMsg(state?.length === 0 ? "State is required" : "");
    setCityErrorMsg(city?.length === 0 ? "City is required" : "");
    setPostalCodeErrorMsg(postalCode?.length === 0 ? "Postal Code is required" : "");
  }

  const handleConfirmAddress = (name, address1, address2, city, postalCode, state) => {
    handleNewAddressCreateInited(true);
    const newAddress: Address = {
      name, address1, address2, city, postalCode, state
    }

    confirmNewAddress({ address: newAddress });

    setTimeout(() => {
      handleNewAddressCreateInited(false);
      hideModal();
    }, 500);
  }

  function updateSelectedValue(value) {
    setState(value);
  }

  return (
    <div className={styles.main}>
      <div className={styles.parentContent}>
        <div className="provider">
          <div className={styles.input + ' partner-color2'}>
            <TextInput className={styles.field} label="Name" onChange={setName} value={name} ></TextInput>
            <span className={styles.textDanger}>{nameErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {nameErrorMsg}</span>
          </div>
          <div className={styles.input + ' partner-color2'}>
            <TextInput className={styles.field} label="Address Line 1" onChange={setAddress1} value={address1} ></TextInput>
            <span className={styles.textDanger}>{address1ErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {address1ErrorMsg}</span>
          </div>
          <div className={styles.input + ' partner-color2'}>
            <TextInput className={styles.field} label="Address Line 2" onChange={setAddress2} value={address2} ></TextInput>
          </div>
          <div className={styles.input + ' partner-color2'}>
            <TextInput className={styles.field} label="City" onChange={setCity} value={city} ></TextInput>
            <span className={styles.textDanger}>{cityErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {cityErrorMsg}</span>
          </div>
          <div className={styles.input + ' partner-color2'}>
            <SelectInput
              className={styles.field}
              handler={(value: string) => {
                updateSelectedValue(value);
              }}
              label="State"
              options={statesArray}
              value={state} />
            <span className={styles.textDanger}>{stateErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {stateErrorMsg}</span>
          </div>
          <div className={styles.input + ' partner-color2'}>
            <TextInput className={styles.field} label="Postal Code" onChange={setPostalCode} value={postalCode} ></TextInput>
            <span className={styles.textDanger}>{postalCodeErrorMsg ? <i className="fas fa-exclamation-circle"></i> : null} {postalCodeErrorMsg}</span>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                handleConfirmAddress(name, address1, address2, city, postalCode, state);
              }}
              className={styles.confirmButton + ' partner-background-color1 partner-border-color1'}
              iconCls={newAddressCreateInited ? 'fas fa-circle-notch fa-spin' : undefined}
              label={newAddressCreateInited ? "" : "CONFIRM"}
              disabled={newAddressCreateInited || !isValidForm}></Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewAddress;

export const statesArray = [
  { value: '', text: '' },
  { value: 'AL', text: 'Alabama' },
  { value: 'AK', text: 'Alaska' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DE', text: 'Delaware' },
  { value: 'DC', text: 'District Of Columbia' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'IA', text: 'Iowa' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'ME', text: 'Maine' },
  { value: 'MD', text: 'Maryland' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MT', text: 'Montana' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NY', text: 'New York' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VT', text: 'Vermont' },
  { value: 'VA', text: 'Virginia' },
  { value: 'WA', text: 'Washington' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WY', text: 'Wyoming' }
];