import { replaceZonePenByZoneId$ } from './canvas';
import { replaceZoneBrushByZoneId$ } from './canvas';
import { rgbArrayToHex
 } from '../../domain/Pattern/colorZoneHelper';
export function setHighlightedTarget$ (fillTarget: any, highlighted: boolean ) 
{
  let targetColor = fillTarget.colorZone.Value.color;
  
  if(!targetColor)
    return Promise.resolve();

  if (highlighted)
    targetColor = Cx.Color.fromRgb( rgbArrayToHex( Cx.interpolate_rgb( Cx.Color.White().rgbArray, targetColor.rgbArray, 0.5 ) ) ) ;
  return replaceZoneBrushByZoneId$(fillTarget.canvasId, fillTarget.colorZone.Name, targetColor);
}