import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ThumbChiclet from './ThumbChiclet'
import uniqueKey from '../../util/uniqueKey';
import { setSelectedPatternColorIndex, setSelectedPatternColorIndexUseCaseOptions } from '../../../app/usecases/setSelectedPatternColorIndex'
import { RootState } from '../../../app/Application';
import { FillTarget } from '../../../app/domain/Design/FillTarget'
import { BrushFactoryOptions, BrushFactoryType } from '../../util/brushFactory';
import { setFill, SetFillUseCaseOptions } from '../../../app/usecases/setFill';
import ArrowPointer from './ArrowPointer';

// Styles
import styles from './PatternCombo.module.css';

interface Props {
  selectedPatternDocumentId: string
  selectedPatternMetadata: any;
  selectedPatternSelectedColorSet: any
  selectedPatternColors: any
  selectedMap: any;
  initialPatternColorZones: any;
  activeFillTarget: FillTarget;
  activeFillTargetBrush: Cx.Brush;
  activeZoneIndex?: number;
  hidden?: boolean;
  showColorsSet?: boolean;
  colorSetIndex: number;
  //funcs
  onColorClick?: Function;
  onPatternZoneClick: Function;
  onTupleClick: Function;
}

const PatternCombo = (props: Props) => {
  const [colorSetIndex, setColorSetIndex] = useState<number>(0);
  const [showRedCheckmark, setShowRedCheckmark] = useState<boolean>(false);

  useEffect(() => {
    if (props.selectedPatternColors[colorSetIndex] && props.selectedPatternSelectedColorSet) {
      // Check if a color other than the initial colors of the active pattern has been selected
      const newSelectedColors = props.selectedPatternColors[colorSetIndex] && props.selectedPatternSelectedColorSet
        ?.filter((s, i) => !props.selectedPatternColors[colorSetIndex]?.Color.some((p, index) => {
          return (s.spotColorName === p.ColorName && i === index);
        }));

      if (newSelectedColors?.length > 0)
        setShowRedCheckmark(true)
      else
        setShowRedCheckmark(false)
    }
  }, [props.selectedPatternSelectedColorSet, props.colorSetIndex, colorSetIndex]);

  return (
    <div className={props.hidden ? styles.patternComboHidden : styles.patternCombo}>
      <div className={styles.body}>
        <div className={styles.selectedColors}>
          <div className={styles.header}>
            SELECT INDIVIDUAL PATTERN COLORS:
          </div>
          <div className={styles.arrowPointer}>
            {props.selectedPatternSelectedColorSet.map((c, i) => {
              return <ArrowPointer
                className={styles.colorChiclet}
                index={i}
                key={uniqueKey()}
                color={c}
                selected={props.activeZoneIndex == i}
                onColorClick={(colorZone: Cx.Color, zoneIndex: number) => {
                  props.onPatternZoneClick(zoneIndex);
                }}
                title={c.spotColorName} />
            })}
            {showRedCheckmark && <span className={styles.redCheckmark + ' fas fa-check'}></span>}
          </div>
        </div>
        {props.showColorsSet && <><div className={styles.selectedPattern}>
          <div className={styles.thumbsColors}>
            {props.selectedPatternColors.map((t, i) => {
              return <ThumbChiclet
                index={i}
                className={styles.thumbChiclet}
                key={uniqueKey()}
                colorSet={t.Color}
                initialColors={props.selectedPatternColors[0].Color}
                initialZones={props.initialPatternColorZones}
                docId={props.selectedPatternDocumentId}
                selected={isMatchingColorSet(t.Color, props.selectedPatternSelectedColorSet)}
                config={props.selectedPatternMetadata}
                onColorClick={(docId, Tuple, thumbIndex) => {
                  setColorSetIndex(thumbIndex);
                  props.onTupleClick(Tuple);
                }}
                title={'Color set #' + String(i + 1)} />
            })}
          </div>
        </div></>
        }
      </div>
    </div>
  );
};

function isMatchingColorSet(patternColors, selectedColors) {
  const patternColorsList = patternColors.map((p) => p.ColorName);
  const selectedColorsList = selectedColors.map((p) => p.spotColorName);
  return patternColorsList.sort().join(',') === selectedColorsList.sort().join(',')
}

//TODO: refactor this using GUI.activePattern only
const mapStateToProps = (state: RootState, props: Props) => {
  const selectedPatternColors = state.Patterns.selectedPatternConfig.colors;
  const selectedPatternMetadata = state.Patterns.selectedPatternConfig.metadata;
  const selectedColorSet = state.GUI.activePattern.activeColors;
  const selectedMap = state.GUI.activePattern.colorIndexZoneMap;
  const colorSetIndex = state.GUI.colorSetIndex;

  return {
    selectedPatternDocumentId: state.Patterns.selectedPatternConfig.seletedPatternId,
    selectedPatternColors,
    selectedPatternMetadata,
    selectedPatternSelectedColorSet: selectedColorSet,
    initialPatternColorZones: state.Patterns.selectedPatternConfig.defaultColorZones,
    activeZoneIndex: state.GUI.activePattern.activeColorSetIndex,
    activeFillTarget: state.GUI.activeFillTarget,
    activeFillTargetBrush: state.GUI.activeFillTargetBrush,
    selectedMap,
    colorSetIndex
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {

    onPatternZoneClick: (zoneIndex: number) => {
      const options: setSelectedPatternColorIndexUseCaseOptions = {
        patternColorIndex: zoneIndex
      }
      setSelectedPatternColorIndex(options);
    },

    onTupleClick: (tuples) => {
      //create a new brush with selected color tuple and affect active target
      //do tint replacement internally
      //finally call setFill
      const brushOptions: BrushFactoryOptions = {
        docId: stateProps.selectedPatternDocumentId,
        type: BrushFactoryType.PATTERN,
        target: tuples.map(t => t.Item1), //Item1 is target color,
        map: stateProps.selectedMap
      };

      const setFillOptions: SetFillUseCaseOptions = {
        brushOptions: brushOptions,
        fillTarget: stateProps.activeFillTarget
      };

      setFill(setFillOptions);
    }

  });
}

export default connect(mapStateToProps, null, mergeProps)(PatternCombo);
