import { isDesignReady, isCoachLoggedIn } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { UserArtDesignElement, userArtDesignElementFromFileUpload } from '../domain/Design/UserArtDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateDecorationArea$ } from './actions/updateDecorationArea$';
import { uploadLogo$ } from './actions/uploadLogo$';
import { UseCase } from './usecase/UseCase';

export interface UploadLogoOptions {
  coachKey: string;
  dispatchStartData: any;
  partnerKey: string;
  file: File;
  designElement: DesignElement;
  clearGroupDecorationAreas?: boolean;
}

export const uploadLogoUseCase: UseCase = {
  type: 'UPLOAD_LOGO',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady, isCoachLoggedIn]);
  },

  run: ( options: UploadLogoOptions ) => {
    // 2MB file size limit
    // if (options.file.size > 2000000) {
    //   return Cx.reject('Max file size is 2MB.  Please try a file within this limit.');
    // }

    // else {
      return uploadLogo$(options.file, options.coachKey, options.partnerKey)
      .then((responseModel) => {

        if (responseModel.error)
          return Cx.resolve();
        else {
          const thumbSize = 260;
          const requestModel = {
            Doc: { Id: responseModel.cadworxDocId, },
            Format: 'png',
            Height: thumbSize,
            Width: thumbSize
          };
        
          const thumb = Cx.Core.Document.thumbnailUrl(requestModel);

          const designElement: UserArtDesignElement = userArtDesignElementFromFileUpload(options.designElement,
            responseModel.cadworxDocId,
            thumb);
            
          return updateDecorationArea$(designElement, options.clearGroupDecorationAreas);
        }

      });
    }
  //}
};

/**
 * @category Can
 */
export function canUploadLogo( state ) {
  return uploadLogoUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function uploadLogo( options: UploadLogoOptions ) {
  app.runUseCase( uploadLogoUseCase, options);
}
