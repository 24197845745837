import { getUpsRatesUrl } from "../../../backendApi/backendApi";
import { getToken } from "../../../gui/services/backend";

export async function getUpsRates$(ShipTo: any, NumberOfJerseys: number, PartnersKey: string, live: boolean, Products: any) {
  const body = { ShipTo, NumberOfJerseys, PartnersKey, avoidShipmentData: true, live, Products }
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const url = getUpsRatesUrl();
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Authorization': `Bearer ${apiToken}`,
      'Accept': 'application/json'
    },
    credentials: 'include'
  })

  if (resp.ok) {
    const jsonResp = await resp.json();
    const rates = jsonResp.rates;
    const selected = rates.map((r) => {
      const rateName = r.code;
      const rateCost = r.amount.offsettedAmount;
      return { Code: r.code, Name: r.name, Cost: parseFloat(r.amount.amount), Threshold: r.threshold }
    })
    return Promise.resolve(selected)
  }

  return Promise.reject('Problem with getUpsRates');
}