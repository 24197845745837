import { app } from '../../initApp';
import { requestForAccessByEmail$ } from '../actions/login';
import { UseCase } from '../usecase/UseCase';

export interface RequestForAccessByEmailOptions {
  email: string;
  hash: string;
  returnUrl: string;
  promptForEmailVerification?: boolean;
  dispatchStartData?: any;
  partnerInfo?: any;
  token?: string;
}

export const requestForAccessByEmailUseCase: UseCase = {
  type: 'REQUEST_FOR_ACCESS_BY_EMAIL',

  run: ( options: RequestForAccessByEmailOptions ) => {
    const hash = options.hash;
    const promptForEmailVerification = options.promptForEmailVerification;

    return requestForAccessByEmail$(options.email, options.returnUrl, options.partnerInfo, options.token).then( (r) => {
      return Cx.resolve(Object.assign({
        hash,
        promptForEmailVerification,
        success: true
      }, r));
    }
    , (e) => {
      return Cx.resolve(Object.assign({ success: false }, e));
    })
  }
};

export function requestForAccessByEmail( options: RequestForAccessByEmailOptions ) {
  app.runUseCase( requestForAccessByEmailUseCase, options );
}
