import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';

export interface LoadCanvasCdlOptions {
  canvasId: string;
  docId: string;
}

export const loadCanvasDocIdUseCase: UseCase = {
  type: 'LOAD_CANVAS_DOC_ID',

  run: ( options: LoadCanvasCdlOptions ) => {
    const canvasId = options.canvasId;
    const docId = options.docId;

    return loadCanvasDocument$(canvasId, docId)
    .then(() => {
      return Cx.resolve({
        canvasId,
        docId: docId
      });
    });
  }
};

/**
 * @category Use Case
 */
export function loadCanvasDocId( options: LoadCanvasCdlOptions ) {
  app.runUseCase( loadCanvasDocIdUseCase, options );
}
