import global from '../gui/services/global';
import { Application } from './Application';
import { initPartnerWithColors } from './usecases';
import { initFontListWithConfig } from './usecases/init/initFontListWithConfig';
import { loadPatterns } from './usecases/loadPatterns';
import initJerseyThumbColorMap from './usecases/initJerseyThumbColorMap'
import { queryString, isEdge }  from '../gui/util/queryString'
import { setInitialPartner } from './usecases/setInitialPartner';
import { setLiveRates } from './usecases/setLiveRates';
import { pollForAccessStart } from './usecases/pollForAccess';
import { coachHasSession } from './usecases/coachHasSession';

const app = Application();
export { app };

// We choose what to export in UseCases/index.ts
export * from './usecases';

// Init code
const partnerKey = queryString("pk") ;
const coachesKey = queryString("coachesKey") ;
const superSessionToken = queryString("superSessionToken") ;
const published = queryString("publicMode")?.toUpperCase() == 'TRUE' || ! isEdge() ;
const liveRates = queryString("liveRates")?.toUpperCase() == 'TRUE' || ! isEdge() ;

initPartnerWithColors({
  partnerKey
});
setInitialPartner({
  partnerKey
})
setLiveRates({
  liveRates
})
initFontListWithConfig({ published });
initJerseyThumbColorMap();
loadPatterns({ published });

if (superSessionToken)
{
  coachHasSession({ coachesKey, partnerKey, superSessionToken })
}
else {
  pollForAccessStart({ timeout: global._POLL_TIMEOUT_
    , interval: global._POLL_INTERVAL_
    , partnersKey: partnerKey})
}
