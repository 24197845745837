import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DecorationArea } from '../../domain/Design/DecorationArea';
import { setDpiFromUploadImg, setUserArtDocIdOnFigure, setUserArtFileLocationOnFigure, UserArtDesignElement } from '../../domain/Design/UserArtDesignElement';
import { clearDecorationArea, enumDecorationAreas } from './canvas';
import { setFigureInDecorationArea$ } from '../../usecases/actions/setFigureInDecorationArea$'
import { DECORATION_AREA_TYPES } from '../../domain/Design/DecorationArea';

export function updateMultipleUserArtDesignElement$ (designElements: UserArtDesignElement[]) {
  let p = [];
  const firstDesignElement = designElements[0];
  const canvasId = firstDesignElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const allDecorationAreas = enumDecorationAreas(canvas);
  let firstDecorationArea = allDecorationAreas.find((area) => {
    return area.Id === firstDesignElement.decorationAreaId;
  });
  const decorationAreasToClear = allDecorationAreas.filter((a: DecorationArea) => {
    // Updating a group? Or by Id
    return ( firstDecorationArea.GroupId && a.GroupId === firstDecorationArea.GroupId ) 
  }); 
  decorationAreasToClear.forEach((da)=>{
    clearDecorationArea(canvas, da.Id);
  });
  designElements.forEach((designElement)=>{        
    let decorationArea = allDecorationAreas.find((area) => {
      return area.Id === designElement.decorationAreaId;
    });
  
    p.push(getFigure$(designElement)
    .then((figure) => {
      setUserArtDocIdOnFigure(figure, designElement.docId);
      setUserArtFileLocationOnFigure(figure, designElement.fileLocation);
      setDpiFromUploadImg(figure, figure.dpi);
      //const addedFigure = addToDecorationArea(figure, decorationArea.Figure);
      figure.bounds$().then(function(bounds){
        if (!designElement.fit)
          designElement.fit = bounds.width >= decorationArea.Figure.bounds_().width || bounds.height >= decorationArea.Figure.bounds_().height;

        setFigureInDecorationArea$(canvasId, figure, decorationArea.Figure, [], [], designElement.fit)
      });
    }));

  });  
  return Cx.whenAll(p).then(function() {
    return canvas.commit$();
  });
}

function rectangleFromFigureBounds(figure, color) {
  //@ts-ignore
  return Cx.Shape.CreateRectangle({
    bounds: figure._computeBounds()
    , pen: new Cx.Pen({ color: color })
  });
}

export function updateUserArtDesignElement$ (designElement: UserArtDesignElement, clearGroupDecorationAreas: boolean = true ) {
  const canvasId = designElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const allDecorationAreas = enumDecorationAreas(canvas);
  const decorationArea = allDecorationAreas.find((area) => {
    return area.Id === designElement.decorationAreaId;
  });
  let decorationAreasToClear = [];

  if (clearGroupDecorationAreas) {
    decorationAreasToClear = allDecorationAreas.filter((a: DecorationArea) => {
      // Updating a group? Or by Id
      return ( decorationArea.GroupId && a.GroupId === decorationArea.GroupId ) && a.Id !== decorationArea.Id;
    });  
  }

  decorationAreasToClear.push(decorationArea);

  decorationAreasToClear.forEach((area) => {
    clearDecorationArea(canvas, area.Id);
  });

  return getFigure$(designElement)
  .then((figure) => {
    setUserArtDocIdOnFigure(figure, designElement.docId);
    setUserArtFileLocationOnFigure(figure, designElement.fileLocation);
    setDpiFromUploadImg(figure, figure.dpi);
    //const addedFigure = addToDecorationArea(figure, decorationArea.Figure);
    return figure.bounds$().then(function(bounds){
      if (!designElement.fit)
        designElement.fit = bounds.width >= decorationArea.Figure.bounds_().width || bounds.height >= decorationArea.Figure.bounds_().height;
      if (designElement.decorationAreaId == DECORATION_AREA_TYPES.MAIN_LOGO )
        designElement.fit = true ;
      return setFigureInDecorationArea$(canvasId, figure, decorationArea.Figure, [], [], designElement.fit)
      .then(function() {
        // //add debug figures
        // const targetRectangle = rectangleFromFigureBounds(decorationArea.Figure, Cx.Color.Black());
        // //const figureRectangle = rectangleFromFigureBounds(figure, Cx.Color.Black());
        // //@ts-ignore
        // canvas.add(targetRectangle);
        // //@ts-ignore
        // //canvas.add(figureRectangle);
        return canvas.commit$();
      });
    });
  });
}

function getFigure$ (designElement: UserArtDesignElement) {
  // Commented out the if block which prevented the figure
  // from updating when the user uploads more than once

  // if (designElement.imageLink) {
  //   // @ts-ignore
  //   const figure = new Cx.Raster({
  //     imageLink: designElement.imageLink
  //   });

  //   return Cx.resolve(figure);
  // }

  // else {
    return Cx.Core.Document.read$({ id: designElement.docId })
    .then((docModel) => {
      // @ts-ignore
      const doc = Cx.Document.fromXmlString(docModel.Cdl);
      // We allow pdf upload which can contain multile figures
      const figureCount = doc.figures.length;
      const firstFigure = doc.figures[0];

      if ( figureCount === 1 && firstFigure._imageLink ) {
        const raster = new Cx.Raster({
          imageLink: firstFigure._imageLink,
          dpi: firstFigure.dpi,
          width: firstFigure.width,
          height: firstFigure.height
        });

        return Cx.resolve(raster);
      }

      else if ( figureCount > 1 ) {
        // @ts-ignore
        return Cx.Group.create(doc.figures);
      }

      else {
        return Cx.resolve(firstFigure);
      }
    });
  // }
}