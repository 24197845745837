import {app} from '../app/initApp';
import { appendHashToUrl } from '../app/utility/appendHashToUrl';
import {RejectionReason, RejectKey} from '../app/utility/RejectionReason';
import { getToken } from '../gui/services/backend';
import {requestAccessByPhone} from './authentication';
import {requestAccessByEmail} from './authentication';
import { isFunction } from 'lodash';

export const getBackendApiUrl = () => {
  let url;
  const urlParams = new URLSearchParams(window.location.search);
  const backendUrl = urlParams.get('backendUrl');
  const backendHttps = urlParams.get('backendHttps') !== 'false'; // default to true unless explicitly set to 'false'

  if (backendUrl) {
    url = backendUrl;
  } else if (window.location.href.indexOf('localhost') > -1) {
    // Check if the href location is localhost
    url = 'localhost:3010';
  } else {
    url = process.env.REACT_APP_API_URL || (window.location.href.indexOf('www.uniformcreator.com') > -1 ? 'ddbackend.azurewebsites.net' : 'ddbackend-staging.azurewebsites.net');
  }

  const protocol = backendHttps ? 'https' : 'http';

  return `${protocol}://${url}/api`;
};

interface ColorSchemeColor {
  RGB: string
  SpotColorName: string
}

export const get3DRenderThumbnailUrl = (docId, colors: ColorSchemeColor[], initial: boolean = false, zoomLevel: number = undefined) => {
  let url = initial ? Cx.Config.app.render3dThumbUrlCache : Cx.Config.app.render3dThumbUrl;
  const siteUrl = window.location.href;
  // Check for "edge." or "localhost" in the URL
  if (siteUrl.includes("edge.") || siteUrl.includes("localhost")) {
    url = initial ? Cx.Config.app.render3dThumbUrlCache : Cx.Config.app.render3dThumbUrlStaging;
  }

  let baseUrl = `//${url}`;

  // Make it easier to work around CORS issues locally or when infrastructure is not setup
  if (Cx.Config.app.render3dThumbOverHttp === true || Cx.Config.app.render3dThumbOverHttp == 'true') {
    baseUrl = `http://${url}`;
  }

  let returnUrl = `${baseUrl}?docId=${docId}&colorSchemme=${JSON.stringify(colors)}&type=img&shown=true&zoomFactor=${zoomLevel || 1.15}`;
  
  return returnUrl;
};


const BACKEND_URL = getBackendApiUrl();

export const apiPath = {
  coachRequestAccessByPhone: '/auth2/coach/phone/requestForAccess',
  coachRequestAccessByEmail: '/auth2/coach/email/requestForAccess',
  verifyPhoneCode: '/auth2/coach/phone/verifyCode',
  verifyEmailCode: '/auth2/coach/email/verifyCode',
}

export const STeamsAPI = {
  coachRequestAccessByPhone: requestAccessByPhone,
  coachRequestAccessByEmail: requestAccessByEmail
}

export const COACH_HAS_SESSION_URL = BACKEND_URL + '/auth2/coach/hasSession';
export const COACH_BELONGS_TO_PARTNER = BACKEND_URL + '/auth2/coach/belongsToPartner';
export const AUTH_CSRF_URL = BACKEND_URL + '/auth/csrf';
export const COACH_LOGOUT_URL = BACKEND_URL + '/auth2/coach/logout';
export const GOOGLE_SIGN_IN_URL = BACKEND_URL + '/auth/signin/google';
export const UPLOAD_IMAGE_URL = BACKEND_URL + '/coaches/uploadImage';
export const ZIP_TAX_URL = BACKEND_URL + '/salesTax/getStateSalesTax';

export const getFullUrl = (path: string) => {
  return BACKEND_URL + path;
};

const getCoachDataFromStore = () => {
  const store = app.getReduxStore();
  const state = store.getState();
  const { coachesKey, cadworxSessionId, ddAccountNumber } = state.Session;
  return { coachesKey, cadworxSessionId, ddAccountNumber };
};

export const sendApiPostRequest = async (path: string, payload: any) => {
  const fullPath = getFullUrl(path);
  const coachData = getCoachDataFromStore();
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  payload.token = apiToken ;

  const resultData:any = await sendPostRequest(fullPath, payload, {
    'Coaches-Account': coachData.ddAccountNumber,
    'Coaches-Key': coachData.coachesKey,
    'Coaches-SID': coachData.cadworxSessionId,
    'Authorization': `Bearer ${apiToken}`,
    'Content-Type': 'application/json'
  });

  return resultData;
};

export const sendPostRequest = async (fullPath: string, payload: any, headers?: any) => {
  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  const result = await fetch(fullPath, {
    body: JSON.stringify(payload),
    credentials: 'include',
    headers: {
      ...headers,
      'Authorization': `Bearer ${apiToken}`
    },
    method: 'POST'
  });

  if (result.status === 200) {
    return await result.json();
  }
  else {
    const data:any = (isFunction(result.json)) ? await result.json() : {};
    await Promise.reject(new RejectionReason({
      Data: data,
      Primary: RejectKey.STEAMS_API_RESPONSE,
      UserMessage: data.error
    }));
  }
};

export const getDoRedirectUrl = (returnUrl: string): string => {
  return BACKEND_URL + '/doredirect?uri=' + returnUrl;
}

export const getOrdersReadByKeyUrl = (key: string): string => {
  return BACKEND_URL + '/orders/readByKey?key=' + key;
}

export const getUpsRatesUrl = (): string => {
  return BACKEND_URL + '/shipping/ups/rate';
}

export const getOrdersByCoachesKeyUrl = (key: string): string => {
  return BACKEND_URL + '/coaches/getOrdersByCoachesKey?key=' + key ;
}

export const getOrderStatesByCoachesKeyUrl = (key: string, partnersKey): string => {
  return BACKEND_URL + '/coaches/getOrderStatesByCoachesKey?key=' + key + '&partnersKey=' + partnersKey;
}

export const getCoachDataReadByKeyUrl = (key: string): string => {
  return BACKEND_URL + '/coaches/readByKey?key=' + key;
}

export async function getGoogleSignInUrl$ (returnUrl: string, hash: string) {
  const csrfToken = await getCsrfToken();
  const hashString = appendHashToUrl(hash, returnUrl);
  const callbackUrl = getDoRedirectUrl(returnUrl + hashString);

  const apiToken = getToken();

  if (!apiToken) {
    return Promise.reject('Token not found in the URL');
  }

  if (csrfToken) {
    const resp1 = await fetch(GOOGLE_SIGN_IN_URL, {
      body: JSON.stringify({ callbackUrl, csrfToken, json: 'true' }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
      },
      method: 'POST'
    });

    if (resp1.ok) {
      const loginUrl = await resp1.json();
      return Promise.resolve({ loginUrl });
    }
  }

  return Promise.reject();
}

async function getCsrfToken () {
  const resp = await fetch(AUTH_CSRF_URL, {
    credentials: 'include',
    method: 'GET'
  });

  if (resp.ok) {
    const jresp = await resp.json();
    const { csrfToken } = jresp;

    return csrfToken;
  }

  return undefined;
}
