import { app } from '../initApp';
import { NewShippingAddressUseCaseOptions } from './confirmNewShippingAddress';
import { UseCase } from './usecase/UseCase';

export const showNewAddressModalUseCase: UseCase = {
  type: 'SHOW_NEW_ADDRESS_MODAL',

  run: (options: NewShippingAddressUseCaseOptions) => {
    return Cx.resolve({ address: options.address });
  }
};

export function showNewAddressModal(options: NewShippingAddressUseCaseOptions) {
  app.runUseCase(showNewAddressModalUseCase, options);
}
