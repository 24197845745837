import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface SetOpenedCaseOptions {
  activeId: any;
}

export const setActiveDecorationAreaPanelUseCase: UseCase = {
  type: 'SET_OPENED_DA_PANEL_ACTIVE_ID',

  run: ( options: SetOpenedCaseOptions ) => {
    return Cx.resolve({
      activeId: options.activeId,
    });
  }
};

/**
 * @category Use Case
 */
export function setActiveDAPanelId( options: SetOpenedCaseOptions ) {
  app.runUseCase( setActiveDecorationAreaPanelUseCase, options );
}
