import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { canSetFill, setFill } from '../../../../app/initApp';
import { SetFillUseCaseOptions } from "../../../../app/usecases/setFill"
import StylesCarousel from '../../design/StylesCarousel';
import { setColorTypeMainColorSelector, SetColorTypeMainColorSelectorOptions } from '../../../../app/usecases/setColorTypeMainColorSelector';
import { BrushFactoryType, BrushFactoryOptions } from '../../../util/brushFactory'
import { setPatternComboVisiblity, setPatternComboVisiblityUseCaseOptions } from '../../../../app/usecases/setPatternComboVisibility'
import { setJerseySchemmeColorSelected } from '../../../../app/usecases/setJerseySchemmeColorSelected'
import Radio from '../../misc/field/Radio';
import { GuiSteps } from '../../../../app/domain/Gui/GuiSteps'
import { GetDefaultColorZones, GetSpotColorDefaultIndexesZoneMap, GetServiceColors, GetSpotColorBasesWithMap } from '../../../../app/domain/Pattern/colorZoneHelper';
import { MAIN_COLOR_TAB_OPTIONS } from './MainColorTabOptions';
import global from '../../../services/global';
import PatternCombo from '../../design/PatternCombo';

// Styles
import styles from './MainColorSelector.module.css';

interface Props {
  activeBrushName?: string;
  activeFillTarget: FillTarget;
  activePattern: any;
  activeStep?: Number;
  activeJerseySchemmeColorIndex?: Number;
  canSetFill: boolean;
  hidden?: boolean;
  onPatternRadioClick: Function;
  onSolidsRadioClick: Function;
  onInUseRadioClick: Function;
  onToggleClick: Function;
  setSelectedColorFill: Function;
  setSelectedPatternFill: Function;
  setSelectedPatternColorFill: Function;
  showPatternsCombo: boolean;
  colorList: Cx.Color[];
  patternsList: any[];
  inUseStylesList: any[];
  mainColorTabSelectedOption: number;
  patternsComboVisible?: boolean;
  activePatternConfig: any;
  isPatternSelectable: boolean;
}

// Bugs me I can't convert to functional component but it's not the end of the world, spent too much time on this one.
// Tried setting up callback in mergeProps first, per what we've done in the past
// Then tried using various hooks, useCallback, useRefs etc.
const MainColorSelector = (props: Props) => {
  const isPattern = props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.PATTERNS;
  const showAsPopout = isPattern && props.patternsComboVisible;
  const isPatternSelectable = props.isPatternSelectable;

  const inUseSelected = props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.IN_USE;
  const solidsSelected = (props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.SOLIDS);
  const patternSelected = props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.PATTERNS;
  const inUseStylesList: any[] = removeDuplicates(props.inUseStylesList);

  const styleList = inUseSelected ? inUseStylesList : (solidsSelected ? props.colorList : props.patternsList);

  return (
    <div className={props.hidden ? styles.mainColorSelectorHidden : styles.mainColorSelector}>
      <div className={showAsPopout ? styles.bodyPopout : styles.body}>
        <div className={showAsPopout ? styles.toggleBar : styles.toggleBarHidden} onClick={() => props.onToggleClick()}>
          <span className={styles.toggleIcon + ' fas fa-caret-down'}></span>
        </div>

        <div className={styles.header}>
          <Radio
            checked={solidsSelected}
            className={solidsSelected ? styles.radioField : styles.radioFieldBold}
            label="SOLIDS"
            onClick={props.onSolidsRadioClick} />
          {isPatternSelectable
            && <Radio
              checked={patternSelected}
              className={patternSelected ? styles.radioFieldBold : styles.radioField}
              label="PATTERNS"
              onClick={props.onPatternRadioClick} />}
          <Radio
            checked={inUseSelected}
            className={inUseSelected ? styles.radioFieldBold : styles.radioField}
            label="COLORS IN USE"
            onClick={props.onInUseRadioClick} />
          <div className={styles.brushName}>{props.activeBrushName}</div>
        </div>

        <PatternCombo
          canvas={global.__MAIN_CANVAS_ID__}
          hidden={inUseSelected ? true : !props.showPatternsCombo}
          showColorsSet={(props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.PATTERNS && props.activePattern?.activeColorSetIndex > -1) ? false : true}
        />
        {/* When a individual color is selected from the ArrowPointer component within a pattern, 
        the bottom bar turns into the SOLIDS colors and the top bar turns into the "COLORS IN USE" */}
        {(props.mainColorTabSelectedOption == MAIN_COLOR_TAB_OPTIONS.PATTERNS && props.activePattern?.activeColorSetIndex > -1) ?
          <>
            <div className={styles.body}>
              <StylesCarousel
                styleList={inUseStylesList}
                disabled={!canSetFill}
                hidden={false} //this component will mostly never hide as support several styles (instad of colors/patterns)
                onColorClick={(color) => props.setSelectedColorFill(color, props.activeJerseySchemmeColorIndex)}
                onPatternClick={(docId: string) => props.setSelectedPatternFill(docId)}
                onPatternColorClick={(color) => props.setSelectedPatternColorFill(color)}
                showPatternsCombo={true}
                inUseSelected={true}
              />
            </div>
            <div className={styles.body}>
              <StylesCarousel
                styleList={props.colorList}
                disabled={!canSetFill}
                hidden={false}
                onColorClick={(color) => props.setSelectedColorFill(color, props.activeJerseySchemmeColorIndex)}
                onPatternClick={(docId: string) => props.setSelectedPatternFill(docId)}
                onPatternColorClick={(color) => props.setSelectedPatternColorFill(color)}
                showPatternsCombo={true}
                inUseSelected={false}
              />
            </div></>
          :
          <><div className={styles.body}>
            <StylesCarousel
              styleList={styleList}
              disabled={!canSetFill}
              hidden={false}
              onColorClick={(color) => props.setSelectedColorFill(color, props.activeJerseySchemmeColorIndex)}
              onPatternClick={(docId, colorTarget, index, colorTuple) => props.setSelectedPatternFill(docId, colorTarget, index, colorTuple)}
              onPatternColorClick={(color) => props.setSelectedPatternColorFill(color)}
              showPatternsCombo={props.showPatternsCombo}
              inUseSelected={inUseSelected}
            />
          </div>
          </>
        }
      </div>
    </div>
  );
}

const getActivePatternName = (state) => {
  if (state.GUI.activeFillTargetBrushIsPattern)
    return state.GUI.activePattern && state.GUI.activePattern && state.GUI.activePattern.config && state.GUI.activePattern.config.metadata && state.GUI.activePattern && state.GUI.activePattern.config.metadata.name
      ? state.GUI.activePattern.config.metadata.name : undefined;
  else
    return state.GUI.activeFillTargetBrush ? state.GUI.activeFillTargetBrush.color.Name : undefined;
}

const removeDuplicates = (arr: any) => {
  const seen = new Set();
  const uniqueRgb = arr.filter(item => {
    const duplicate = item instanceof Cx.Color && seen.has(item?.rgb);
    seen.add(item.rgb);
    return !duplicate;
  });

  return uniqueRgb;
}

const mapStateToProps = (state: RootState) => {
  const parseUniqueDocumentBrushes = (brushes: any, patterns: any) => {
    const styles = brushes.map(b => {
      if (b.color)
        return b.color
      else {
        const pattern = patterns.find(p => p.Config.public.metadata.patternLink == b._link);
        const selectedCxColors = b.colorMap.colors;
        const map = GetSpotColorDefaultIndexesZoneMap(b.getColors());
        const initialColorZones = GetDefaultColorZones(Cx.uniqueColorZones(b), pattern.Config.public.colors[0], map);
        const initialColors = pattern.Config.public.colors[0];
        const inUseMetadata = { selectedColors: GetServiceColors(selectedCxColors, initialColorZones), initialColorZones, initialColors: initialColors.Color, selectedCxColors }
        return Object.assign(pattern, { metadata: inUseMetadata });
      }
    })

    return styles;
  }

  return {
    activeBrushName: getActivePatternName(state),
    activeFillTarget: state.GUI.activeFillTarget,
    activePattern: state.GUI.activePattern,
    showPatternsCombo: state.GUI.patternsComboVisible,
    inUseStylesList: parseUniqueDocumentBrushes(state.GUI.inUseStyles, state.Patterns.patterns),
    canSetFill: canSetFill(state),
    colorList: state.Config.colorList,
    patternsList: state.Patterns.patterns,
    hidden: state.GUI.mainColorSelectorHidden,
    mainColorTabSelectedOption: state.GUI.mainColorTabSelectedOption,
    patternsComboVisible: state.GUI.patternsComboVisible,
    activeStep: state.GUI.activeStep,
    activeJerseySchemmeColorIndex: state.GUI.activeJerseySchemmeColorIndex,
    isPatternSelectable: state.GUI.isPatternSelectable
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {

    onToggleClick: () => {
      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: false
      };

      setPatternComboVisiblity(visibilityOptions);
    },

    onInUseRadioClick: () => {
      const options: SetColorTypeMainColorSelectorOptions = {
        mainColorTabSelectedOption: MAIN_COLOR_TAB_OPTIONS.IN_USE
      };

      setColorTypeMainColorSelector(options);
    },

    onPatternRadioClick: () => {
      const options: SetColorTypeMainColorSelectorOptions = {
        mainColorTabSelectedOption: MAIN_COLOR_TAB_OPTIONS.PATTERNS
      };

      setColorTypeMainColorSelector(options);
    },

    onSolidsRadioClick: () => {
      const options: SetColorTypeMainColorSelectorOptions = {
        mainColorTabSelectedOption: MAIN_COLOR_TAB_OPTIONS.SOLIDS
      };

      setColorTypeMainColorSelector(options);

      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: false
      };

      setPatternComboVisiblity(visibilityOptions);
    },

    setSelectedPatternColorFill: (color) => {

      let activeColors = stateProps.activePattern.activeColors;
      activeColors[stateProps.activePattern.activeColorSetIndex] = color;

      const brushOptions: BrushFactoryOptions = {
        docId: stateProps.activePattern.docId,
        type: BrushFactoryType.PATTERN,
        target: activeColors,
        map: stateProps.activePattern.colorIndexZoneMap
      };

      const setFillOptions: SetFillUseCaseOptions = {
        brushOptions: brushOptions,
        fillTarget: stateProps.activeFillTarget
      };

      setFill(setFillOptions);
    },

    setSelectedColorFill: (color, index) => {
      if (stateProps.activeStep != GuiSteps.STYLE) {
        if (stateProps.canSetFill) {

          const brushOptions: BrushFactoryOptions = {
            type: BrushFactoryType.SOLID_COLOR,
            color: color
          };

          const setFillOptions: SetFillUseCaseOptions = {
            brushOptions: brushOptions,
            fillTarget: stateProps.activeFillTarget
          };

          setFill(setFillOptions);
        }
      } else {
        setJerseySchemmeColorSelected({
          index: index,
          color: color
        });
      }
    },

    setSelectedPatternFill: (docId, colorTarget) => {
      const activeFillTarget = stateProps.activeFillTarget;
      const patternModel = stateProps.patternsList.find(p => p.Config.publishedDocId == docId);

      let brushOptions: BrushFactoryOptions = {
        docId: docId,
        type: BrushFactoryType.PATTERN
      };

      if (colorTarget && patternModel) {
        const colors = colorTarget.map(t => t.Item2);
        const targetColors = colorTarget.map(t => t.Item1);
        const map = GetSpotColorDefaultIndexesZoneMap(colors);
        //const target = GetSpotColorBasesWithMap(targetColors, map)

        brushOptions = Object.assign(brushOptions, { map, target: targetColors })
      }

      const setFillOptions: SetFillUseCaseOptions = {
        brushOptions: brushOptions,
        fillTarget: activeFillTarget,
        docId: docId
      };

      setFill(setFillOptions);

      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: true
      };

      setPatternComboVisiblity(visibilityOptions);
    }
  });
};

export default connect(mapStateToProps, null, mergeProps)(MainColorSelector);
