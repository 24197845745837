import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { showLogin, showMoreMenu } from '../../../app/initApp';
import { showFAQPopup } from '../../../app/usecases/showFAQPopup';
import { isMobile } from '../../util/isMobile';
import useMedia from '../../util/useMedia';
import Button from './Button';
import packageJson from '../../../../package.json'; // Import package.json

// Styles
import styles from './Header.module.css';

interface Props {
  isLoggedIn: boolean;
  mainLogoUrl: string;
}

const Header = (props: Props) => {
  const isLoggedIn = props.isLoggedIn;
  let matchMedia = useMedia("(max-width: 500px)");
  const mobile = (isMobile() || matchMedia) ? true : false;
  let wrapperCls =
    mobile ? styles.wrapper + " " + (isLoggedIn ? styles.mobileNavOptions : (styles.wrapper + " " + styles.mobileOffline)) : styles.wrapper;

  function onButtonClick() {
    if (isLoggedIn) {
      showMoreMenu();
    } else {
      showLogin();
    }
  }

  function onFAQClick() {
    showFAQPopup();
  }

  return (
    <div className={styles.whiteHeaderWrapper + ' partner-border-color1'}>
      <div className={styles.whiteHeader}>
        <div className={styles.logoContainer}>
          <img src={props.mainLogoUrl} height="50" alt="" />
        </div>
        <div className={wrapperCls}>
          {/* Add version next to FAQ */}
          <span className={styles.version}>v{packageJson.version}</span>
          <Button
            className={styles.userButton + ' partner-color3'}
            label='FAQ'
            onClick={onFAQClick} />
          <div className={styles.divider + ' partner-color3'}>|</div>
          <div className={styles.spacer} />
          <Button
            className={styles.userButton + ' partner-color3'}
            iconCls={isLoggedIn ? 'fas fa-user-check' : (!mobile ? 'fas fa-sign-in-alt' : 'fas fa-user-plus')}
            label={isLoggedIn ? 'My Account' : (!mobile ? 'Sign In / Create Account' : '')}
            onClick={onButtonClick} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.Session.coachesKey ? true : false,
    mainLogoUrl: state.GUI.partnerLogoUrl
  }
};

export default connect(mapStateToProps, null)(Header);
