import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface setFreeShippingUseCaseOptions {
  isFreeShipping: boolean;
}

export const setFreeShippingUseCase: UseCase = {
  type: 'SET_FREE_SHIPPING',

  run: (options: setFreeShippingUseCaseOptions) => {
    return Cx.resolve({
      isFreeShipping: options.isFreeShipping
    });
  }
};


/**
 * @category Use Case
 */
export function setFreeShipping(options: setFreeShippingUseCaseOptions) {
  app.runUseCase(setFreeShippingUseCase, options);
}