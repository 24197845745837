import MD5 from "crypto-js/md5";

export function hash(length) : string {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function simpleStringify (object){
  // stringify an object, avoiding circular structures
  // https://stackoverflow.com/a/31557814
  var simpleObject = {};
  for (var prop in object ){
      if (!object.hasOwnProperty(prop)){
          continue;
      }
      if (typeof(object[prop]) == 'object'){
          continue;
      }
      if (typeof(object[prop]) == 'function'){
          continue;
      }
      simpleObject[prop] = object[prop];
  }
  return JSON.stringify(simpleObject); // returns cleaned up JSON
};

export function objectHash(...o) : string {
  let fullHash = ''; 
  o.forEach( obj => {
    fullHash += MD5(simpleStringify(obj)).toString();
  });
  return fullHash ;
}

export function brushHash(base, colors) : string {
  let fullHash = base; 
  colors.forEach( obj => {
    fullHash += obj.rgb || obj._link
  });
  return fullHash ;
}