
import global from "../../gui/services/global"
import { ColorZoneBrush } from "../domain/Design/ColorZone";
import { DecorationArea } from "../domain/Design/DecorationArea";
import { DesignElement } from "../domain/Design/DesignElement";
export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any, R extends string>(
  type: T,
  data: P,
  resultType?: R
): { type: T; data: P, resultType?: R };

export function typedAction(type: string, data?: any) {
  return { type, data };
}

export class CxCanvasState {
  canvasId: string
  canvasDocId: string
  decorationAreas: DecorationArea[]
  namedColorZones: ColorZoneBrush[]
  success: boolean
  message: string
  updated: boolean
  inited: boolean
  events: string[]
  public constructor() {}
  public static default(): CxCanvasState
  {
    return new CxCanvasState();  
  }
}

export class SnapshotState {
  savedDocId: string ; 
  thumb: string ; //base64 
  started: boolean ;
  finished : boolean ;
}


export interface ErrorState {
  errorMessage: string
}

export interface ConfigState {
  backendConfig: any,
  colorList: Array<Cx.Color>,
  fontList: Array<Cx.Font>,
  coachKey: string,
  coachSession: any,
  liveRates: boolean,
  jerseyOriginalColors: any,
  jerseyDefaultColors: any,
  jerseyAppliedDefaultColors: any;
  jerseyThumbColorMap: any;
}

export interface ApplicationState {
  canvasState: Map<string, CxCanvasState>,
  errorState: ErrorState,
  figureInDecorationAreaState: FigureInDecorationAreaState,
  config: ConfigState
}

export interface DesignElementState {
  figureInDecorationArea: FigureInDecorationAreaState
}

export interface FigureInDecorationAreaState {
  designElements : DesignElement[]
}

export const initialState : ApplicationState = {
  //@ts-ignore
  canvasState : new Map([ [global.__MAIN_CANVAS_ID__, 
                          {
                            canvasDocId: undefined, // '29150d88-b61a-4020-a77a-8edf704e7785',
                            decorationAreas: [],
                            namedColorZones: [],
                            success: false,
                            updated: false,
                            inited: false,
                            message: '',
                            events: []
                      }] 
                    ]),
  errorState: {
    errorMessage: ''
  },
  figureInDecorationAreaState: null,
  config: { 
    backendConfig : null
    , colorList : new Array<Cx.Color>() 
    , fontList : new Array<Cx.Font>()
    , coachKey: 'sample-coach'
    , coachSession: null
    , liveRates: false
    , jerseyDefaultColors: []
    , jerseyOriginalColors: []
    , jerseyAppliedDefaultColors: []
    , jerseyThumbColorMap: null
  }
};
