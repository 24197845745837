import React from 'react';

// Styles
import styles from './FAQPopup.module.css';

const FAQPopup = () => {
    const handleClick = (e: any) => {
        e.preventDefault();
        const fileURL = process.env.PUBLIC_URL + "/Uniform Creator Colors_Pantone Equivalents.pdf";
        fetch(fileURL)
            .then(res => {
                return res.blob();
            }).then(blob => {
                const link = document.createElement('a');

                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', "color_details.pdf");
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }).catch(err => console.error(err));
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.body}>
                    <h4>What is the minimum order?</h4>
                    <span>There is no minimum order, although there is a $20 processing fee for orders under 8 pieces.  This is not a per item fee, only an initial fee.</span>
                    <h4>Can I reorder from a previous order placed with you on your uniform creator?</h4>
                    <span>Yes.  You can reorder any quantity needed by logging in and selecting submitted orders from the My Account selection.
                        You would then select Copy from the order that you want to reorder.
                        You can also bring up a Saved Order from My Account as well.  Minimum order processing fee would also apply.</span>
                    <h4>Why am I being asked to login or create an account?</h4>
                    <span>This will provide you with the ability to save your order, recall an order, place a reorder,
                        and screen capture your custom design to be able to share for approvals.
                        Your information also provides us with the details to contact you for your order.</span>
                    <h4>How long does it take for my order to be completed once approved and paid for?</h4>
                    <span>Your order will be completed within 2 weeks, plus shipping.</span>
                    <h4>Can I change my order once it has been submitted and approved?</h4>
                    <span>Once your order has been submitted, that is the final indication that it is approved and ready for production, and no changes can be made at that point.
                        Any new or add on orders, can be submitted as a separate order, as there is no order minimum.
                        Minimum order processing fee would also apply.</span>
                    <h4>What is the cost for shipping?</h4>
                    <span>Shipping is calculated on the review page once an order is ready to be submitted.</span>
                    <h4>Can I use my own artwork or logo when creating my custom jersey?</h4>
                    <span>Yes. Logos can be uploaded during the customization process in the defined decoration areas.  Supported file types are PDF and PNG.</span>
                    <h4>Why am I getting a warning for my artwork?</h4>
                    <span>Uploaded logos should be 300 DPI or higher at actual size.
                        If you receive a warning when uploading artwork, it means that your logo is less than 300 DPI and may potentially appear blurry or pixelated on the final product.</span>
                    <h4>Is there an upcharge when using the patterns for customizing my jersey?</h4>
                    <span>There are no additional charges for using the available patterns in our uniform creator.</span>
                    <h4>How can I match the colors in my artwork to the PANTONE equivalent of the Uniform Creator color palette?</h4>
                    <span>
                        Our Uniform Creator colors are unique and formulated for sublimation.
                        Using the listed PANTONE colors in your artwork may help to be similar to our uniform creator colors but is <b style={{ color: "red" }}>NOT GUARANTEED</b> to match.
                        PANTONE colors are not formulated for sublimation.
                    </span>
                    <br></br>
                    <span>
                        For more details and to download a detailed list of PANTONE equivalents, please click <a href="#" onClick={handleClick}><b>here</b></a> to download the color details file.
                    </span>
                </div>
            </div>
        </>
    )
}

export default FAQPopup;
