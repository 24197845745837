import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DecorationArea, isBackNumberDecorationArea, isCADecorationArea, isNameDecorationArea, isSleeveNumberDecorationArea, isRibNumberDecorationArea } from '../../domain/Design/DecorationArea';
import { defaultBackNumberDesignElement, defaultCADesignElement, defaultNameDesignElement, defaultSleeveNumberDesignElement, defaultRibNumberDesignElement } from '../../domain/Design/TextDesignElement';
import { Font } from '../../domain/Font/Font';
import { getDecorationAreaId, getFigureInDecorationArea } from './canvas';
import { applyConfig } from './font';
import { updateTextDesignElement$ } from './updateTextDesignElement$';
import { Units } from '../../../canvas/helpers/units'

export function initDecorationAreas$(canvasId: string, decorationAreas: DecorationArea[], caFont: Font, nameFont: Font, numberFont: Font, allFonts: any, isLoadedOrder: boolean) {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const fn$ = [];

  let lastRibNumberId = null;
  const ribNumberAreas = decorationAreas.filter(item => {
    if (isRibNumberDecorationArea(item)) {
      lastRibNumberId = item.Id;
      return true;
    }
    return false;
  });

  decorationAreas?.forEach((item: DecorationArea) => {
    const areaId = getDecorationAreaId(item.Figure);
    const designFigure = areaId ? getFigureInDecorationArea(canvas, areaId) : undefined;

    if (areaId) {
      if (!designFigure)//does not exist, init with default properties
      {
        const baseCfg = {
          canvasId,
          decorationAreaId: areaId
        };
        let designElement;

        // CA
        if (isCADecorationArea(item)) {
          designElement = defaultCADesignElement(Object.assign({}, baseCfg, {
            fontFamily: caFont.data.fontName,
            fontId: caFont.value
          }));
          updateDesign(designElement, fn$, allFonts, item);
        }

        // NAME
        else if (isNameDecorationArea(item)) {
          let defaultNameProps = Object.assign({}, baseCfg, {
            fontFamily: nameFont.data.fontName,
            fontId: nameFont.value
          });
          if (item.Figure.metadata.get('daFontSize'))
            Object.assign(defaultNameProps, {
              fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
            })
          designElement = defaultNameDesignElement(defaultNameProps);
          updateDesign(designElement, fn$, allFonts, item)
        }

        // BACK NUMBER
        else if (isBackNumberDecorationArea(item)) {
          let defaultNumberProps = Object.assign({}, baseCfg, {
            fontFamily: numberFont.data.fontName,
            fontId: numberFont.value
          });
          if (item.Figure.metadata.get('daFontSize'))
            Object.assign(defaultNumberProps, {
              fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
            })
          designElement = defaultBackNumberDesignElement(defaultNumberProps);
          updateDesign(designElement, fn$, allFonts, item);
        }

        // SLEEVE NUMBERS
        else if (isSleeveNumberDecorationArea(item)) {
          let defaultNumberProps = Object.assign({}, baseCfg, {
            fontFamily: numberFont.data.fontName,
            fontId: numberFont.value
          });
          if (item.Figure.metadata.get('daFontSize'))
            Object.assign(defaultNumberProps, {
              fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
            })
          designElement = defaultSleeveNumberDesignElement(defaultNumberProps);
          updateDesign(designElement, fn$, allFonts, item);
        }

        // RIB NUMBERS
        else if (isRibNumberDecorationArea(item)) {
          let defaultNumberProps = Object.assign({}, baseCfg, {
            fontFamily: numberFont.data.fontName,
            fontId: numberFont.value,
            hidden: (lastRibNumberId && lastRibNumberId === item.Id) ? false : true
          });
          if (item.Figure.metadata.get('daFontSize'))
            Object.assign(defaultNumberProps, {
              fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
            })
          designElement = defaultRibNumberDesignElement(defaultNumberProps);
          updateDesign(designElement, fn$, allFonts, item);
        }
      }
    }
  });

  return Cx.whenAll(fn$).then(() => {
    return canvas.commit$();
  });
}

const updateDesign = (designElement, fn, allFonts, item) => {
  if (designElement) {
    applyConfig(designElement, allFonts);
    // @ts-ignore
    if (designElement.fontSize > item.Figure.frame_().height)
      // @ts-ignore
      designElement.fontSize = item.Figure.frame_().height;
    fn.push(updateTextDesignElement$(designElement));
  }
}

export const reorderItems = (items) => {
  const sortedItems = [...items].sort((a, b) => {
    const aHasLeft = a.Label.toUpperCase().includes('LEFT');
    const bHasLeft = b.Label.toUpperCase().includes('LEFT');

    if (aHasLeft && !bHasLeft) return 1;
    if (!aHasLeft && bHasLeft) return -1;

    return a.Label.localeCompare(b.Label);
  });

  return sortedItems;
};