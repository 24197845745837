import React from 'react';
import { hideModal } from '../../../app/initApp';
import Button from './Button';

// Styles
import styles from './CustomMsgPopup.module.css';

interface Props {
    title?: React.ReactNode;
    url?: string;
    onClick?: Function;
    onCancelClick?: Function;
    showCancel: boolean;
    showConfirm: boolean;
    showCancelMsg?: string;
    showConfirmMsg?: string;
    isConfirmOutline?: boolean;
    isCancelOutline?: boolean;
    disableConfirmBtn?: boolean;
    disableCancelBtn?: boolean;
    cancelBtnIcon?: string;
    confirmBtnIcon?: string;
}

const CustomMsgPopup = (props: Props) => {
    const title = props.title;
    const url = props.url;
    const onClick = props.onClick;
    const onCancelClick = props.onCancelClick;
    const showCancel = props.showCancel;
    const showConfirm = props.showConfirm;
    const showCancelMsg = props.showCancelMsg;
    const showConfirmMsg = props.showConfirmMsg;
    const isConfirmOutline = props.isConfirmOutline;
    const isCancelOutline = props.isCancelOutline;
    const disableConfirmBtn = props.disableConfirmBtn;
    const disableCancelBtn = props.disableCancelBtn;
    const cancelBtnIcon = props.cancelBtnIcon;
    const confirmBtnIcon = props.confirmBtnIcon

    const handleClose = () => {
        hideModal();
    }

    const titleContent = url ? (
        <>
            {title}{' '}
            <a
                className={styles.urlLink}
                href={url}
                target="_self">
                {url}
            </a>
        </>
    ) : (
        title
    );

    return (
        <>
            <div className={styles.container}>
                <div className={styles.body}>
                    {titleContent}
                </div>
                <div className={`${(!showCancel || !showConfirm) ? styles.oneButton : styles.twoButtons}`}>
                    {showCancel && <Button
                        className={isCancelOutline
                            ? [styles.cancelButton, styles.outlineBtn].join(" ") + ' partner-color1 partner-border-color1'
                            : styles.cancelButton + ' ui-gray btn-primary'}
                        label={showCancelMsg ? showCancelMsg : "CANCEL"}
                        onClick={onCancelClick || handleClose}
                        disabled={disableCancelBtn}
                        iconCls={cancelBtnIcon} />
                    }
                    {showConfirm && <Button
                        className={isConfirmOutline
                            ? styles.confirmButton + ' ui-primary partner-color1 partner-border-color1'
                            : styles.confirmButton + ' ui-primary btn-primary partner-background-color1'}
                        label={showConfirmMsg ? showConfirmMsg : "CONFIRM"}
                        onClick={onClick}
                        disabled={disableConfirmBtn}
                        iconCls={confirmBtnIcon} />
                    }
                </div>
            </div>
        </>
    )
}

export default CustomMsgPopup;
