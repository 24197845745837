import { getCoachDataReadByKey$ } from './getCoachDataReadByKey$';
import { coachHasSession$, getCoachBelongsToPartner$ } from './login';

export async function getCoachSessionData$(sessionToken?: string, partnerKey?: string, coachesKey?: string, superSessionToken?: string) {
  const coachHasSessionResult = await coachHasSession$(sessionToken, coachesKey, superSessionToken);
  const hasSession = coachHasSessionResult.hasSession;

  if (!hasSession) {
    return Promise.reject('Coach does not have session');
  }

  const coach = coachHasSessionResult.coach;

  const r = partnerKey && await getCoachBelongsToPartner$(coach.coachesKey, partnerKey);
  const coachContacts = await getCoachDataReadByKey$(coach.coachesKey);

  if (r && !r?.belongsToPartner) {
    return Promise.reject('Coach does not belong to Partner');
  }

  return Promise.resolve({
    coach,
    coachContacts,
    hasSession,
    sessionToken: coachHasSessionResult.sessionToken
  });
}