import React from 'react';
import { getTextColor } from '../../../app/utility/getTextColor';

// Styles
import styles from './ArrowPointer.module.css';

interface Props {
  color: Cx.Color,
  index: number;
  selected: boolean;
  title: string,
  onColorClick?: Function,
  className?: string
}

const ArrowPointer = (props: Props) => {
  const selected = props.selected;
  const hex = `#${props.color.argb.replace(/(..)(......)/, '$2$1')}`;
  const colorName = props.title?.replace(/TS/g, '');

  // change font text color based on background
  const fontColor = getTextColor(hex);

  return (
    <div className={styles.item} onClick={() => props.onColorClick(props.color, props.index)}>
      <div className={[styles.arrow, styles.top].join(' ')}
        style={{
          background: hex,
          borderLeft: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)"),
          borderRight: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)"),
          borderTop: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)")
        }}>
      </div>
      <div
        className={styles.content}
        style={{ color: fontColor && fontColor }}>
        {colorName}
      </div>
      <div className={[styles.arrow, styles.bottom].join(' ')}
        style={{
          background: hex,
          borderLeft: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)"),
          borderRight: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)"),
          borderBottom: (hex === '#FFFFFFFF' && !selected) ? "0.5px solid black" : (selected && "2px solid rgb(237, 28, 36)")
        }}>
      </div>
    </div>
  );
};

export default ArrowPointer;
