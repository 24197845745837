import { Component } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { setSport } from '../../../app/initApp';
import { GrayGood } from '../../../app/domain/Design/GrayGood';

// Styles
import styles from './Sports.module.css';

interface Props {
  hidden: boolean;
  sports: any;
  allGrayGoods: GrayGood[];
}

class Sports extends Component<Props> {

  public render() {
    const hidden = this.props.hidden;
    const sports = this.props.sports;
    const grayGoods = this.props.allGrayGoods;
    const count = sports.length;

    function getActiveGrayGoods(item) {
      return grayGoods.filter((i) => { return i.SportId === item.SportId })?.length > 0;
    }

    const items = sports.map(function (item, index) {
      const onClick = this.onItemClick.bind(this, item);

      return <Item
        key={item.Id}
        onClick={onClick}
        hastActiveGrayGoods={getActiveGrayGoods(item)}
        sport={item}>{item.Name}</Item>;
    }.bind(this));

    let containerClassname = styles.sports + ' ' + styles.centered;

    if (hidden) {
      containerClassname = styles.hidden;
    }

    return (
      <div className={containerClassname}>
        {(count > 0) &&
          <React.Fragment>
            <div className={styles.list}>
              {items}
            </div>
          </React.Fragment>
        }
      </div>
    );
  }

  public onItemClick(item) {
    setSport({
      sport: item
    });
  }

}

function Item(props) {
  const className = styles.item;
  let iconClassName = styles.listFontIcon + ' partner-color1 ' + (props.hastActiveGrayGoods ? '' : styles.iconWithoutGrayGoods);
  const onClick = props.onClick;
  iconClassName += ' ' + props.sport.FontAwesomeClass;

  return (
    <div className={className} onClick={onClick}>
      <span className={iconClassName}></span>
    </div>
  );
}

const mapStateToProps = (state) => {
  const sports = state.GUI.allSports?.sort((a, b) => a.Name.localeCompare(b.Name));
  const allGrayGoods = state.GUI.allGrayGoods;

  // Hack to make scrollable
  // sports = sports.concat(sports);

  return {
    sports,
    allGrayGoods
  };
};

export default connect(mapStateToProps, null)(Sports);
