import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { GuiSteps } from '../../../app/domain/Gui/GuiSteps';
import {
  canAddProduct,
  canAddRosterItem,
  canEditProduct,
  canSetGrayGood,
  canSetSport,
  canShowReviewStep,
  hideModal,
  removeProduct,
  showAddRosterStep,
  showCustomizeStep,
  showDesignStylesStep,
  showGrayGoodsStep,
  showSportsStep
}
  from '../../../app/initApp';
import { showCustomMsgPopup } from '../../../app/usecases/customMsgPopupUseCase';
import { resetCanvas } from '../../../app/usecases/resetCanvas'
import { showReviewStep } from '../../../app/usecases/showReviewStep';

import styles from './Steps.module.css';

interface Props {
  activeStep: string;
  canAddRosterItem: boolean;
  canCustomize: boolean;
  canSetDesignStyle: boolean;
  canSetGrayGood: boolean;
  canSetSport: boolean;
  canShowReviewStep: boolean;
  itemNumber: string;
  products?: any[],
  partnerKey?: string,
  stateTax?: string
}

class Steps extends Component<Props> {

  public render() {
    const props = this.props;
    const activeStep = props.activeStep;
    const itemNumber = props.itemNumber;

    const products = this.props.products;
    const partnerKey = this.props.partnerKey;
    const stateTax = this.props.stateTax;

    const handleClick = (fn: Function, step: string, goTo: string) => {
      if (activeStep === GuiSteps.CUSTOMIZE || activeStep === GuiSteps.ROSTER) {
        showCustomMsgPopup({
          title: 'Are you sure you’d like to return to the ' + step + '? Any customization will be lost.',
          onClick: () => { goToStep(fn) },
          showCancel: true,
          showConfirm: true
        });
      } else {
        switch (goTo) {
          case GuiSteps.SPORT:
            resetCanvas();
            showSportsStep();
            break;
          case GuiSteps.PRODUCT:
            resetCanvas();
            showGrayGoodsStep();
            break;
          case GuiSteps.STYLE:
            showDesignStylesStep();
            break;
        }
      }
    }

    function goToStep(fn: Function) {
      if (activeStep !== GuiSteps.REVIEW)
        removeProduct({ ItemNumber: props.itemNumber });

      hideModal();
      resetCanvas();
      fn();
    }

    function getHeader() {
      switch (activeStep) {
        case "sport":
          return "SELECT A SPORT"
        case "product":
          return "SELECT A PRODUCT TYPE"
        case "style":
          return "SELECT DESIGN STYLE"
        default:
          return "";
      }
    }

    return (
      <><div className={styles.steps + ' partner-border-color2'}>
        <Tab
          active={activeStep === GuiSteps.SPORT}
          disabled={!props.canSetSport}
          onClick={() => {
            // going back before design style resets canvas and change color state
            handleClick(showSportsStep, "sports", GuiSteps.SPORT);
          }}
          name="Sport" />
        <Tab
          active={activeStep === GuiSteps.PRODUCT}
          disabled={!props.canSetGrayGood}
          onClick={() => {
            // going back before design style resets canvas and change color state
            handleClick(showGrayGoodsStep, "product types", GuiSteps.PRODUCT);
          }}
          name="Product Type"
          shortName="Product" />
        <Tab
          active={activeStep === GuiSteps.STYLE}
          disabled={!props.canSetDesignStyle}
          onClick={() => {
            // going back before design style resets canvas and change color state
            handleClick(showDesignStylesStep, "design styles", GuiSteps.STYLE);
          }}
          name="Design Style"
          shortName="Style" />
        <Tab
          active={activeStep === GuiSteps.CUSTOMIZE}
          disabled={!props.canCustomize}
          onClick={() => showCustomizeStep()}
          name="Customize" />
        <Tab
          active={activeStep === GuiSteps.ROSTER}
          disabled={!props.canAddRosterItem}
          onClick={() => showAddRosterStep({ itemNumber })}
          name="Add Roster"
          shortName="Roster" />
        <Tab
          active={activeStep === GuiSteps.REVIEW}
          disabled={!props.canShowReviewStep}
          onClick={() => {
            showReviewStep({ products, partnerKey, stateTax });
          }}
          name="Review"
          products={props.products} />
      </div>
        {["sport", "style", "product"].includes(activeStep?.toLowerCase()) && (
          <div className="page-header partner-color1">
            {getHeader()}
          </div>
        )}
      </>
    );
  }

}

interface TabProps {
  active: boolean;
  disabled: boolean;
  onClick: any;
  name: string;
  shortName?: string;
  products?: any;
};

function Tab(props: TabProps) {
  const disabled = props.disabled;
  const tabStyle = disabled ? styles.tabDisabled : styles.tab;
  const onClick = disabled ? undefined : props.onClick;
  const labelStyle = styles.label + (props.active ? ' partner-color1' : ' partner-color2');
  const labelUnderlineStyle = props.active ? (styles.activeLabelUnderline + ' partner-background-color1') : styles.labelUnderline;
  const isReviewTab = props.name.toLowerCase() === GuiSteps.REVIEW ? true : false;
  const products = props.products;

  return <div className={tabStyle} onClick={onClick}>
    <div className={styles.arrow} />
    {
      (props.shortName)
        ?
        <React.Fragment>
          <div className={labelStyle + ' ' + styles.uiLarge}>
            <div className={labelUnderlineStyle} />
            {props.name}
          </div>
          <div className={labelStyle + ' ' + styles.uiSmall}>
            <div className={labelUnderlineStyle} />
            {props.shortName}
          </div>
        </React.Fragment>
        :
        <div className={labelStyle}>
          <div className={labelUnderlineStyle} />
          {props.name} {(isReviewTab && products?.length > 0)
            && <span className={styles.badge + ' partner-background-color2'}>{products?.length}</span>}
        </div>
    }
  </div>
}

const mapStateToProps = (state: RootState) => {
  return {
    activeStep: state.GUI.activeStep,
    canAddRosterItem: canAddRosterItem(state),
    canCustomize: canEditProduct(state),
    canSetDesignStyle: canAddProduct(state),
    canSetGrayGood: canSetGrayGood(state),
    canSetSport: canSetSport(state),
    canShowReviewStep: canShowReviewStep(state),
    itemNumber: state.Order.activeItemNumber,
    products: state.Order.products,
    partnerKey: state.Session.partnersKey
  };
};

export default connect(mapStateToProps, null)(Steps);
