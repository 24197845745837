import React from 'react';
import { RosterItem } from '../../../../../app/domain/Roster/RosterItem';
import Input from '../../../misc/field/Input';

interface Props {
  columnCfg:any,
  onChange: Function,
  onClick?: any,
  onFocus: any,
  rosterItem: RosterItem,
}

const TextInputColumn = (props: Props) => {
  const columnCfg = props.columnCfg;
  const item = props.rosterItem;

  return <td className={columnCfg.id}>
    <Input
      onChange={(e) => {
        const value: string = e.target.value;

        if (value !== item[columnCfg.field]) {
          item[columnCfg.field] = value;
          props.onChange(item, columnCfg);
        }
      }}
      onFocus={props.onFocus}
      type="text"
      value={item[columnCfg.field]}
      name="text_input_column"
      placeholder={columnCfg?.id === "name" && "*Roster names are case sensitive"}  />
  </td>
}

export default TextInputColumn;